import React, { useEffect, useState } from "react";
import DataTable from "../../Tables/DataTable/DataTable";
import { Box, Grid, Tooltip } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import Filter from "../../../common/Filter/Filter";
import { FILTER_OPTION } from "../../../constants/filterOptions";
import { useLoading } from "../../../hooks/useLoader";
import ApiServices from "../../../services/ApiServices";
import {
  SUBSCRIBER_ACCESSDATA,
  SUBSCRIBER_CONTENT_DISTBRANDNAME,
  SUBSCRIBER_CONTENT_EXPORT,
} from "../../../constants/api-constants";
import {
  ClearSelectionData,
  GetAllSelectedIds,
  GetSelectedColumns,
  SelectionFilter,
} from "../../../utilities/SelectionFilter";
import { toast } from "react-toastify";
import ExportToExcelHandler from "../../../utilities/ExportToExcelHandler";

/*istanbul ignore next */
const Access = (props) => {
  const { showLoader } = useLoading();
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [adminTable, setAdminTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(0);
  const [bundleFilterOptions, setBundleFilterOptions] = React.useState(
    FILTER_OPTION.SUBSCRIBER_ACCESS_LIST
  );
  const DOCUMENT_TYPES = {
    CONTENT: 1,
    CATALOG: 2,
    BUNDLE: 3,
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [query, setQuery] = useState({
    searchText: "",
    PageSize: 10,
    PageNumber: 1,
    IgnorePaging: false,
    SortColumn: "Name",
    SortType: "asc",
    Filter: {
      SubscriberId: props.id,
      DocumentTypes: [
        DOCUMENT_TYPES.CONTENT,
        DOCUMENT_TYPES.CATALOG,
        DOCUMENT_TYPES.BUNDLE,
      ],
    },
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePageNumber = (event, newPage) => {
    setCurrentPage(newPage);
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };

  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return {
          ...prevState,
          searchText: event.target.value,
          PageNumber: 1,
        };
      });
    }, 1000);
  };

  const sortHandler = (sortOrder, sortBy) => {
    setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };
  const onCheckboxChangeHandler = (e, value) => {
    const { checked } = e.target;
    if (value === "all") {
      setAdminTableData((items) => {
        SelectionFilter(items, "multiple", checked);
        return items.map((data) => {
          return {
            ...data,
            isChecked: checked,
          };
        });
      });
    } else {
      setAdminTableData((items) => {
        return items.map((user) => {
          let userRecord = { ...user };
          if (user.contentId === value) {
            SelectionFilter(user, "single", checked, "contentId");
            userRecord.isChecked = checked;
          }
          return userRecord;
        });
      });
    }
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let listOfContentCols = selectedCols?.subscriberaccesscol || [];
    setSelectedColumns(listOfContentCols);
  };

  const accessDetails = async () => {
    try {
      showLoader(true);
      const accessData = await ApiServices.httpGet(
        `${SUBSCRIBER_ACCESSDATA}?query=${JSON.stringify(query)}`
      );
      showLoader(false);
      setAdminTableData(accessData?.result?.records);
      setTotalPages(accessData?.result?.totalPages);
      setPageSize(accessData?.result?.pageSize);
      setCurrentPage(accessData?.result?.currentPage);
    } catch (e) {
      showLoader(false);
    }
  };

  const handleExport = async () => {
    try {
      let ids = GetAllSelectedIds("contentId");
      let queryState = { ...query };
      queryState.SelectedIds = ids;
      showLoader(true);
      queryState.selectedColumns = GetSelectedColumns(14);
      const exportedContent = await ApiServices.httpGet(
        `${SUBSCRIBER_CONTENT_EXPORT}?query=${JSON.stringify(queryState)}`
      );
      if (exportedContent?.result?.file) {
        ExportToExcelHandler(
          exportedContent.result.file.fileContents,
          exportedContent.result.file.fileDownloadName
        );
      } else {
        toast.error(exportedContent?.responseStatus?.error);
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const loadDistBrandName = async () => {
    try {
      const contentOptions = [...bundleFilterOptions];
      const subscriberId = props.id;
      const apiEndpoint = `${SUBSCRIBER_CONTENT_DISTBRANDNAME.replace(
        "subscriberId",
        subscriberId
      )}`;
      const filteredResponses = await ApiServices.httpGet(apiEndpoint);
      contentOptions[0].options = filteredResponses.result.map((r) => {
        const distBrandname = r.brandName.split(" ")[0];
        return {
          value: r.userId,
          roleId: r.userId,
          label: distBrandname,
        };
      });
      setBundleFilterOptions(contentOptions);
    } catch (e) {
      console.error("Error loading distributor brand names:", e);
      showLoader(false);
    }
  };

  const applyFilterHandler = (filter) => {
    ClearSelectionData();
    setQuery((prevState) => {
      return {
        ...prevState,
        PageNumber: 1,
        Filter: {
          ...prevState.Filter,
          userIds: filter.brandName?.map((item) => item) || [],
          DocumentTypes:
            filter.type?.length > 0
              ? filter.type.map((item) => item)
              : [
                  DOCUMENT_TYPES.CONTENT,
                  DOCUMENT_TYPES.CATALOG,
                  DOCUMENT_TYPES.BUNDLE,
                ],
        },
      };
    });
  };

  useEffect(() => {
    ClearSelectionData();
    setSelectionColumns();
    loadDistBrandName();
  }, []);

  useEffect(() => {
    accessDetails();
  }, [query]);

  return (
    <Grid spacing={2} container>
      <Grid xs={12}>
        <Box className="top-right-actions">
          <div className="add-welcome-widgets" style={{ zIndex: "10" }}>
            <Tooltip placement="bottom" title="Filter">
              <FilterAltOutlinedIcon
                title="filter-icon"
                onClick={handleClick}
              />
            </Tooltip>
            <Filter
              anchorEl={anchorEl}
              id={props.id}
              open={open}
              options={bundleFilterOptions}
              onApplyFilter={applyFilterHandler}
              onClose={handleClose}
            />
            <Tooltip placement="bottom" title="Export to Excel">
              <IosShareRoundedIcon
                data-testid="IosShareRoundedIcon"
                onClick={handleExport}
              />
            </Tooltip>
          </div>
        </Box>
        <DataTable
          uniqueKey="contentId"
          adminTable={adminTable}
          columns={selectedColumns}
          query={query}
          handleChangePage={handleChangePageNumber}
          totalPages={totalPages}
          pageSize={pageSize}
          onSearch={onSearchHandler}
          onSort={sortHandler}
          onPageSizeChange={pageSizeChangeHandler}
          onCheckboxChange={onCheckboxChangeHandler}
          permissions={props.permissions}
          page={currentPage}
          routeData={props.routeData}
        />
      </Grid>
    </Grid>
  );
};

export default Access;
