import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  List,
  ListSubheader,
  Modal,
  Popover,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../Vendors/Vendors.css";
import DataTable from "../Tables/DataTable/DataTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
// import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  ClearSelectionData,
  SelectionFilter,
} from "../../utilities/SelectionFilter";
import TableColumnModal from "../../common/TableColumnModal/TableColumnModal";
import ApiServices from "../../services/ApiServices";
import { useLoading } from "../../hooks/useLoader";
import {
  VENDOR_INTEGRATIONSERVICE,
  VENDOR_INTEGRATIONSERVICE_LIST,
  VENDOR_INTEGRATIONSERVICE_STATUS,
  VENDOR_INTEGRATIONSOLUTION,
} from "../../constants/api-constants";
import { toast } from "react-toastify";
import { MenuListItems } from "../../common/Sidebar/MenuListItems";
import { SystemModulesEnum } from "../../constants/SystemUsers";
import {
  childModulePermissions,
  PermissionEnum,
} from "../../constants/permissionService";
import { useVendorContext } from "../Vendors/Vendor.context";
import Confirmation from "../../common/Confirmation/Confirmation";
import { INTEGRATION_TYPE, STATUS_ENUM, TABS } from "../../constants/common";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import { a11yProps } from "../../common/TabPanel/a11yProps";
import CheckboxList from "../../common/CheckboxList/CheckboxList";
import { useFormik } from "formik";
import FooterButtons from "../../common/FooterButtons/FooterButtons";
import { useAuth } from "../../hooks/useAuth";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import APISchemaTree from "./ApiSchemaTree";
import { VendorIntegrationServiceSchema } from "../../common/ValidationSchema/ValidationSchema";

/*istanbul ignore next */
const IntServiceList = (props) => {
  const auth = useAuth();
  const { showLoader } = useLoading();
  const { id } = useParams();
  const permissionId = auth.getPermissionId();
  const vendorContext = useVendorContext();
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [isEditMode, setIsEditMode] = useState(vendorContext.isEditMode);
  const [vendorTable, setVendorTableData] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [openSolModal, setopenSolModal] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [expanded, setExpanded] = React.useState("panel1");
  const [expanded2, setExpanded2] = React.useState("panel2");
  const [selectedTab, setSelectedTab] = useState(TABS.SERVICE_BASIC);
  const [solutionDetails, setSolutionDetails] = useState(null);
  const [integrationServiceId, setIntegrationServiceId] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open2 = Boolean(anchorEl);
  const handleConfirmationOpen = (actionName, cnfMessage) => {
    setConfirmationMessage(cnfMessage);
    setConfirmation(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationMessage("");
    setConfirmation(false);
  };

  const vendorServiceInit = {
    serviceName: "",
    serviceAPIURL: "",
    integrationMethodText: "",
    integrationTypeText: "",
    integrationType: 0,
    integrationMethod: 0,
    integrationServiceId: integrationServiceId,
    vendorId: Number(props.vendorId),
    integrationSolutionId: props.integrationSolutionId,
    status: 1,
    integrationTypeBasic: null,
    integrationTypeJWT: null,
    integrationTypeAPIKey: null,
  };

  const formik = useFormik({
    initialValues: vendorServiceInit,
    enableReinitialize: true,
    validationSchema: VendorIntegrationServiceSchema,
  });

  const [query, setQuery] = useState({
    searchText: "",
    SortType: "desc",
    IgnorePaging: false,
    SortColumn: "userId",
    PageNumber: 1,
    PageSize: 10,
    Filter: {
      Status: selectedStatus,
    },
  });

  const sstyle = {
    position: "absolute",
    right: "0%",
    width: 800,
    height: "100%",
    bgcolor: "#fff",
    border: "1px solid #000",
    p: 2,
  };

  useEffect(() => {
    vendorServiceList();
    setSelectionColumns();
  }, [query]);

  useEffect(() => {
    if (integrationServiceId > 0 && openSolModal) {
      vendorServiceDetails();
    }
  }, [integrationServiceId, openSolModal]);

  useEffect(() => {
    if (id && vendorIntSolutionPermission?.includes(PermissionEnum.LIST)) {
      vendorServiceList();
    }
  }, []);

  useEffect(() => {
    if (props.integrationSolutionId) {
      vendorSolutionDetails();
    }
  }, [props.integrationSolutionId]);

  let vendorContactModuleId = MenuListItems.find(
    (item) => item.name === SystemModulesEnum.VENDORS
  )?.moduleId;
  const vendorIntSolutionPermission = childModulePermissions(
    vendorContactModuleId,
    SystemModulesEnum.VENDOR_INTEGRATION_SOLUTION
  );

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setExpanded2(newExpanded ? panel : false);
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return {
          ...prevState,
          PageNumber: 1,
          searchText: event.target.value,
        };
      });
    }, 1000);
  };
  const fieldMapping = {
    integrationTypeText: "integrationType",
    integrationMethodText: "integrationMethod",
  };

  const sortHandler = (sortOrder, sortBy) => {
    const mappedSortBy = fieldMapping[sortBy] || sortBy;
    setQuery((prevState) => {
      if (prevState.SortColumn === mappedSortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: mappedSortBy,
          SortType: "asc",
        };
      }
    });
  };

  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };
  /*istanbul ignore next */
  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };
  const handleVendorSolutionClose = () => {
    setopenSolModal(false);
    formik.setValues(vendorServiceInit);
  };
  const applyFilterHandler = (Filter) => {
    ClearSelectionData();
    setQuery((prevState) => {
      return {
        ...prevState,
        PageNumber: 1,
        Filter: {
          Status: selectedStatus,
        },
      };
    });
  };

  const onCheckboxChangeHandler = (e, value) => {
    const { checked } = e.target;
    if (value === "all") {
      setVendorTableData((items) => {
        SelectionFilter(items, "multiple", checked);
        return items.map((data) => {
          return {
            ...data,
            isChecked: checked,
          };
        });
      });
    } else {
      setVendorTableData((items) => {
        return items.map((user) => {
          let userRecord = { ...user };
          if (user.integrationServiceId === value) {
            SelectionFilter(user, "single", checked, "integrationServiceId");
            userRecord.isChecked = checked;
          }
          return userRecord;
        });
      });
    }
  };

  const onCancelHandler = () => {
    setIsEditMode(false);
    setopenSolModal(false);
  };
  const onEditHandler = () => {
    setIsEditMode(true);
  };

  const onEdit = (record) => {
    setIntegrationServiceId(record.integrationServiceId);
    setIsEditMode(true);
    setopenSolModal(true);
  };
  const onView = (record) => {
    setIntegrationServiceId(record.integrationServiceId);
    setIsEditMode(false);
    setopenSolModal(true);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFilterStatusChangeHandler = (values) => {
    setSelectedStatus(values);
  };
  const clearFilterHandler = () => {
    setSelectedStatus([]);
  };

  const onCancelTableColumnSelection = () => {
    setSelectionColumns();
    setTableColumnModalVisibility(false);
  };

  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, listOfServiceDataColunms: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    }
    setSelectionColumns();
    setTableColumnModalVisibility(false);
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let adminCols = selectedCols?.listOfServiceDataColunms
      ? selectedCols?.listOfServiceDataColunms
      : null;
    if (adminCols) {
      setSelectedColumns(adminCols);
    }
  };

  const onToggleHandler = async (
    column,
    userId,
    contentSubscriberStatus,
    e,
    selectedSolution
  ) => {
    setSelectedSolution(selectedSolution);
    if (selectedSolution.status === 1) {
      handleConfirmationOpen(
        "Inactivate",
        "Are you sure you want to mark the Integration Service as Inactive?"
      );
    } else {
      changeContentStatus(selectedSolution, STATUS_ENUM.ACTIVE);
    }
  };
  const changeContentStatus = async (vendorObj, status) => {
    try {
      const response = await ApiServices.httpPut(
        VENDOR_INTEGRATIONSERVICE_STATUS,
        {
          integrationServiceId: vendorObj.integrationServiceId,
          status: Number(status),
        }
      );

      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        handleConfirmationClose();
        vendorServiceList();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };

  const vendorServiceDetails = async () => {
    showLoader(true);
    const data = await ApiServices.httpGet(
      `${VENDOR_INTEGRATIONSERVICE}/${integrationServiceId}`
    );
    showLoader(false);
    formik.setValues(data.result);
    let resultObject = {
      name: data.result.integrationSolutionName,
      description: data.result.integrationSolutionDescription,
    };
    setSolutionDetails(resultObject);
  };

  const vendorSolutionDetails = async () => {
    showLoader(true);
    const data = await ApiServices.httpGet(
      `${VENDOR_INTEGRATIONSOLUTION}/${props.integrationSolutionId}`
    );
    showLoader(false);
    let resultObject = {
      name: data.result.name,
      description: data.result.description,
    };
    setSolutionDetails(resultObject);
  };

  const vendorServiceList = async () => {
    try {
      showLoader(true);
      const contactQuery = {
        ...query,
        Filter: {
          ...query.Filter,
          vendorId: props.vendorId,
          integrationSolutionId: props.integrationSolutionId,
        },
      };
      const vendorData = await ApiServices.httpGet(
        `${VENDOR_INTEGRATIONSERVICE_LIST}/${JSON.stringify(contactQuery)}`
      );
      showLoader(false);
      if (vendorData.responseStatus.isSuccess) {
        setVendorTableData(vendorData?.result?.records);
        setTotalPages(vendorData?.result?.totalPages);
        setPageSize(vendorData?.result?.pageSize);
      } else {
        toast.error(vendorData.responseStatus.error);
      }
    } catch (e) {
      showLoader(false);
      toast.error();
    }
  };

  const addVendorSolutionServiceDetails = async (isNext) => {
    try {
      const payload = {
        ...formik.values,
      };
      showLoader(true);
      const response = await ApiServices.httpPost(
        VENDOR_INTEGRATIONSERVICE,
        payload
      );
      if (response.responseStatus.isSuccess) {
        vendorServiceList();
        setopenSolModal(false);
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      toast.error("Internal server error");
    } finally {
      showLoader(false);
    }
  };

  const updateVendorSolutionServiceDetails = async (isNext) => {
    try {
      const payload = {
        ...formik.values,
      };

      showLoader(true);
      const response = await ApiServices.httpPut(
        VENDOR_INTEGRATIONSERVICE,
        payload
      );
      if (response.responseStatus.isSuccess) {
        formik.setValues(response.result);
        setopenSolModal(false);
        vendorServiceList();
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      toast.error("Internal server error");
    } finally {
      showLoader(false);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Box mb={2} mt={-1} className="top-right-actions">
          <div className="add-welcome-widgets">
            <div>
              <Popover
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                id={"simple-popover"}
                open={open2}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <div className="expanded-filter">
                  <div className="filter-options">
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      subheader={
                        <ListSubheader
                          component="div"
                          id="nested-list-subheader"
                        >
                          Filters will be applied to your grid
                        </ListSubheader>
                      }
                    >
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            Status
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CheckboxList
                            id="status"
                            options={[
                              { value: 1, label: "Active" },
                              { value: 0, label: "Inactive" },
                            ]}
                            onChange={onFilterStatusChangeHandler}
                            selectedValues={selectedStatus}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </List>
                  </div>
                  <div className="modal-footer">
                    <Button
                      variant="outlined"
                      className="action-button mr-4"
                      onClick={clearFilterHandler}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      className="action-button"
                      onClick={() => {
                        applyFilterHandler();
                        handleClose();
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
            {props.integrationSolutionId > 0 &&
              vendorIntSolutionPermission?.includes(PermissionEnum.CREATE) && (
                <Tooltip placement="bottom" title="Add New Service">
                  <AddOutlinedIcon
                    name="add-icon"
                    onClick={() => {
                      formik.setValues(vendorServiceInit);
                      setIntegrationServiceId(0);
                      setopenSolModal(true);
                      setIsEditMode(true);
                    }}
                  />
                </Tooltip>
              )}
            <Tooltip placement="bottom" title="Filter">
              <FilterAltOutlinedIcon
                title="filter-icon"
                onClick={handleClick}
              />
            </Tooltip>
          </div>
        </Box>
      </Grid>
      <Grid item xs={2} sx={{ display: "contents" }}>
        <Box>
          <Modal
            open={openSolModal}
            onClose={() => {
              setopenSolModal(false);
              handleVendorSolutionClose();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="cbhdsabhsdab"
          >
            <Box sx={sstyle} className="custom-modal">
              <form>
                <div className="modal-header">
                  <div className="modal-heading-title">
                    Integration Solutions
                  </div>
                  <div
                    className="modal-header-right"
                    style={{ cursor: "pointer" }}
                  >
                    <CloseOutlinedIcon
                      data-testid="CloseNewAdminPopup"
                      onClick={() => {
                        setopenSolModal(false);
                        handleVendorSolutionClose();
                      }}
                    />
                  </div>
                </div>
                <div
                  className="modal-body"
                  style={{ maxHeight: "100vh", overflowY: "auto" }}
                >
                  {
                    <Accordion
                      className="accordion-ent"
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={{ fontWeight: 700 }}>
                          Basic Details
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          padding={2}
                          className="blue-field-section"
                        >
                          <Grid
                            container
                            padding={0}
                            sx={{ background: "white" }}
                          >
                            <Grid item xs={12} p={2}>
                              <label
                                htmlFor="brandName"
                                style={{
                                  display: "block",
                                  marginBottom: "4px",
                                }}
                              >
                                Solution Name
                                <MandatoryField />
                              </label>
                              <input
                                id="name"
                                name="name"
                                className="form-label form-control"
                                type="text"
                                placeholder="Enter Solution Name"
                                style={{ width: "100%" }}
                                value={solutionDetails?.name}
                                onChange={(e) => {
                                  if (
                                    !formik.values?.name &&
                                    e.target.value === " "
                                  )
                                    return;
                                  formik.handleChange(e);
                                }}
                                disabled={
                                  !isEditMode ||
                                  props.selectedSubTab === TABS.VENDOR_SERVICES
                                }
                              />
                            </Grid>
                            <Grid item xs={12} p={2}>
                              <label
                                htmlFor="firstName"
                                style={{
                                  display: "block",
                                  marginBottom: "4px",
                                }}
                              >
                                Description
                                <MandatoryField />
                              </label>
                              <textarea
                                id="description"
                                name="description"
                                className="form-label form-control bs-cus scrollable-input-content"
                                type="text"
                                placeholder="Add Description"
                                value={solutionDetails?.description}
                                onChange={(e) => {
                                  if (
                                    !formik.values?.description &&
                                    e.target.value === " "
                                  )
                                    return;
                                  formik.setFieldValue("dirty", true);
                                  formik.handleChange(e);
                                }}
                                disabled={
                                  !isEditMode ||
                                  props.selectedSubTab === TABS.VENDOR_SERVICES
                                }
                                sx={{
                                  "&.Mui-disabled": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "transparent",
                                      border: "none",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "transparent",
                                        border: "none",
                                      },
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginTop: "16px",
                            marginLeft: "-20px",
                          }}
                        ></div>
                      </AccordionDetails>
                    </Accordion>
                  }
                  {props.selectedSubTab === TABS.VENDOR_SERVICES && (
                    <Accordion
                      className="accordion-ent"
                      expanded={expanded2 === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography sx={{ fontWeight: 700 }}>
                          Service Details
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          maxHeight: "100vh",
                          overflowY: "auto",
                          marginBottom: "50px",
                        }}
                      >
                        <Tabs
                          value={selectedTab}
                          onChange={(e, newValue) => setSelectedTab(newValue)}
                          aria-label="Distributor Tabs"
                          className="content-tabs"
                        >
                          <Tab
                            label="Service Details"
                            value={TABS.SERVICE_BASIC}
                            className="content-sub-tabs"
                            {...a11yProps("Services", "Services")}
                          />
                          {/* <Tab
                            label="Service Request"
                            value={TABS.SERVICE_REQUEST}
                            className="content-sub-tabs"
                            {...a11yProps("Services", "Services")}
                            disabled
                          />
                          <Tab
                            label="Service Response"
                            value={TABS.SERVICE_RESPONSE}
                            className="content-sub-tabs"
                            {...a11yProps("Services", "Services")}
                            disabled
                          /> */}
                        </Tabs>
                        {selectedTab === TABS.SERVICE_BASIC && (
                          <div
                            className="blue-field-section"
                            sx={{ margin: "10px" }}
                          >
                            <Grid container xs={12} margin={0}>
                              <Grid item xs={3} margin={2}>
                                <label htmlFor="serviceName">
                                  Service Name
                                  <MandatoryField />
                                </label>
                                <input
                                  id="serviceName"
                                  name="serviceName"
                                  className="form-label form-control"
                                  type="text"
                                  placeholder="Enter Service Name"
                                  maxLength={25}
                                  style={{ width: "100%" }}
                                  disabled={!isEditMode}
                                  value={formik.values?.serviceName}
                                  onChange={(e) => {
                                    formik.setFieldValue("dirty", true);
                                    formik.handleChange(e);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={3} margin={2}>
                                <label htmlFor="serviceAPIURL">
                                  Service API URL
                                  <MandatoryField />
                                </label>
                                <input
                                  id="serviceAPIURL"
                                  name="serviceAPIURL"
                                  className="form-label form-control"
                                  type="text"
                                  placeholder="Enter Service API URL"
                                  maxLength={25}
                                  style={{ width: "100%" }}
                                  disabled={!isEditMode}
                                  value={formik.values?.serviceAPIURL}
                                  onChange={(e) => {
                                    formik.setFieldValue("dirty", true);
                                    formik.handleChange(e);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={3} margin={2}>
                                <label htmlFor="integrationMethod">
                                  Integration Method
                                  <MandatoryField />
                                </label>
                                <select
                                  id="integrationMethod"
                                  name="integrationMethod"
                                  className="form-label form-control"
                                  style={{ width: "100%" }}
                                  disabled={!isEditMode}
                                  value={formik.values?.integrationMethod}
                                  onChange={(e) => {
                                    formik.setFieldValue("dirty", true);
                                    formik.setFieldValue(
                                      "integrationMethod",
                                      Number(e.target.value)
                                    );
                                  }}
                                >
                                  <option>Select Integration Method</option>

                                  {[
                                    { label: "AL3", value: "1" },
                                    { label: "JSON", value: "2" },
                                    { label: "XML", value: "3" },
                                  ].map((method) => (
                                    <option
                                      key={method.value}
                                      value={method.value}
                                    >
                                      {method.label}
                                    </option>
                                  ))}
                                </select>
                              </Grid>
                              <Grid item xs={3} margin={2}>
                                <label htmlFor="integrationType">
                                  Integration Type
                                  <MandatoryField />
                                </label>
                                <select
                                  id="integrationType"
                                  name="integrationType"
                                  className="form-label form-control"
                                  style={{ width: "100%" }}
                                  disabled={!isEditMode}
                                  value={formik.values?.integrationType}
                                  onChange={(e) => {
                                    const selectedIntegrationType = Number(
                                      e.target.value
                                    );
                                    if (selectedIntegrationType === 1) {
                                      formik.setValues({
                                        ...formik.values,
                                        integrationType:
                                          selectedIntegrationType,
                                        integrationTypeBasic: {
                                          userName: null,
                                          password: null,
                                        },
                                        integrationTypeJWT: null,
                                        integrationTypeAPIKey: null,
                                      });
                                    } else if (selectedIntegrationType === 2) {
                                      formik.setValues({
                                        ...formik.values,
                                        integrationType:
                                          selectedIntegrationType,
                                        integrationTypeBasic: null,
                                        integrationTypeJWT: {
                                          token: null,
                                        },
                                        integrationTypeAPIKey: null,
                                      });
                                    } else if (selectedIntegrationType === 3) {
                                      formik.setValues({
                                        ...formik.values,
                                        integrationType:
                                          selectedIntegrationType,
                                        integrationTypeBasic: null,
                                        integrationTypeJWT: null,
                                        integrationTypeAPIKey: {
                                          keyName: null,
                                          keyValue: null,
                                          addTo: null,
                                        },
                                      });
                                    } else {
                                      formik.setValues({
                                        ...formik.values,
                                        integrationType: "",
                                        integrationTypeBasic: null,
                                        integrationTypeJWT: null,
                                        integrationTypeAPIKey: null,
                                      });
                                    }
                                  }}
                                >
                                  <option>Select Integration Type</option>
                                  {[
                                    { label: "Basic", value: "1" },
                                    { label: "JWT", value: "2" },
                                    { label: "API Key", value: "3" },
                                  ].map((type) => (
                                    <option key={type.value} value={type.value}>
                                      {type.label}
                                    </option>
                                  ))}
                                </select>
                              </Grid>
                              <Grid item xs={3} margin={2}></Grid>
                              <Grid item xs={3} margin={2}></Grid>
                              <>
                                {formik.values?.integrationType ===
                                  INTEGRATION_TYPE.BASIC && (
                                  <>
                                    <Grid item xs={3} margin={2}>
                                      <label htmlFor="userName">Username</label>
                                      <MandatoryField />
                                      <input
                                        id="userName"
                                        name="userName"
                                        className="form-label form-control"
                                        type="text"
                                        placeholder="Enter Username"
                                        maxLength={25}
                                        style={{ width: "100%" }}
                                        value={
                                          formik.values?.integrationTypeBasic
                                            ?.userName
                                        }
                                        disabled={!isEditMode}
                                        onChange={(e) => {
                                          formik.setFieldValue("dirty", true);
                                          formik.setFieldValue(
                                            "integrationTypeBasic",
                                            {
                                              ...formik.values
                                                .integrationTypeBasic,
                                              userName: e.target.value,
                                            }
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={3} margin={2}>
                                      <label htmlFor="password">Password</label>
                                      <MandatoryField />
                                      <input
                                        id="password"
                                        name="password"
                                        className="form-label form-control"
                                        type="text"
                                        placeholder="Enter Password"
                                        maxLength={25}
                                        style={{ width: "100%" }}
                                        value={
                                          formik.values?.integrationTypeBasic
                                            ?.password
                                        }
                                        disabled={!isEditMode}
                                        onChange={(e) => {
                                          formik.setFieldValue("dirty", true);
                                          formik.setFieldValue(
                                            "integrationTypeBasic",
                                            {
                                              ...formik.values
                                                .integrationTypeBasic,
                                              password: e.target.value,
                                            }
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}

                                {formik.values?.integrationType ===
                                  INTEGRATION_TYPE.JWT && (
                                  <>
                                    <Grid item xs={3} margin={2}>
                                      <label htmlFor="token">Token</label>
                                      <MandatoryField />
                                      <input
                                        id="token"
                                        name="token"
                                        className="form-label form-control"
                                        type="text"
                                        placeholder="Enter Token"
                                        maxLength={25}
                                        style={{ width: "100%" }}
                                        value={
                                          formik.values?.integrationTypeJWT
                                            ?.token
                                        }
                                        disabled={!isEditMode}
                                        onChange={(e) => {
                                          formik.setFieldValue("dirty", true);
                                          formik.setFieldValue(
                                            "integrationTypeJWT",
                                            {
                                              ...formik.values
                                                .integrationTypeJWT,
                                              token: e.target.value,
                                            }
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}

                                {formik.values?.integrationType ===
                                  INTEGRATION_TYPE.API_KEY && (
                                  <>
                                    <Grid item xs={3} margin={2}>
                                      <label htmlFor="keyName">Key Name</label>
                                      <MandatoryField />
                                      <input
                                        id="keyName"
                                        name="keyName"
                                        className="form-label form-control"
                                        type="text"
                                        placeholder="Enter Key Name"
                                        maxLength={25}
                                        style={{ width: "100%" }}
                                        value={
                                          formik.values?.integrationTypeAPIKey
                                            ?.keyName
                                        }
                                        disabled={!isEditMode}
                                        onChange={(e) => {
                                          formik.setFieldValue("dirty", true);
                                          formik.setFieldValue(
                                            "integrationTypeAPIKey",
                                            {
                                              ...formik.values
                                                .integrationTypeAPIKey,
                                              keyName: e.target.value,
                                            }
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={3} margin={2}>
                                      <label htmlFor="keyValue">
                                        Key Value
                                      </label>
                                      <MandatoryField />
                                      <input
                                        id="keyValue"
                                        name="keyValue"
                                        className="form-label form-control"
                                        type="text"
                                        placeholder="Enter Key Value"
                                        maxLength={25}
                                        style={{ width: "100%" }}
                                        value={
                                          formik.values?.integrationTypeAPIKey
                                            ?.keyValue
                                        }
                                        disabled={!isEditMode}
                                        onChange={(e) => {
                                          formik.setFieldValue("dirty", true);
                                          formik.setFieldValue(
                                            "integrationTypeAPIKey",
                                            {
                                              ...formik.values
                                                .integrationTypeAPIKey,
                                              keyValue: e.target.value,
                                            }
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={3} margin={2}>
                                      <label htmlFor="addTo">Add To</label>
                                      <MandatoryField />
                                      <select
                                        id="addTo"
                                        name="addTo"
                                        className="form-label form-control"
                                        style={{ width: "100%" }}
                                        disabled={!isEditMode}
                                        value={
                                          formik.values?.integrationTypeAPIKey
                                            ?.addTo
                                        }
                                        onChange={(e) => {
                                          formik.setFieldValue("dirty", true);
                                          formik.setFieldValue(
                                            "integrationTypeAPIKey",
                                            {
                                              ...formik.values
                                                .integrationTypeAPIKey,
                                              addTo: Number(e.target.value),
                                            }
                                          );
                                        }}
                                      >
                                        <option value="">Select Add To</option>
                                        {[
                                          { label: "Header", value: 1 },
                                          { label: "Query Param", value: 2 },
                                        ].map((type) => (
                                          <option
                                            key={type.value}
                                            value={type.value}
                                          >
                                            {type.label}
                                          </option>
                                        ))}
                                      </select>
                                    </Grid>
                                  </>
                                )}
                              </>
                            </Grid>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                marginTop: "16px",
                                marginLeft: "-20px",
                              }}
                            >
                              <FooterButtons
                                isEditMode={isEditMode}
                                permissions={vendorIntSolutionPermission}
                                permissionId={permissionId}
                                editPermission={integrationServiceId > 0}
                                showUpdateNext={integrationServiceId > 0}
                                showSaveNext={integrationServiceId === 0}
                                disableUpdateNext={
                                  !isEditMode || !formik.isValid
                                }
                                disableSaveNext={!isEditMode || !formik.isValid}
                                showUpdateDetail={integrationServiceId > 0}
                                disableUpdateDetail={
                                  !isEditMode || !formik.isValid
                                }
                                disableSaveDetail={
                                  !isEditMode || !formik.isValid
                                }
                                onCancel={onCancelHandler}
                                onEdit={onEditHandler}
                                onUpdateNext={() =>
                                  updateVendorSolutionServiceDetails(true)
                                }
                                onSaveNext={() => {
                                  addVendorSolutionServiceDetails(true);
                                }}
                                onUpdate={() =>
                                  updateVendorSolutionServiceDetails(false)
                                }
                                onSave={() => {
                                  addVendorSolutionServiceDetails(false);
                                }}
                                buttonType="button"
                              />
                            </div>
                          </div>
                        )}
                        {selectedTab === TABS.SERVICE_REQUEST && (
                          <div
                            className="blue-field-section"
                            sx={{ margin: "10px" }}
                          >
                            <Grid container xs={12} margin={0}>
                              <Grid item xs={3} margin={2}>
                                <label htmlFor="brandName">
                                  HTTP Verb
                                  <MandatoryField />
                                </label>
                                <input
                                  id="brandName"
                                  name="brandName"
                                  className="form-label form-control"
                                  type="text"
                                  placeholder="Enter Solution Name"
                                  maxLength={25}
                                  style={{ width: "100%" }}
                                  disabled={!isEditMode}
                                />
                              </Grid>
                              <Grid item xs={3} margin={2}></Grid>
                              <Grid item xs={3} margin={2}></Grid>
                              <Grid item xs={2.5} margin={2}>
                                <label htmlFor="brandName">
                                  Parameter Name
                                  <MandatoryField />
                                </label>
                                <input
                                  id="brandName"
                                  name="brandName"
                                  className="form-label form-control"
                                  type="text"
                                  placeholder="Enter Solution Name"
                                  maxLength={25}
                                  style={{ width: "100%" }}
                                  disabled={!isEditMode}
                                />
                              </Grid>
                              <Grid item xs={4} margin={2}>
                                <label htmlFor="brandName">
                                  Source(XFDL,Client Folder)
                                  <MandatoryField />
                                </label>
                                <input
                                  id="brandName"
                                  name="brandName"
                                  className="form-label form-control"
                                  type="text"
                                  placeholder="Enter Solution Name"
                                  maxLength={25}
                                  style={{ width: "100%" }}
                                  disabled={!isEditMode}
                                />
                              </Grid>
                              <Grid item xs={3} margin={2}>
                                <label htmlFor="brandName">
                                  eLabel/Client Details
                                  <MandatoryField />
                                </label>
                                <input
                                  id="brandName"
                                  name="brandName"
                                  className="form-label form-control"
                                  type="text"
                                  placeholder="Enter Solution Name"
                                  maxLength={25}
                                  style={{ width: "100%" }}
                                  disabled={!isEditMode}
                                />
                              </Grid>
                              <Grid item xs={3} margin={2}>
                                <label htmlFor="brandName">HTTP Verb</label>
                                <input
                                  id="brandName"
                                  name="brandName"
                                  className="form-label form-control"
                                  type="text"
                                  placeholder="Enter Solution Name"
                                  maxLength={25}
                                  style={{ width: "100%" }}
                                  disabled={!isEditMode}
                                />
                              </Grid>
                              <Grid item xs={3} margin={2}>
                                <label htmlFor="brandName">Upload File</label>
                                <input
                                  id="brandName"
                                  name="brandName"
                                  className="form-label form-control"
                                  type="text"
                                  placeholder="Enter Solution Name"
                                  maxLength={25}
                                  style={{ width: "100%" }}
                                  disabled={!isEditMode}
                                />
                              </Grid>
                            </Grid>
                            <Tabs
                              value={selectedTab}
                              onChange={(e, newValue) =>
                                setSelectedTab(newValue)
                              }
                              aria-label="Distributor Tabs"
                              className="service-tabs"
                              sx={{ background: "#f4f4f4" }}
                            >
                              <Tab
                                label="API Schema"
                                className="service-sub-tabs"
                                disabled
                              />
                              <Tab className="service-sub-tabs" />
                              <Tab
                                label="Inchanted eLabels"
                                className="service-sub-tabs sub-tab-service-margin"
                                disabled
                              />
                            </Tabs>
                            <Grid
                              container
                              xs={12}
                              spacing={0}
                              margin={1}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                overflow: "auto",
                              }}
                              className="blue-field-section"
                            >
                              <Grid item xs={5} marginLeft={-1}>
                                <div>
                                  <APISchemaTree />
                                </div>
                              </Grid>
                              <Grid item xs={7}>
                                <Grid item xs={7} margin={2}>
                                  <label htmlFor="brandName">
                                    HTTP Verb
                                    <MandatoryField />
                                  </label>
                                </Grid>
                              </Grid>
                            </Grid>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                marginTop: "16px",
                                marginLeft: "-20px",
                              }}
                            >
                              <FooterButtons
                                isEditMode={isEditMode}
                                permissions={vendorIntSolutionPermission}
                                permissionId={permissionId}
                                editPermission={props.integrationSolutionId > 0}
                                showUpdateNext={props.integrationSolutionId > 0}
                                showSaveNext={props.integrationSolutionId === 0}
                                disableUpdateNext={
                                  !isEditMode || !formik.isValid
                                }
                                disableSaveNext={!isEditMode || !formik.isValid}
                                showUpdateDetail={
                                  props.integrationSolutionId > 0
                                }
                                disableUpdateDetail={
                                  !isEditMode || !formik.isValid
                                }
                                disableSaveDetail={
                                  !isEditMode || !formik.isValid
                                }
                                onCancel={onCancelHandler}
                                onEdit={onEditHandler}
                                onUpdateNext={() =>
                                  updateVendorSolutionServiceDetails(true)
                                }
                                onSaveNext={() => {
                                  addVendorSolutionServiceDetails(true);
                                }}
                                onUpdate={() =>
                                  updateVendorSolutionServiceDetails(false)
                                }
                                onSave={() => {
                                  addVendorSolutionServiceDetails(false);
                                }}
                                buttonType="button"
                              />
                            </div>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  )}
                </div>
              </form>
            </Box>
          </Modal>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <DataTable
            uniqueKey="integrationServiceId"
            query={query}
            columns={selectedColumns}
            adminTable={vendorTable}
            onSearch={onSearchHandler}
            onSort={sortHandler}
            onPageSizeChange={pageSizeChangeHandler}
            handleChangePage={handleChangePageNumber}
            totalPages={totalPages}
            pageSize={pageSize}
            onCheckboxChange={onCheckboxChangeHandler}
            permissions={vendorIntSolutionPermission}
            onToggle={onToggleHandler}
            onEdit={(data) => onEdit(data)}
            onView={(data) => onView(data)}
            checkboxesNotRequired={true}
            showToggle={true}
            showEdit={true}
            showView={true}
          />

          {tableColumnModalVisibility && (
            <TableColumnModal
              isOpen={tableColumnModalVisibility}
              onCancelTableColumnSelection={onCancelTableColumnSelection}
              onSaveTableColumnSelection={onSaveTableColumnSelection}
              selection={selectedColumns}
            />
          )}
          <Confirmation
            open={confirmation}
            handleClose={handleConfirmationClose}
            title={confirmationMessage}
            cancelButtonLabel="No"
            confirmButtonLabel="Yes"
            onCancel={() => {
              handleConfirmationClose();
            }}
            onConfirm={() => {
              changeContentStatus(selectedSolution, STATUS_ENUM.INACTIVE);
            }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default IntServiceList;
