import * as Yup from "yup";
import moment from "moment";
import { REGULAR_EXPRESSION } from "../../constants/regular-expressions";
import { COUNTRY_OPTIONS, INTEGRATION_TYPE } from "../../constants/common";

const phoneWithExtensionSchema = Yup.string()
  .test("phone-extension", "Incorrect format", (value) => {
    if (!value) return true; // Allow empty value
    const [phonePart, extensionPart] = value.split(",");
    return (
      REGULAR_EXPRESSION.PHONE_NUMBER.test(phonePart) &&
      (!extensionPart || /^\d{2,6}$/.test(extensionPart))
    );
  })
  .required("");

const secondaryPhoneWithExtensionSchema = Yup.string()
  .test("combined-validation", "Invalid number", (value, context) => {
    const phoneNumberRegex = REGULAR_EXPRESSION.PHONE_NUMBER;
    const [phonePart, extensionPart] = value ? value.split(",") : [null, null];
    const primaryPhoneNumber = context.parent.phoneNumber;

    if (!value) {
      return true;
    } // Allow empty value
    else if (phonePart === primaryPhoneNumber) {
      return false; // Must be different from primary phone number
    } else if (
      !phoneNumberRegex.test(phonePart) ||
      (extensionPart && !/^\d{2,6}$/.test(extensionPart))
    ) {
      return false; // Invalid format
    } else {
      return true; // Valid
    }
  })
  .nullable(true);

const CommonSchema = {
  mobile: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  fax: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  mobile1: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  fax1: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .matches(
      REGULAR_EXPRESSION.NAME,
      "Only Alphabets, Hyphen(-) & Apostrophe(') allowed"
    )
    .required(""),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .matches(
      REGULAR_EXPRESSION.NAME,
      "Only Alphabets, Hyphen(-) & Apostrophe(') allowed"
    )
    .required(""),
  distributorType: Yup.array().min(1, "Select Distributor").required(""),
  primaryEmail: Yup.string()
    .max(50, "Cannot be greater than 50 characters")
    .email("Invalid email")
    .required(""),
  secondaryEmail: Yup.string().email("Invalid email").nullable(true),
  phoneNumber: phoneWithExtensionSchema,
  secondaryPhoneNumber: secondaryPhoneWithExtensionSchema,
  billingPhoneNumber: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .nullable(true),

  zip: Yup.string().when("countryId", {
    is: COUNTRY_OPTIONS.CANADA,
    then: Yup.string()
      .matches(
        REGULAR_EXPRESSION.ZIP_CODE_CANADA,
        "Invalid Postal Code. Format is ANA NAN, where 'A' is an uppercase alphabet and 'N' is a digit."
      )
      .nullable(true),
    otherwise: Yup.string()
      .matches(
        REGULAR_EXPRESSION.ZIP_CODE_USA,
        "Invalid Zip Code. Please enter a 5-digit Zip Code or a 9-digit ZIP+4 code in the format 12345 or 12345-6789."
      )
      .nullable(true),
  }),
  websiteUrl: Yup.string()
    .matches(REGULAR_EXPRESSION.URL, "Must be a valid url")
    .nullable(true),
  numberOfOwners: Yup.string()
    .matches(REGULAR_EXPRESSION.NUMBER, "Must be only digits")
    .nullable(true),
  businessYear: Yup.string()
    .matches(REGULAR_EXPRESSION.NUMBER, "Must be only digits")
    .nullable(true),
  producers: Yup.string()
    .matches(REGULAR_EXPRESSION.NUMBER, "Must be only digits")
    .nullable(true),
  taxID: Yup.string()
    .matches(REGULAR_EXPRESSION.NUMBER, "Must be only digits")
    .nullable(true),
};

const VendorsSchema = {
  name: Yup.string().required("").nullable(true),
  email: Yup.string()
    .max(50, "Cannot be greater than 50 characters")
    .email("Invalid email")
    .required(""),
  secondaryEmail: Yup.string()
    .max(50, "Cannot be greater than 50 characters")
    .email("Invalid email")
    .nullable(true),
  phoneNumber: phoneWithExtensionSchema,
  zipCode: Yup.string().when("countryId", {
    is: COUNTRY_OPTIONS.CANADA,
    then: Yup.string()
      .matches(
        REGULAR_EXPRESSION.ZIP_CODE_CANADA,
        "Invalid Postal Code. Format is ANA NAN, where 'A' is an uppercase alphabet and 'N' is a digit."
      )
      .nullable(true),
    otherwise: Yup.string()
      .matches(
        REGULAR_EXPRESSION.ZIP_CODE_USA,
        "Invalid Zip Code. Please enter a 5-digit Zip Code or a 9-digit ZIP+4 code in the format 12345 or 12345-6789."
      )
      .nullable(true),
  }),
};
const VendorsContactSchema = {
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .matches(
      REGULAR_EXPRESSION.NAME,
      "Only Alphabets, Hyphen(-) & Apostrophe(') allowed"
    )
    .required(""),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .matches(
      REGULAR_EXPRESSION.NAME,
      "Only Alphabets, Hyphen(-) & Apostrophe(') allowed"
    )
    .required(""),
  email: Yup.string()
    .max(50, "Cannot be greater than 50 characters")
    .email("Invalid email")
    .required(""),
  phoneNumber: phoneWithExtensionSchema,
  mobile: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .required(""),
};

const VendorsSolutionSchema = {
  name: Yup.string().required("").nullable(true),
  description: Yup.string().required("").nullable(true),
};

const VendorsSolutionServiceSchema = {
  serviceName: Yup.string().required("Service Name is required").nullable(true),
  serviceAPIURL: Yup.string()
    .required("Service API URL is required")
    .nullable(true),
  integrationMethod: Yup.string()
    .oneOf(["1", "2", "3"], "Invalid Integration Method selected")
    .required("Integration Method is required")
    .nullable(true),
  integrationType: Yup.number()
    .oneOf(
      [INTEGRATION_TYPE.BASIC, INTEGRATION_TYPE.JWT, INTEGRATION_TYPE.API_KEY],
      "Invalid Integration Type selected"
    )
    .required("Integration Type is required")
    .nullable(true),
  integrationTypeBasic: Yup.object().when("integrationType", {
    is: INTEGRATION_TYPE.BASIC,
    then: Yup.object().shape({
      userName: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
    }),
    otherwise: Yup.object().nullable(true),
  }),
  integrationTypeJWT: Yup.object().when("integrationType", {
    is: INTEGRATION_TYPE.JWT,
    then: Yup.object().shape({
      token: Yup.string().required("Token is required"),
    }),
    otherwise: Yup.object().nullable(true),
  }),
  integrationTypeAPIKey: Yup.object().when("integrationType", {
    is: INTEGRATION_TYPE.API_KEY,
    then: Yup.object().shape({
      keyName: Yup.string().required("Key Name is required"),
      keyValue: Yup.string().required("Key Value is required"),
      addTo: Yup.string().required("Add To is required"),
    }),
    otherwise: Yup.object().nullable(true),
  }),
};

const ReusableLinkInputSchema = {
  name: Yup.string().required(""),
  email: Yup.string()
    .max(50, "Cannot be greater than 50 characters")
    .email("Invalid email")
    .required(""),
  phoneNumber: Yup.string()
    .matches(REGULAR_EXPRESSION.PHONE_NUMBER, "Incorrect format")
    .required(""),
};

const associateSchema = Yup.object().shape({
  distributorUserId: Yup.object().shape({
    value: Yup.number().moreThan(0, "Associated distributor is required"),
  }),
  associationId: Yup.string()
    .matches(REGULAR_EXPRESSION.ALPHANUMERIC, "Must be alpha numberic")
    .required("Association Id is required"),
  // distributorSubscriptionId: Yup.string(),
  frequencyType: Yup.string()
    .equals(["1", "2", "3", "4"], "Select Frequency")
    .required("Select Frequency"),
  distributorSubscriptionId: Yup.number()
    .typeError("Invalid Subscription ID")
    .required("")
    .moreThan(0, ""),
});
export const distributorsubscriptionPlanDomainsSchema = {
  baseValue: Yup.number()
    .positive("Base Value must be greater than 0")
    .typeError("Base Value must be greater than 0")
    .required(""),
  frequency: Yup.number().positive("").typeError("").required(""),
  effectiveFromDate: Yup.date().when("distributorSubscriptionPlanId", {
    is: (distributorSubscriptionPlanId) => distributorSubscriptionPlanId > 0,
    then: Yup.date()
      .nullable()
      .default(null)
      .required("")
      .typeError("Please input the date in the correct format"),
    otherwise: Yup.date()
      .nullable()
      .default(null)
      .required("")
      .min(
        moment(new Date().setHours(0, 0, 0, 0)),
        "Date must be a present or future date"
      )
      .typeError("Please input the date in the correct format"),
  }),
  effectiveToDate: Yup.date().when(
    [
      "distributorSubscriptionPlanId",
      "savedEffectiveToDate",
      "effectiveFromDate",
    ],
    {
      is: (distributorSubscriptionPlanId, savedEffectiveToDate) =>
        !distributorSubscriptionPlanId > 0 ||
        (savedEffectiveToDate ? false : true),
      then: Yup.date()
        .nullable()
        .default(null)
        .when(["effectiveFromDate", "distributorSubscriptionPlanId"], {
          is: (effectiveFromDate, distributorSubscriptionPlanId) =>
            effectiveFromDate && !distributorSubscriptionPlanId > 0,
          then: Yup.date()
            .nullable()
            .default(null)
            .min(
              Yup.ref("effectiveFromDate"),
              "Date must be greater than or equal to the Effective From date."
            )
            .typeError("Please input the date in the correct format"),
          otherwise: Yup.date()
            .nullable()
            .default(null)
            .min(
              moment(new Date().setHours(0, 0, 0, 0)),
              "Date must be a present or future date"
            )
            .typeError("Please input the date in the correct format"),
        })
        .typeError("Please input the date in the correct format"),
      otherwise: Yup.date()
        .nullable()
        .default(null)
        .typeError("Please input the date in the correct format"),
    }
  ),
};

const distributorSubscriptionDomainsSchema = Yup.object().shape({
  name: Yup.string().required(""),
  subscriptionPlan: Yup.array().of(
    Yup.object().shape(distributorsubscriptionPlanDomainsSchema)
  ),
});

export const SignupSchema = Yup.object().shape({
  ...CommonSchema,
});

export const UserSignupSchema = Yup.object().shape({
  ...CommonSchema,
  roles: Yup.array().min(1, "Select Roles").required(""),
});

export const DistributorSchema = Yup.object().shape({
  ...CommonSchema,

  // roles: Yup.array().min(1, "Select Roles").required(""),
  distributorSubscriptionDomains: Yup.array().of(
    distributorSubscriptionDomainsSchema
  ),
  brandName: Yup.string().required("").nullable(true),
});

export const EnterpriseGroupUserSchema = Yup.object().shape({
  ...CommonSchema,
  associateDistributors: Yup.array().of(associateSchema),
  roles: Yup.array().min(1, "Select Roles").required(""),
  brandName: Yup.string().required("").nullable(true),
});

export const InternalUserSchema = Yup.object().shape({
  ...CommonSchema,
  roles: Yup.array().min(1, "Select Roles").required(""),
});

export const EmailSchema = Yup.object().shape({
  primaryEmail: Yup.string()
    .max(50, "Cannot be greater than 50 characters")
    .email("Invalid email")
    .required(""),
});

export const SubscriberSchema = Yup.object().shape({
  ...CommonSchema,
  subscription: Yup.object().shape({
    startDate: Yup.date().typeError("Invalid Date"),
    // .min(moment().subtract(1, "days"), "Start Date can't be a past date"),
    // endDate: Yup.date()
    //   .typeError("Invalid Date")
    //   .when("startDate", (startDate, schema) =>
    //     startDate
    //       ? schema.min(
    //           moment(startDate).add(1, "days"),
    //           "End Date should be greater than Start Date"
    //         )
    //       : schema
    //   ),
    // subscriptionId: Yup.number()
    //   .typeError("Invalid Subscription ID")
    //   .moreThan(0, ""),
  }),
  // egSubscriptions: Yup.array().of(
  // Yup.object().shape({
  // startDate: Yup.date().required("Start date is required"),
  // endDate: Yup.date()
  //   .min(Yup.ref("startDate"), "End Date should be greater than Start Date")
  //   .required("End date is required"),
  // })
  // ),
});

export const SettingsSchema = Yup.object().shape({
  writeToUs: Yup.string().email("Invalid email").required(""),
  aboutOrLegal: Yup.string()
    .matches(REGULAR_EXPRESSION.URL, "Must be a valid url")
    .required(""),
  helpOrSupport: Yup.string()
    .matches(REGULAR_EXPRESSION.URL, "Must be a valid url")
    .required(""),
});

export const VendorSchema = Yup.object().shape({
  ...VendorsSchema,
});
export const VendorContactSchema = Yup.object().shape({
  ...VendorsContactSchema,
});
export const VendorIntegrationSolutionSchema = Yup.object().shape({
  ...VendorsSolutionSchema,
});
export const VendorIntegrationServiceSchema = Yup.object().shape({
  ...VendorsSolutionServiceSchema,
});
export const ReusableLinkSchema = Yup.object().shape({
  ...ReusableLinkInputSchema,
});
