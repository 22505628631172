import React from "react";
import { TreeView, TreeItem } from "@mui/lab";
import { TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const CustomTreeItem = ({ nodeId, label, children }) => (
  <TreeItem
    nodeId={nodeId}
    label={
      <div
        style={{
          margin: "2px",
          backgroundColor: "white",
        }}
      >
        <TextField
          value={label}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          size="small"
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "black",
            },
            pointerEvents: "none",
          }}
          disabled
          fullWidth
        />
      </div>
    }
  >
    {children}
  </TreeItem>
);

const APISchemaTree = () => {
  return (
    <TreeView
      aria-label="API Schema Tree"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{
        overflowY: "auto",
        maxWidth: 400,
      }}
    >
      <CustomTreeItem nodeId="1" label="emp_Full Name" />
      <CustomTreeItem nodeId="2" label="Address">
        <CustomTreeItem nodeId="3" label="Country" />
        <CustomTreeItem nodeId="4" label="State" />
        <CustomTreeItem nodeId="5" label="City" />
      </CustomTreeItem>
      <CustomTreeItem nodeId="6" label="Fill Primitive" />
      <CustomTreeItem nodeId="7" label="BorderPrimitive" />
      <CustomTreeItem nodeId="8" label="Stack Layout Panel" />
      <CustomTreeItem nodeId="9" label="Assets">
        <CustomTreeItem nodeId="10" label="External Accounting">
          <CustomTreeItem nodeId="11" label="Cash and Cash Balances">
            <CustomTreeItem nodeId="12" label="Cash Reserve" />
          </CustomTreeItem>
        </CustomTreeItem>
      </CustomTreeItem>
    </TreeView>
  );
};

export default APISchemaTree;
