import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  List,
  ListSubheader,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Vendors.css";
import DataTable from "../Tables/DataTable/DataTable";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckboxList from "../../common/CheckboxList/CheckboxList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ClearSelectionData,
  GetAllSelectedIds,
  GetSelectedColumns,
  SelectionFilter,
} from "../../utilities/SelectionFilter";
import TableColumnModal from "../../common/TableColumnModal/TableColumnModal";
import ApiServices from "../../services/ApiServices";
import { useLoading } from "../../hooks/useLoader";
import {
  VENDOR_EXPORT,
  VENDOR_LIST,
  VENDOR_STATUS,
} from "../../constants/api-constants";
import { toast } from "react-toastify";
import ExportToExcelHandler from "../../utilities/ExportToExcelHandler";
import { MenuListItems } from "../../common/Sidebar/MenuListItems";
import { SystemModulesEnum } from "../../constants/SystemUsers";
import {
  PermissionEnum,
  PermissionService,
} from "../../constants/permissionService";
import { useVendorContext } from "../Vendors/Vendor.context";
import Confirmation from "../../common/Confirmation/Confirmation";
import { STATUS_ENUM, TABS } from "../../constants/common";

/*istanbul ignore next */
const Vendors = (props) => {
  const { showLoader } = useLoading();
  const VendorContext = useVendorContext();
  const history = useHistory();
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [vendorTable, setVendorTableData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedVendor, setSelectedVendor] = useState(null);
  const handleConfirmationOpen = (actionName, cnfMessage) => {
    setConfirmationMessage(cnfMessage);
    setConfirmation(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationMessage("");
    setConfirmation(false);
  };

  const [query, setQuery] = useState({
    searchText: "",
    SortType: "desc",
    IgnorePaging: false,
    SortColumn: "userId",
    PageNumber: 1,
    PageSize: 10,
    Filter: {
      Status: selectedStatus,
    },
  });

  useEffect(() => {
    vendorDetails();
  }, [query]);

  useEffect(() => {
    localStorage.setItem("selectedVendorTab", TABS.VENDOR_BASIC);
  }, []);

  useEffect(() => {
    setSelectionColumns();
  }, [query]);
  const open = Boolean(anchorEl);
  const onFilterStatusChangeHandler = (values) => {
    setSelectedStatus(values);
  };

  let moduleId = MenuListItems.find(
    (item) => item.name === SystemModulesEnum.VENDORS
  ).moduleId;
  const permissions = PermissionService(moduleId);

  const applyFilterHandler = (Filter) => {
    ClearSelectionData();
    setQuery((prevState) => {
      return {
        ...prevState,
        PageNumber: 1,
        Filter: {
          Status: selectedStatus,
        },
      };
    });
  };

  const clearFilterHandler = () => {
    setSelectedStatus([]);
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return {
          ...prevState,
          PageNumber: 1,
          searchText: event.target.value,
        };
      });
    }, 1000);
  };

  const sortHandler = (sortOrder, sortBy) => {
    setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };

  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };
  /*istanbul ignore next */
  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };

  const onCheckboxChangeHandler = (e, value) => {
    const { checked } = e.target;
    if (value === "all") {
      setVendorTableData((items) => {
        SelectionFilter(items, "multiple", checked);
        return items.map((data) => {
          return {
            ...data,
            isChecked: checked,
          };
        });
      });
    } else {
      setVendorTableData((items) => {
        return items.map((user) => {
          let userRecord = { ...user };
          if (user.vendorId === value) {
            SelectionFilter(user, "single", checked, "vendorId");
            userRecord.isChecked = checked;
          }
          return userRecord;
        });
      });
    }
  };

  const onEdit = (vendor) => {
    vendor.editable = true;
    VendorContext.setEditMode(true);
    history.push(`/vendor/${vendor.vendorId}`);
  };
  const onView = (vendor) => {
    vendor.editable = false;
    VendorContext.setEditMode(false);
    history.push(`/vendor/${vendor.vendorId}`);
  };

  const onCancelTableColumnSelection = () => {
    setSelectionColumns();
    setTableColumnModalVisibility(false);
  };

  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, listOfVendorDataColumns: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    }
    setSelectionColumns();
    setTableColumnModalVisibility(false);
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let adminCols = selectedCols?.listOfVendorDataColumns
      ? selectedCols?.listOfVendorDataColumns
      : null;
    if (adminCols) {
      setSelectedColumns(adminCols);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onToggleHandler = async (
    column,
    userId,
    contentSubscriberStatus,
    e,
    selectedVendor
  ) => {
    setSelectedVendor(selectedVendor);
    if (selectedVendor.status === 1) {
      handleConfirmationOpen(
        "Inactivate",
        "Are you sure you want to mark the Vendor as Inactive?"
      );
    } else {
      changeContentStatus(selectedVendor, "", STATUS_ENUM.ACTIVE);
    }
  };
  const changeContentStatus = async (vendorObj, reason, status) => {
    try {
      const response = await ApiServices.httpPut(VENDOR_STATUS, {
        vendorId: vendorObj.vendorId,
        status: status,
        reason: reason,
      });

      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        handleConfirmationClose();
        vendorDetails();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };

  const vendorDetails = async () => {
    try {
      showLoader(true);
      const vendorData = await ApiServices.httpGet(
        `${VENDOR_LIST}/${JSON.stringify(query)}`
      );
      showLoader(false);
      if (vendorData.responseStatus.isSuccess) {
        setVendorTableData(vendorData?.result?.records);
        setTotalPages(vendorData?.result?.totalPages);
        setPageSize(vendorData?.result?.pageSize);
      } else {
        toast.error(vendorData.responseStatus.error);
      }
    } catch (e) {
      showLoader(false);
      toast.error();
    }
  };

  const handleExport = async () => {
    try {
      let ids = GetAllSelectedIds("vendorId");
      let queryState = { ...query };
      queryState.SelectedIds = ids;
      showLoader(true);
      queryState.Filter.selectedColumns = GetSelectedColumns(12);
      const exportedContent = await ApiServices.httpGet(
        `${VENDOR_EXPORT}/${JSON.stringify(queryState)}`
      );
      if (exportedContent?.result?.file) {
        ExportToExcelHandler(
          exportedContent.result.file.fileContents,
          exportedContent.result.file.fileDownloadName
        );
      } else {
        toast.error(exportedContent?.responseStatus?.error);
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };
  return (
    <Grid spacing={2} container className="reports-container">
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <Box pt={2} pr={4} pl={4} pb={0}>
            <h5>Vendors</h5>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} justifyContent={"flex-end"} display={"flex"} mr={5}>
        <div className="add-welcome-widgets">
          <div>
            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              id={"simple-popover"}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
            >
              <div className="expanded-filter">
                <div className="filter-options">
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Filters will be applied to your grid
                      </ListSubheader>
                    }
                  >
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ width: "33%", flexShrink: 0 }}>
                          Status
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CheckboxList
                          id="status"
                          options={[
                            { value: 1, label: "Active" },
                            { value: 0, label: "Inactive" },
                          ]}
                          onChange={onFilterStatusChangeHandler}
                          selectedValues={selectedStatus}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </List>
                </div>
                <div className="modal-footer">
                  <Button
                    variant="outlined"
                    className="action-button mr-4"
                    onClick={clearFilterHandler}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="contained"
                    className="action-button"
                    onClick={() => {
                      applyFilterHandler();
                      handleClose();
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Popover>
          </div>

          {permissions?.includes(PermissionEnum.CREATE) && (
            <Tooltip placement="bottom" title="Add New Vendor">
              <AddOutlinedIcon
                name="add-icon"
                onClick={() => {
                  VendorContext.setEditMode(true);
                  history.push(`/vendor`);
                }}
              />
            </Tooltip>
          )}

          {
            <Tooltip placement="bottom" title="Filter">
              <FilterAltOutlinedIcon
                onClick={handleClick}
                title="filter-icon"
              />
            </Tooltip>
          }
          {
            <Tooltip placement="bottom" title="Change View">
              <BorderColorIcon
                data-testid="TableColumnSelectionIcon"
                onClick={() => {
                  setTableColumnModalVisibility(true);
                }}
              />
            </Tooltip>
          }
          {
            <Tooltip placement="bottom" title="Export to Excel">
              <IosShareRoundedIcon
                data-testid="IosShareRoundedIcon"
                onClick={handleExport}
              />
            </Tooltip>
          }
        </div>
      </Grid>

      <Grid item xs={12}>
        <Box pr={4} pl={4} pb={0}>
          <DataTable
            uniqueKey="vendorId"
            query={query}
            columns={selectedColumns}
            onEdit={(data) => onEdit(data)}
            onView={(data) => onView(data)}
            adminTable={vendorTable}
            onSearch={onSearchHandler}
            onSort={sortHandler}
            onPageSizeChange={pageSizeChangeHandler}
            handleChangePage={handleChangePageNumber}
            totalPages={totalPages}
            pageSize={pageSize}
            onCheckboxChange={onCheckboxChangeHandler}
            permissions={permissions}
            onToggle={onToggleHandler}
            showToggle={true}
            showEdit={true}
            showView={true}
          />

          {tableColumnModalVisibility && (
            <TableColumnModal
              isOpen={tableColumnModalVisibility}
              onCancelTableColumnSelection={onCancelTableColumnSelection}
              onSaveTableColumnSelection={onSaveTableColumnSelection}
              selection={selectedColumns}
            />
          )}
          <Confirmation
            open={confirmation}
            handleClose={handleConfirmationClose}
            title={confirmationMessage}
            cancelButtonLabel="No"
            confirmButtonLabel="Yes"
            onCancel={() => {
              handleConfirmationClose();
            }}
            onConfirm={() => {
              changeContentStatus(selectedVendor, "", STATUS_ENUM.INACTIVE);
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Vendors;
