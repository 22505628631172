import {
  ADD_DISTRIBUTOR,
  DISTRIBUTOR,
  ENTERPRISE_GROUP_USER,
  ENTERPRISE_GROUP_USERS,
  SUBSCRIBER,
  SUBSCRIBERS,
} from "./api-constants";
import {
  SubscriberSchema,
  DistributorSchema,
  EnterpriseGroupUserSchema,
} from "../common/ValidationSchema/ValidationSchema";

export const UserTypes = {
  distributor: {
    newTitle: "Distributors & Publishers",
    title: "Distributors & Publishers",
    getUsers: DISTRIBUTOR,
    getUser: ADD_DISTRIBUTOR,
    userTypeId: 3,
    userTypeName: "Distributor",
    internalUserTypeId: 5,
    listRoute: "/distributors",
    addRoute: "/distributor",
    editRoute: "/distributor/:id",
    userTypeLabel: "Distributors Type",
    userTypeField: "enterpriseType",
    userTypeOptions: "distributorTypes",
    queryVar: "distributorId",
    addEditInternalUserKey: "distributorUserId",
    internalUserFieldLabel: "DISTRIBUTOR ID",
    addUserTooltip: "Add New Distributor",
    userRolesKey: "distributorInternalUserRoles",
    errorMessage: "Please Add Distributor First !",
    bulkUploadTemplate: "/templates/Distributer_Internal_User_Bulk_Upload.xlsx",
    profileTabs: [
      {
        id: 0,
        name: "Basic Information",
        label: "basic-information",
        disabled: false,
        module_name: "Basic Information",
      },
      {
        id: 1,
        name: "User/Contacts",
        label: "internal-users",
        disabled: false,
        module_name: "Distributor Internal User",
      },
      {
        id: 2,
        name: "Content",
        label: "content",
        disabled: false,
        module_name: "Distributor Content",
      },
      {
        id: 3,
        name: "Catalog",
        label: "catalog",
        disabled: false,
        module_name: "Distributor Catalog",
      },
      {
        id: 4,
        name: "Bundle",
        label: "bundle",
        disabled: false,
        module_name: "Distributor Bundle",
      },
    ],
    schema: DistributorSchema,
    distTypeError: "",
    componentName: "distributor",
    moduleId: 2, //as defined in MenuListItem.js
  },
  enterpriseGroupUser: {
    title: "Enterprise Groups",
    newTitle: "Enterprise Groups",
    getUsers: ENTERPRISE_GROUP_USERS,
    getUser: ENTERPRISE_GROUP_USER,
    userTypeId: 4,
    userTypeName: "Enterprise User",
    internalUserTypeId: 6,
    listRoute: "/enterprisegroups",
    addRoute: "/enterprisegroup",
    editRoute: "/enterprisegroup/:id",
    userTypeLabel: "Enterprise Type",
    userTypeField: "enterpriseType",
    userTypeOptions: "enterpriseTypes",
    queryVar: "enterpriseGroupId",
    addEditInternalUserKey: "enterpriseGroupId",
    internalUserFieldLabel: "ENTERPRISE GROUP ID",
    addUserTooltip: "Add New Enterprise User",
    userRolesKey: "enterpriseInternalUserRoles",
    errorMessage: "Please Add Enterprise User First !",
    bulkUploadTemplate:
      "/templates/EnterpriseGroup_Internal_User_Bulk_Upload.xlsx",
    profileTabs: [
      {
        id: 0,
        name: "Basic Information",
        label: "basic-information",
        disabled: false,
        module_name: "Enterprise Basic Details",
      },
      {
        id: 1,
        name: "User/Contacts",
        label: "internal-users",
        disabled: false,
        module_name: "Enterprise Internal User",
      },
      {
        id: 2,
        name: "Content",
        label: "content",
        disabled: false,
        module_name: "Enterprise Content",
      },
      {
        id: 3,
        name: "Catalog",
        label: "catalog",
        disabled: false,
        module_name: "Enterprise Catalog",
      },
      {
        id: 4,
        name: "Bundle",
        label: "bundle",
        disabled: false,
        module_name: "Enterprise Bundle",
      },
    ],
    schema: EnterpriseGroupUserSchema,
    distTypeError: "",
    componentName: "enterprise",
    moduleId: 3, //as defined in MenuListItem.js
  },
  subscriber: {
    title: "Subscribers",
    newTitle: "Subscribers",
    getUsers: SUBSCRIBERS,
    getUser: SUBSCRIBER,
    userTypeId: 7,
    userTypeName: "Subscriber",
    // internalUserTypeId: 6,
    listRoute: "/subscribers",
    addRoute: "/subscriber",
    editRoute: "/subscriber/:id",
    userTypeLabel: "Subscriber Type",
    userTypeField: "subscriber",
    userTypeOptions: "subscriberTypes",
    queryVar: "subscribers",
    addEditInternalUserKey: "subscriberId",
    internalUserFieldLabel: "SUBSCRIBER ID",
    addUserTooltip: "Add New Subscriber",
    // userRolesKey: "enterpriseInternalUserRoles",
    errorMessage: "Please Add Subscriber First !",
    bulkUploadTemplate: "/templates/Subscriber_Bulk_Upload.xlsx",
    bulkUploadadminTemplate:
      "/templates/admin_or_super_admin/Subscriber_Bulk_Upload.xlsx",
    profileTabs: [
      {
        id: 0,
        name: "Basic Information",
        label: "basic-information",
        disabled: false,
        module_name: "Basic Information",
      },
    ],
    schema: SubscriberSchema,
    distTypeError: "",
    componentName: "subscriber",
    moduleId: 5, //as defined in MenuListItem.js
  },
};
