import React, { useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../DataTable/DataTable.css";
import {
  Checkbox,
  Grid,
  InputAdornment,
  Pagination,
  TextField,
} from "@mui/material";
import moment from "moment";
import ToggleSwitch from "../../../common/Switch/ToggleSwitch";
import EditAdminModal from "../../../common/Modal/EditAdmin/EditAdminModal";
import ApiServices from "../../../services/ApiServices";
import {
  ADMIN_CHANGE_STATUS,
  INTERNAL_USER_CHANGE_STATUS,
  UNBLOCK_USER,
} from "../../../constants/api-constants";
import { toast } from "react-toastify";
import Confirmation from "../../../common/Confirmation/Confirmation";
import { useLoading } from "../../../hooks/useLoader";
import { COLUMN_TYPES } from "../../../constants/common";
import AddEditInternalUserModal from "../../../common/Modal/InternalUsers/AddEditInternalUserModal";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { usePageMode } from "../../../hooks/usePageMode";
import { useHistory } from "react-router";
import { useAuth } from "../../../hooks/useAuth";

const AdminDataTable = (props) => {
  const {
    userRoleId,
    columns,
    showFavorite,
    permissions,
    PermissionEnum,
    masterData,
    currentUserId,
  } = props;
  const { showLoader } = useLoading();
  const [open, setOpen] = React.useState(false);
  const [ids, setIds] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [selectedColumnName, setSelectedColumnName] = React.useState(null);
  const [selectedSubEmail, setSelectedSubEmail] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const page = usePageMode();
  const auth = useAuth();
  const selectedRole = auth.getRole();
  const history = useHistory();
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [modalValue, setModalValue] = React.useState({});
  const [sortColumn, setSortColumn] = React.useState(""); // To track the currently sorted column
  const [sortOrder, setSortOrder] = React.useState(""); // To track the sorting order (ascending or descending)
  const [openUnBlockUserPopup, setOpenUnBlockUserPopup] = React.useState(false);
  const tableContainerRef = useRef(null);
  const isIntUser = window.location.pathname.includes("internal-users");
  const isInternaluser = isIntUser;
  const scrollBarReset = JSON.stringify(
    props.adminTable?.map(({ isChecked, ...rest }) => rest)
  );

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [scrollBarReset]);

  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked again, reverse the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If a different column is clicked, set it as the new sorting column in ascending order
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const getSortImage = (column) => {
    if (sortColumn === column) {
      if (sortOrder === "asc") {
        return "/images/Ascending@2x-1.png";
      } else {
        return "/images/Descending@2x-1.png";
      }
    } else {
      return "/images/unsorted.png";
    }
  };

  const onChangeHandler = async () => {
    try {
      if (selectedColumnName === "associatedStatus" && selectedSubEmail) {
        props.handleAssociation(selectedSubEmail);
        handleClose();
      } else {
        showLoader(true);
        let API_END_POINT = ADMIN_CHANGE_STATUS;
        if (props?.tableType === "internalTable") {
          API_END_POINT = INTERNAL_USER_CHANGE_STATUS;
        }
        const res = await ApiServices.httpPut(API_END_POINT, {
          userId: ids,
          status: status ? "0" : "1",
        });
        showLoader(false);
        if (res.responseStatus.isSuccess) {
          toast.success(res.responseStatus.description);
          props.success(true);
          handleClose();
        } else {
          toast.error(res.responseStatus.error);
          props.success(false);
        }
      }
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const onUnBlockUserHandler = async () => {
    try {
      showLoader(true);
      const res = await ApiServices.httpPost(UNBLOCK_USER, {
        userId: ids,
      });
      showLoader(false);
      if (res.responseStatus?.isSuccess) {
        toast.success(res.responseStatus.description);
        props.success(true);
        setOpenUnBlockUserPopup(false);
      } else {
        toast.error(res.responseStatus.error);
        props.success(false);
      }
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const handleCloseModalCallback = () => {
    setOpenEditModal(false);
  };

  const handleSuccessAddEdit = () => {
    props.success(true);
  };

  const renderTableHead = () => {
    return (
      <TableRow>
        <TableCell style={{ width: "56px" }}>
          <div className="col-details">
            <Checkbox
              role={"checkbox"}
              name="selectUser"
              data-testid="select-all"
              checked={
                props.adminTable?.length > 0 &&
                props.adminTable?.filter((user) => user.isChecked).length ===
                  props.adminTable?.length
              }
              onChange={(event) => props.onCheckboxChange(event, "all")}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
              style={{ paddingLeft: 0 }}
            />
          </div>
        </TableCell>

        {columns &&
          columns.map(
            (column) =>
              column.isSelected && (
                <TableCell
                  className={column.isFixed ? "sticky-header" : ""}
                  style={
                    column.isFixed
                      ? {
                          minWidth: column.width + "px",
                          maxWidth: column.width + "px",
                          right: column.rightPadding + "px",
                        }
                      : {}
                  }
                >
                  <div className="col-details">
                    <h6
                      className="col-details-heading col-details-heading-fname table-column"
                      style={{ width: column.width }}
                    >
                      {column.label}
                      {column.sort && (
                        <img
                          className="sort-icon"
                          data-testid={`sort-by-${column.fieldName}`}
                          src={getSortImage(column.fieldName)}
                          alt="sort"
                          onClick={() => {
                            handleSort(column.fieldName);
                            props.onSort(
                              props.query.SortType,
                              column.fieldName
                            );
                          }}
                        />
                      )}
                    </h6>
                  </div>
                </TableCell>
              )
          )}
      </TableRow>
    );
  };

  const renderTableBody = () => {
    return props?.adminTable?.map((res, index) => {
      return (
        <TableRow
          key={res.userId}
          className={index % 2 === 0 ? "table-row-grey" : "table-row-white"}
        >
          <TableCell style={{ width: "56px" }}>
            <Checkbox
              role="checkbox"
              name="selectUser"
              data-testid={`select-${res.userId}`}
              onChange={(event) => props.onCheckboxChange(event, res.userId)}
              checked={res.isChecked || false}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
              style={{ paddingLeft: 0 }}
            />
          </TableCell>
          {columns &&
            columns.map((column) => {
              return column.isSelected && renderComponentTypes(column, res);
            })}
        </TableRow>
      );
    });
  };

  const renderComponentTypes = (column, res) => {
    let cellElement;
    switch (column.type) {
      case COLUMN_TYPES.EMAIL:
        cellElement = (
          <TableCell
            className={column.isFixed ? "sticky-col" : ""}
            style={styleWhenIsFixed(column)}
          >
            <a
              className="primary-email-url"
              href={`mailto:${res[column.fieldName]}`}
            >
              {res[column.fieldName]}
            </a>
          </TableCell>
        );
        break;
      case COLUMN_TYPES.MULTI_VALUE:
        cellElement = (
          <TableCell
            className={classWhenMultiValue(column)}
            style={styleWhenIsFixed(column)}
            title={
              res[column.fieldName] &&
              res[column.fieldName].flatMap((r) => r.name).join(", ")
            }
          >
            {res[column.fieldName] &&
              res[column.fieldName].flatMap((r) => r.name).join(", ")}
          </TableCell>
        );
        break;
      case COLUMN_TYPES.UNBLOCK_TOGGLE:
        cellElement = onUnblockToggle(
          res,
          props,
          permissions,
          PermissionEnum,
          setOpenUnBlockUserPopup,
          setIds,
          column
        );

        break;
      case COLUMN_TYPES.STATUS_CHANGE:
        let dataObj = {
          props: props,
          cellElement: cellElement,
          res: res,
          permissions: permissions,
          PermissionEnum: PermissionEnum,
          handleOpen: handleOpen,
          setIds: setIds,
          setStatus: setStatus,
          setSelectedcolumnName: setSelectedColumnName,
          setSelectedSubEmail: setSelectedSubEmail,
          open: open,
          column: column,
        };
        cellElement = onStatusChange(dataObj);
        break;
      case COLUMN_TYPES.ACTION:
        let dataObj2 = {
          props: props,
          cellElement: cellElement,
          selectedRole: selectedRole,
          currentUserId: currentUserId,
          res: res,
          setModalValue: setModalValue,
          setOpenEditModal: setOpenEditModal,
          permissions: permissions,
          PermissionEnum: PermissionEnum,
          page: page,
          history: history,
          userRoleId: userRoleId,
          masterData: masterData,
          showFavorite: showFavorite,
          column: column,
        };
        cellElement = onAction(dataObj2);
        break;
      case COLUMN_TYPES.OBJECT:
        cellElement = (
          <TableCell
            className={classWhenObject(column)}
            style={styleWhenIsFixed(column)}
          >
            {renderDate(res, column)}
          </TableCell>
        );
        break;
      default:
        cellElement = (
          <TableCell
            className={classWhenDefault(column)}
            style={styleWhenIsFixed(column)}
          >
            {res[column.fieldName]}
          </TableCell>
        );
    }
    return cellElement;
  };

  const renderDate = (res, column) => {
    try {
      return column.objName
        ? moment(res[column.objName][column.fieldName]).format("MM/DD/YYYY")
        : "";
    } catch (e) {
      //e
    }
  };

  return (
    <div className="admin-table">
      <Confirmation
        open={open}
        handleClose={handleClose}
        title={`${status === 1 ? "Deactivate" : "Activate"} User`}
        description={`Are you sure you want to ${
          status === 1 ? "deactivate" : "activate"
        }
        the user?`}
        onCancel={() => {
          props.success(false);
          setOpen(false);
        }}
        onConfirm={onChangeHandler}
        cancelButtonLabel="Cancel"
        confirmButtonLabel={status === 1 ? "Deactivate" : "Activate"}
      />

      <Confirmation
        open={openUnBlockUserPopup}
        handleClose={handleClose}
        title="Are you sure you want to unlock the account?"
        onCancel={() => {
          setOpenUnBlockUserPopup(false);
          setIds(null);
        }}
        onConfirm={() => {
          onUnBlockUserHandler();
        }}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
      />

      <TableContainer component={Paper}>
        <div className="table-content">
          <p>
            <span className="mr-2">Show</span>
            <select
              onChange={(e) => props.onPageSizeChange(e.target.value)}
              className="select"
              id="cars"
              name="cars"
              data-testid="pageSizeCombo"
            >
              <option value="10" selected>
                10
              </option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="ml-2">entries</span>
          </p>
          <div className="table-search-boxx">
            <TextField
              className="table-search-box"
              onChange={props.onSearch}
              type="text"
              placeholder="Search Everything..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div className="search-icon-div">
                      <img
                        src="/images/search-icon.png"
                        alt="search icon"
                        name="search-icon"
                      />
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        <div
          className={isInternaluser ? "resp-table-dist-internal" : "resp-table"}
          ref={tableContainerRef}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>{renderTableHead()}</TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </div>

        {props.totalPages > 1 && (
          <div className="table-pagination">
            <Pagination
              page={props.page}
              onChange={props.handleChangePage}
              rowsPerPage={10}
              count={props.totalPages}
              variant="outlined"
              shape="rounded"
            />
          </div>
        )}
      </TableContainer>
      {openEditModal ? (
        <AddEditInternalUserModal
          routeData={props.routeData}
          isEditMode={true}
          userRole={props.userRole}
          data={modalValue}
          distributorData={props.distributorData}
          userRolesDropdown={props.userRolesDropdown}
          handleCloseModalCallback={handleCloseModalCallback}
          handleSuccessAddEdit={handleSuccessAddEdit}
          permissions={permissions}
        />
      ) : null}
    </div>
  );
};

export default AdminDataTable;

function styleWhenIsFixed(column) {
  return column.isFixed
    ? {
        minWidth: column.width + "px",
        maxWidth: column.width + "px",
        right: column.rightPadding + "px",
      }
    : {};
}

function classWhenDefault(column) {
  return column.isFixed ? "sticky-col" : "";
}

function classWhenObject(column) {
  return column.isFixed ? "sticky-col" : "";
}

function classWhenMultiValue(column) {
  return column.isFixed
    ? "sticky-col roles admin-data-roles"
    : "roles admin-data-roles";
}

function onUnblockToggle(
  res,
  props,
  permissions,
  PermissionEnum,
  setOpenUnBlockUserPopup,
  setIds,
  column
) {
  return (
    <TableCell
      className={
        column.isFixed ? "sticky-col toggle-switch-td" : "toggle-switch-td"
      }
      style={
        column.isFixed
          ? {
              minWidth: column.width + "px",
              maxWidth: column.width + "px",
              right: column.rightPadding + "px",
            }
          : {}
      }
    >
      <ToggleSwitch
        dataTestId="accountStatus"
        activeLabelText="Unlocked"
        inactiveLabelText="Locked"
        state={!res.isLocked}
        id={res.userId}
        coloredDisable={!res.isLocked}
        onChange={() => {
          if (res.userId === props.currentUserId) {
            toast.error("You are not allowed to conduct this action.");
            return;
          }
          if (
            permissions &&
            permissions.includes(PermissionEnum.UPDATE) &&
            res.isLocked
          ) {
            setOpenUnBlockUserPopup(true);
            setIds(res.userId);
          } else {
            //we can block user is not written on story so we are not doing any action on active/unblocked user
          }
        }}
      />
    </TableCell>
  );
}

function onStatusChange(dataObj) {
  if (isToggleSwitchOne(dataObj.props)) {
    dataObj.cellElement = toggleSwitchOne(dataObj);
  } else {
    dataObj.cellElement = toggleSwitchTwo(dataObj);
  }
  return dataObj.cellElement;
}

function isToggleSwitchOne(props) {
  return (
    props?.routeData?.userTypeId === 3 ||
    props?.routeData?.userTypeId === 4 ||
    props?.routeData?.userTypeId === 7
  );
}

function toggleSwitchTwo(dataObj) {
  let cellElement = (
    <TableCell
      className={dataObj.column.isFixed ? "sticky-col" : ""}
      style={
        dataObj.column.isFixed
          ? {
              minWidth: dataObj.column.width + "px",
              maxWidth: dataObj.column.width + "px",
              right: dataObj.column.rightPadding + "px",
              position: "relative",
            }
          : { position: "relative" }
      }
    >
      <ToggleSwitch
        activeLabelText="Active"
        inactiveLabelText="Inactive"
        success={dataObj.props.success}
        id={dataObj.res.userId}
        state={dataObj.res[dataObj.column.fieldName]}
        onChange={() => {
          if (dataObj.res.userId === dataObj.props.currentUserId) {
            toast.error("You are not allowed to conduct this action.");
            return;
          }
          if (
            dataObj.permissions &&
            dataObj.permissions.includes(dataObj.PermissionEnum.UPDATE)
          ) {
            dataObj.handleOpen();
            dataObj.setIds(dataObj.res.userId);
            dataObj.setStatus(dataObj.res[dataObj.column.fieldName]);
            dataObj.setSelectedcolumnName(dataObj.column.fieldName);
            dataObj.setSelectedSubEmail(dataObj.res.email);
          }
        }}
        open={dataObj.open}
      />
    </TableCell>
  );
  return cellElement;
}

function toggleSwitchOne(dataObj) {
  let cellElement = (
    <TableCell
      className={
        dataObj.column.isFixed
          ? "sticky-col toggle-switch-td"
          : "toggle-switch-td"
      }
      style={
        dataObj.column.isFixed
          ? {
              minWidth: dataObj.column.width + "px",
              maxWidth: dataObj.column.width + "px",
              right: dataObj.column.rightPadding + "px",
            }
          : {}
      }
    >
      <ToggleSwitch
        activeLabelText="Active"
        inactiveLabelText="Inactive"
        state={dataObj.res[dataObj.column.fieldName]}
        id={dataObj.res.userId}
        onChange={() => {
          if (dataObj.res.userId === dataObj.props.currentUserId) {
            toast.error("You are not allowed to conduct this action.");
            return;
          }
          if (
            dataObj.permissions &&
            dataObj.permissions.includes(dataObj.PermissionEnum.UPDATE)
          ) {
            dataObj.handleOpen();
            dataObj.setIds(dataObj.res.userId);
            dataObj.setStatus(dataObj.res[dataObj.column.fieldName]);
            dataObj.setSelectedcolumnName(dataObj.column.fieldName);
            dataObj.setSelectedSubEmail(dataObj.res.email);
          }
        }}
        open={dataObj.open}
      />
    </TableCell>
  );
  return cellElement;
}

function onAction(dataObj) {
  if (dataObj.props?.tableType === "internalTable") {
    whenInteralTableAction(dataObj);
  } else if ([3, 4, 7].includes(dataObj.props?.routeData?.userTypeId)) {
    whenSpecificUserTypeId(dataObj);
  } else {
    whenElseCase(dataObj);
  }
  return dataObj.cellElement;
}
function whenElseCase(dataObj) {
  dataObj.cellElement = (
    <TableCell
      align="right"
      className={
        dataObj.column.isFixed ? "sticky-col action-cell" : "action-cell"
      }
      style={
        dataObj.column.isFixed
          ? {
              minWidth: dataObj.column.width + "px",
              maxWidth: dataObj.column.width + "px",
              right: dataObj.column.rightPadding + "px",
            }
          : {}
      }
    >
      <Grid container className="action-item-icons">
        {dataObj.permissions &&
          dataObj.permissions.includes(dataObj.PermissionEnum.UPDATE) && (
            <Grid item mr={1}>
              <EditAdminModal
                userRoleId={dataObj.userRoleId}
                isEditMode={true}
                userRole={dataObj.props.userRole}
                onClick={dataObj.props.onClick}
                data={dataObj.res}
                onEditSuccess={dataObj.props.onEditSuccess}
                masterData={dataObj.masterData}
              />
            </Grid>
          )}
        {dataObj.permissions &&
          dataObj.permissions.includes(dataObj.PermissionEnum.LIST) && (
            <Grid item>
              <EditAdminModal
                isEditMode={false}
                userRole={dataObj.props.userRole}
                onClick={dataObj.props.onClick}
                data={dataObj.res}
                onEditSuccess={dataObj.props.onEditSuccess}
                masterData={dataObj.masterData}
              />
            </Grid>
          )}
        {dataObj.showFavorite && (
          <Grid item ml={1}>
            <StarRateRoundedIcon />
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
}

function whenSpecificUserTypeId(dataObj) {
  dataObj.cellElement = (
    <TableCell
      align="right"
      className={
        dataObj.column.isFixed ? "sticky-col action-cell" : "action-cell"
      }
      style={
        dataObj.column.isFixed
          ? {
              minWidth: dataObj.column.width + "px",
              maxWidth: dataObj.column.width + "px",
              right: dataObj.column.rightPadding + "px",
            }
          : {}
      }
    >
      <Grid container className="action-item-icons">
        {dataObj.permissions &&
          dataObj.permissions.includes(dataObj.PermissionEnum.UPDATE) && (
            <Grid item mr={1}>
              <img
                className="edit-button"
                src="/images/edit_enable.png"
                alt="Edit"
                onClick={() => {
                  dataObj.page.setBreadcrumbRootNode({
                    title: dataObj.props.routeData?.title,
                    path: dataObj.props.routeData?.listRoute,
                  });
                  dataObj.page.setEditMode(true);
                  dataObj.history.push(
                    dataObj.props.routeData?.editRoute.replace(
                      ":id",
                      dataObj.res.userId + "/basic-information"
                    )
                  );
                }}
                width={20}
              />
            </Grid>
          )}
        {dataObj.permissions &&
          dataObj.permissions.includes(dataObj.PermissionEnum.LIST) && (
            <Grid item>
              <img
                className="view-button"
                src="/images/view_enable.png"
                alt="view-icon"
                width={20}
                onClick={() => {
                  dataObj.page.setEditMode(false);
                  dataObj.history.push(
                    dataObj.props.routeData?.editRoute.replace(
                      ":id",
                      dataObj.res.userId + "/basic-information"
                    )
                  );
                }}
              />
            </Grid>
          )}
      </Grid>
    </TableCell>
  );
}

function whenInteralTableAction(dataObj) {
  dataObj.cellElement = (
    <TableCell
      align="right"
      className={dataObj.column.isFixed ? "sticky-col" : ""}
      style={
        dataObj.column.isFixed
          ? {
              minWidth: dataObj.column.width + "px",
              maxWidth: dataObj.column.width + "px",
              right: dataObj.column.rightPadding + "px",
            }
          : {}
      }
    >
      <div className="action-items internal-user-action-item pr-2">
        {(dataObj.selectedRole === 5 || dataObj.selectedRole === 6) &&
        dataObj.currentUserId !== dataObj.res.userId ? (
          <img
            alt="Edit"
            title="You are not authorized to edit other user."
            className="edit-button"
            src="/images/edit_disable.png"
            data-testid="EditIcon"
            width={20}
          />
        ) : (
          <img
            alt="Edit"
            title="Edit User"
            className="edit-button"
            src="/images/edit_enable.png"
            onClick={() => {
              dataObj.setModalValue(dataObj.res);
              dataObj.setOpenEditModal(true);
            }}
            data-testid="EditIcon"
            width={20}
          />
        )}
      </div>
    </TableCell>
  );
}
