import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  List,
  ListSubheader,
  Modal,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../Vendors/Vendors.css";
import DataTable from "../Tables/DataTable/DataTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  ClearSelectionData,
  SelectionFilter,
} from "../../utilities/SelectionFilter";
import TableColumnModal from "../../common/TableColumnModal/TableColumnModal";
import ApiServices from "../../services/ApiServices";
import { useLoading } from "../../hooks/useLoader";
import {
  VENDOR_INTEGRATIONSOLUTION,
  VENDOR_INTEGRATIONSOLUTION_LIST,
  VENDOR_INTEGRATIONSOLUTION_STATUS,
} from "../../constants/api-constants";
import { toast } from "react-toastify";
import { MenuListItems } from "../../common/Sidebar/MenuListItems";
import { SystemModulesEnum } from "../../constants/SystemUsers";
import {
  childModulePermissions,
  PermissionEnum,
} from "../../constants/permissionService";
import { useVendorContext } from "../Vendors/Vendor.context";
import Confirmation from "../../common/Confirmation/Confirmation";
import { STATUS_ENUM, TABS } from "../../constants/common";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
// import { a11yProps } from "../../common/TabPanel/a11yProps";
import CheckboxList from "../../common/CheckboxList/CheckboxList";
import { useFormik } from "formik";
import { VendorIntegrationSolutionSchema } from "../../common/ValidationSchema/ValidationSchema";
import FooterButtons from "../../common/FooterButtons/FooterButtons";
import { useAuth } from "../../hooks/useAuth";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import APISchemaTree from "./ApiSchemaTree";

/*istanbul ignore next */
const IntSolutionList = (props) => {
  const auth = useAuth();
  const { showLoader } = useLoading();
  const { id } = useParams();
  const permissionId = auth.getPermissionId();
  const vendorContext = useVendorContext();
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [isEditMode, setIsEditMode] = useState(vendorContext.isEditMode);
  const [integrationSolutionId, setIntegrationSolutionId] = useState(0);
  const [vendorTable, setVendorTableData] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [openSolModal, setopenSolModal] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [expanded, setExpanded] = React.useState("panel1");
  const [anchorEl, setAnchorEl] = useState(null);
  const open2 = Boolean(anchorEl);
  const handleConfirmationOpen = (actionName, cnfMessage) => {
    setConfirmationMessage(cnfMessage);
    setConfirmation(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationMessage("");
    setConfirmation(false);
  };

  const vendorSolutionInit = {
    name: "",
    description: "",
    vendorId: props.vendorId,
    status: 1,
  };
  const formik = useFormik({
    initialValues: vendorSolutionInit,
    enableReinitialize: true,
    validationSchema: VendorIntegrationSolutionSchema,
  });

  const [query, setQuery] = useState({
    searchText: "",
    SortType: "desc",
    IgnorePaging: false,
    SortColumn: "userId",
    PageNumber: 1,
    PageSize: 10,
    Filter: {
      Status: selectedStatus,
    },
  });

  const sstyle = {
    position: "absolute",
    right: "0%",
    width: 800,
    height: "100%",
    bgcolor: "#fff",
    border: "1px solid #000",
    p: 2,
  };

  useEffect(() => {
    vendorSolutionList();
    setSelectionColumns();
  }, [query]);

  useEffect(() => {
    if (integrationSolutionId && openSolModal) {
      vendorSolutionDetails();
    }
  }, [integrationSolutionId, openSolModal]);

  useEffect(() => {
    if (id && vendorIntSolutionPermission?.includes(PermissionEnum.LIST)) {
      vendorSolutionList();
    }
  }, []);

  let vendorContactModuleId = MenuListItems.find(
    (item) => item.name === SystemModulesEnum.VENDORS
  )?.moduleId;
  const vendorIntSolutionPermission = childModulePermissions(
    vendorContactModuleId,
    SystemModulesEnum.VENDOR_INTEGRATION_SOLUTION
  );

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return {
          ...prevState,
          PageNumber: 1,
          searchText: event.target.value,
        };
      });
    }, 1000);
  };

  const sortHandler = (sortOrder, sortBy) => {
    setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };

  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };
  /*istanbul ignore next */
  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };
  const handleVendorSolutionClose = () => {
    setopenSolModal(false);
    formik.setValues(vendorSolutionInit);
  };
  const applyFilterHandler = (Filter) => {
    ClearSelectionData();
    setQuery((prevState) => {
      return {
        ...prevState,
        PageNumber: 1,
        Filter: {
          Status: selectedStatus,
        },
      };
    });
  };

  const onCheckboxChangeHandler = (e, value) => {
    const { checked } = e.target;
    if (value === "all") {
      setVendorTableData((items) => {
        SelectionFilter(items, "multiple", checked);
        return items.map((data) => {
          return {
            ...data,
            isChecked: checked,
          };
        });
      });
    } else {
      setVendorTableData((items) => {
        return items.map((user) => {
          let userRecord = { ...user };
          if (user.integrationSolutionId === value) {
            SelectionFilter(user, "single", checked, "integrationSolutionId");
            userRecord.isChecked = checked;
          }
          return userRecord;
        });
      });
    }
  };

  const onCancelHandler = () => {
    setIsEditMode(false);
    setopenSolModal(false);
  };
  const onEditHandler = () => {
    setIsEditMode(true);
  };

  const onEdit = (record) => {
    setIntegrationSolutionId(record.integrationSolutionId);
    setIsEditMode(true);
    setopenSolModal(true);
  };
  const onView = (record) => {
    setIntegrationSolutionId(record.integrationSolutionId);
    setIsEditMode(false);
    setopenSolModal(true);
  };
  const onClickService = (vendor) => {
    props.setIntegrationSolutionId(vendor.integrationSolutionId);
    props.setSelectedSubTab(TABS.VENDOR_SERVICES);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFilterStatusChangeHandler = (values) => {
    setSelectedStatus(values);
  };
  const clearFilterHandler = () => {
    setSelectedStatus([]);
  };

  const onCancelTableColumnSelection = () => {
    setSelectionColumns();
    setTableColumnModalVisibility(false);
  };

  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, listOfSolutionDataColumns: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    }
    setSelectionColumns();
    setTableColumnModalVisibility(false);
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let adminCols = selectedCols?.listOfSolutionDataColumns
      ? selectedCols?.listOfSolutionDataColumns
      : null;
    if (adminCols) {
      setSelectedColumns(adminCols);
    }
  };

  const onToggleHandler = async (
    column,
    userId,
    contentSubscriberStatus,
    e,
    selectedSolution
  ) => {
    setSelectedSolution(selectedSolution);
    if (selectedSolution.status === 1) {
      handleConfirmationOpen(
        "Inactivate",
        "Are you sure you want to mark the Integration Solution as Inactive?"
      );
    } else {
      changeContentStatus(selectedSolution, STATUS_ENUM.ACTIVE);
    }
  };
  const changeContentStatus = async (vendorObj, status) => {
    try {
      const response = await ApiServices.httpPut(
        VENDOR_INTEGRATIONSOLUTION_STATUS,
        {
          integrationSolutionId: vendorObj.integrationSolutionId,
          status: Number(status),
        }
      );

      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        handleConfirmationClose();
        vendorSolutionList();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };

  const vendorSolutionDetails = async () => {
    showLoader(true);
    const data = await ApiServices.httpGet(
      `${VENDOR_INTEGRATIONSOLUTION}/${integrationSolutionId}`
    );
    showLoader(false);
    formik.setValues(data.result);
  };

  const vendorSolutionList = async () => {
    try {
      showLoader(true);
      const contactQuery = {
        ...query,
        Filter: { ...query.Filter, vendorId: props.vendorId },
      };
      const vendorData = await ApiServices.httpGet(
        `${VENDOR_INTEGRATIONSOLUTION_LIST}/${JSON.stringify(contactQuery)}`
      );
      showLoader(false);
      if (vendorData.responseStatus.isSuccess) {
        setVendorTableData(vendorData?.result?.records);
        setTotalPages(vendorData?.result?.totalPages);
        setPageSize(vendorData?.result?.pageSize);
      } else {
        toast.error(vendorData.responseStatus.error);
      }
    } catch (e) {
      showLoader(false);
      toast.error();
    }
  };

  const addVendorSolutionDetails = async (isNext) => {
    try {
      const payload = {
        ...formik.values,
      };
      showLoader(true);
      const response = await ApiServices.httpPost(
        VENDOR_INTEGRATIONSOLUTION,
        payload
      );
      if (response.responseStatus.isSuccess) {
        vendorSolutionList();
        setopenSolModal(false);
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      toast.error("Internal server error");
    } finally {
      showLoader(false);
    }
  };

  const updateVendorSolutionDetails = async (isNext) => {
    try {
      const payload = {
        ...formik.values,
      };
      showLoader(true);
      const response = await ApiServices.httpPut(
        VENDOR_INTEGRATIONSOLUTION,
        payload
      );
      if (response.responseStatus.isSuccess) {
        formik.setValues(response.result);
        setopenSolModal(false);
        vendorSolutionList();
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      toast.error("Internal server error");
    } finally {
      showLoader(false);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Box mb={2} mt={-1} className="top-right-actions">
          <div className="add-welcome-widgets">
            <div>
              <Popover
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                id={"simple-popover"}
                open={open2}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <div className="expanded-filter">
                  <div className="filter-options">
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      subheader={
                        <ListSubheader
                          component="div"
                          id="nested-list-subheader"
                        >
                          Filters will be applied to your grid
                        </ListSubheader>
                      }
                    >
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            Status
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CheckboxList
                            id="status"
                            options={[
                              { value: 1, label: "Active" },
                              { value: 0, label: "Inactive" },
                            ]}
                            onChange={onFilterStatusChangeHandler}
                            selectedValues={selectedStatus}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </List>
                  </div>
                  <div className="modal-footer">
                    <Button
                      variant="outlined"
                      className="action-button mr-4"
                      onClick={clearFilterHandler}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      className="action-button"
                      onClick={() => {
                        applyFilterHandler();
                        handleClose();
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </Popover>
            </div>
            {vendorIntSolutionPermission?.includes(PermissionEnum.CREATE) && (
              <Tooltip placement="bottom" title="Add New Integration Solution">
                <AddOutlinedIcon
                  name="add-icon"
                  onClick={() => {
                    formik.setValues(vendorSolutionInit);
                    setIntegrationSolutionId(0);
                    setopenSolModal(true);
                    setIsEditMode(true);
                  }}
                />
              </Tooltip>
            )}
            <Tooltip placement="bottom" title="Filter">
              <FilterAltOutlinedIcon
                title="filter-icon"
                onClick={handleClick}
              />
            </Tooltip>
          </div>
        </Box>
      </Grid>
      <Grid item xs={2} sx={{ display: "contents" }}>
        <Box>
          <Modal
            open={openSolModal}
            onClose={() => {
              setopenSolModal(false);
              handleVendorSolutionClose();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="cbhdsabhsdab"
          >
            <Box sx={sstyle} className="custom-modal">
              <form>
                <div className="modal-header">
                  <div className="modal-heading-title">
                    Integration Solutions
                  </div>
                  <div
                    className="modal-header-right"
                    style={{ cursor: "pointer" }}
                  >
                    <CloseOutlinedIcon
                      data-testid="CloseNewAdminPopup"
                      onClick={() => {
                        setopenSolModal(false);
                        handleVendorSolutionClose();
                      }}
                    />
                  </div>
                </div>
                <div
                  className="modal-body"
                  style={{ maxHeight: "100vh", overflowY: "auto" }}
                >
                  <Accordion
                    className="accordion-ent"
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontWeight: 700 }}>
                        Basic Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        padding={2}
                        className="blue-field-section"
                      >
                        <Grid
                          container
                          padding={0}
                          sx={{ background: "white" }}
                        >
                          <Grid item xs={12} p={2}>
                            <label
                              htmlFor="brandName"
                              style={{ display: "block", marginBottom: "4px" }}
                            >
                              Solution Name
                              <MandatoryField />
                            </label>
                            <input
                              id="name"
                              name="name"
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Solution Name"
                              style={{ width: "100%" }}
                              value={formik.values?.name}
                              onChange={(e) => {
                                if (
                                  !formik.values?.name &&
                                  e.target.value === " "
                                )
                                  return;
                                formik.setFieldValue("dirty", true);
                                formik.handleChange(e);
                              }}
                              disabled={!isEditMode}
                            />
                          </Grid>
                          <Grid item xs={12} p={2}>
                            <label
                              htmlFor="firstName"
                              style={{ display: "block", marginBottom: "4px" }}
                            >
                              Description
                              <MandatoryField />
                            </label>
                            <textarea
                              id="description"
                              name="description"
                              className="form-label form-control bs-cus scrollable-input-content"
                              type="text"
                              placeholder="Add Description"
                              value={formik.values?.description}
                              onChange={(e) => {
                                if (
                                  !formik.values?.description &&
                                  e.target.value === " "
                                )
                                  return;
                                formik.setFieldValue("dirty", true);
                                formik.handleChange(e);
                              }}
                              disabled={!isEditMode}
                              sx={{
                                "&.Mui-disabled": {
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "transparent",
                                    border: "none",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "transparent",
                                    border: "none",
                                  },
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginTop: "16px",
                          marginLeft: "-20px",
                        }}
                      >
                        <FooterButtons
                          isEditMode={isEditMode}
                          permissions={vendorIntSolutionPermission}
                          permissionId={permissionId}
                          editPermission={integrationSolutionId > 0}
                          showUpdateNext={integrationSolutionId > 0}
                          showSaveNext={integrationSolutionId === 0}
                          disableUpdateNext={!isEditMode || !formik.isValid}
                          disableSaveNext={!isEditMode || !formik.isValid}
                          showUpdateDetail={integrationSolutionId > 0}
                          disableUpdateDetail={!isEditMode || !formik.isValid}
                          disableSaveDetail={!isEditMode || !formik.isValid}
                          onCancel={onCancelHandler}
                          onEdit={onEditHandler}
                          onUpdateNext={() => updateVendorSolutionDetails(true)}
                          onSaveNext={() => {
                            addVendorSolutionDetails(true);
                          }}
                          onUpdate={() => updateVendorSolutionDetails(false)}
                          onSave={() => {
                            addVendorSolutionDetails(false);
                          }}
                          buttonType="button"
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  {props.selectedSubTab === TABS.VENDOR_SERVICES && (
                    <Accordion
                      className="accordion-ent"
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography sx={{ fontWeight: 700 }}>
                          Service Details
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          maxHeight: "100vh",
                          overflowY: "auto",
                          marginBottom: "50px",
                        }}
                      ></AccordionDetails>
                    </Accordion>
                  )}
                </div>
              </form>
            </Box>
          </Modal>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <DataTable
            uniqueKey="integrationSolutionId"
            query={query}
            columns={selectedColumns}
            adminTable={vendorTable}
            onSearch={onSearchHandler}
            onSort={sortHandler}
            onPageSizeChange={pageSizeChangeHandler}
            handleChangePage={handleChangePageNumber}
            totalPages={totalPages}
            pageSize={pageSize}
            onCheckboxChange={onCheckboxChangeHandler}
            permissions={vendorIntSolutionPermission}
            onToggle={onToggleHandler}
            onEdit={(data) => onEdit(data)}
            onView={(data) => onView(data)}
            showToggle={true}
            showEdit={true}
            showView={true}
            showServiceIcon={true}
            onClickService={(data) => onClickService(data)}
            checkboxesNotRequired={true}
          />

          {tableColumnModalVisibility && (
            <TableColumnModal
              isOpen={tableColumnModalVisibility}
              onCancelTableColumnSelection={onCancelTableColumnSelection}
              onSaveTableColumnSelection={onSaveTableColumnSelection}
              selection={selectedColumns}
            />
          )}
          <Confirmation
            open={confirmation}
            handleClose={handleConfirmationClose}
            title={confirmationMessage}
            cancelButtonLabel="No"
            confirmButtonLabel="Yes"
            onCancel={() => {
              handleConfirmationClose();
            }}
            onConfirm={() => {
              changeContentStatus(selectedSolution, STATUS_ENUM.INACTIVE);
            }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default IntSolutionList;
