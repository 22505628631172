import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  Tooltip,
  Modal,
  Button,
  Popover,
  List,
  ListSubheader,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { STATUS_ENUM, COUNTRY_OPTIONS, TABS } from "../../constants/common";
import { Link } from "react-router-dom";
import TabPanel from "../../common/TabPanel/TabPanel";
import DataTable from "../Tables/DataTable/DataTable";
import BusinessPhoneNumberTextbox from "../../common/PhoneNumberTextbox/BusinessPhoneNumberTextbox";
import {
  getMaxLength,
  getPhoneCode,
  getZipLabel,
  getZipPlaceholder,
  isCountryCanada,
} from "../../utilities/common";
import FooterButtons from "../../common/FooterButtons/FooterButtons";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./AddEditVendor.css";
import PhoneNumberTextbox from "../../common/PhoneNumberTextbox/PhoneNumberTextbox";
import { useFormik } from "formik";
import InlineMessage from "../../common/InlineMessage/InlineMessage";
import {
  VendorContactSchema,
  VendorSchema,
} from "../../common/ValidationSchema/ValidationSchema";
import ApiServices from "../../services/ApiServices";
import { useLoading } from "../../hooks/useLoader";
import {
  VENDOR,
  VENDOR_CONTACT,
  VENDOR_CONTACT_LIST,
  VENDORCONTACT_EXPORT,
  VENDORCONTACT_STATUS,
} from "../../constants/api-constants";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useVendorContext } from "./Vendor.context";
import { formatDate } from "../../utilities/formatter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  childModulePermissions,
  PermissionEnum,
  PermissionService,
} from "../../constants/permissionService";
import { useAuth } from "../../hooks/useAuth";
import { MenuListItems } from "../../common/Sidebar/MenuListItems";
import { SystemModulesEnum } from "../../constants/SystemUsers";
import {
  ClearSelectionData,
  GetAllSelectedIds,
  GetSelectedColumns,
  SelectionFilter,
} from "../../utilities/SelectionFilter";
import CheckboxList from "../../common/CheckboxList/CheckboxList";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import ExportToExcelHandler from "../../utilities/ExportToExcelHandler";
import TableColumnModal from "../../common/TableColumnModal/TableColumnModal";
import Confirmation from "../../common/Confirmation/Confirmation";
import IntSolutionList from "../VendorIntegrationSolutions/IntSolutionList";
import { a11yProps } from "../../common/TabPanel/a11yProps";
import IntServiceList from "../VendorIntegrationSolutions/IntServiceList";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "#fff",
  border: "1px solid #000",
  boxShadow: 24,
  p: 0,
};

/*istanbul ignore next */
const AddEditVendor = (props) => {
  const history = useHistory();
  const { showLoader } = useLoading();
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const vendorContext = useVendorContext();
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedVendorTab, setSelectedVendorTab] = useState(
    localStorage.getItem("selectedVendorTab") || TABS.VENDOR_BASIC
  );
  const [expanded, setExpanded] = React.useState("panel1");
  const [isEditMode, setIsEditMode] = useState(vendorContext.isEditMode);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [allStateOptions, setAllStateOptions] = useState([]);
  const [vendorTable, setVendorTableData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [vendorContactId, setVendorContactId] = useState(0);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedSubTab, setSelectedSubTab] = useState(TABS.SOLUTION_DETAILS);
  const [integrationSolutionId, setIntegrationSolutionId] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const auth = useAuth();
  const user = auth.getCurrentUser();
  const permissionId = auth.getPermissionId();
  const open2 = Boolean(anchorEl);

  const formik = useFormik({
    initialValues: {
      name: "",
      billingAddressLine1: "",
      billingAddressLine2: "",
      city: "",
      countryId: COUNTRY_OPTIONS.USA,
      countryName: "",
      createdByName: "",
      email: "",
      phoneNumber: "",
      secondaryEmail: "",
      secondaryPhoneNumber: "",
      state: "",
      stateId: null,
      status: 1,
      vendorId: 0,
      zipCode: "",
    },
    enableReinitialize: true,
    validationSchema: VendorSchema,
  });

  const vendorContactInit = {
    firstName: "",
    lastName: "",
    vendorName: "",
    createdByName: "",
    email: "",
    phoneNumber: "",
    secondaryEmail: "",
    mobile: "",
    status: 1,
    vendorId: id,
  };
  const contactFormik = useFormik({
    initialValues: vendorContactInit,
    enableReinitialize: true,
    validationSchema: VendorContactSchema,
  });

  const [query, setQuery] = useState({
    searchText: "",
    SortType: "desc",
    IgnorePaging: false,
    SortColumn: "userId",
    PageNumber: 1,
    PageSize: 10,
    Filter: {
      Status: selectedStatus,
    },
  });

  useEffect(() => {
    populateStateOptions(formik.values.countryId);
  }, [formik.values.countryId, allStateOptions]);

  const setSelectedTab = (value) => {
    localStorage.setItem("selectedVendorTab", value);
    setSelectedVendorTab(value);
  };

  useEffect(() => {
    if (id) {
      vendorDetails();
    }
    const response = populateDropdownOptions();
    setAllStateOptions(response.states);
    setCountryOptions(response.countries);
  }, []);

  useEffect(() => {
    if (vendorContactId && open) {
      vendorContactDetails();
    }
  }, [vendorContactId, open]);

  useEffect(() => {
    setSelectionColumns();
  }, [query]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onFilterStatusChangeHandler = (values) => {
    setSelectedStatus(values);
  };
  const clearFilterHandler = () => {
    setSelectedStatus([]);
  };
  const handleVendorContactClose = () => {
    setOpen(false);
    contactFormik.setValues(vendorContactInit);
  };
  const onEdit = (vendor) => {
    setVendorContactId(vendor.vendorContactId);
    setIsEditMode(true);
    setOpen(true);
  };
  const onView = (vendor) => {
    setVendorContactId(vendor.vendorContactId);
    setIsEditMode(false);
    setOpen(true);
  };

  const handleConfirmationOpen = (actionName, cnfMessage) => {
    setConfirmationMessage(cnfMessage);
    setConfirmation(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationMessage("");
    setConfirmation(false);
  };
  const applyFilterHandler = (Filter) => {
    ClearSelectionData();
    setQuery((prevState) => {
      return {
        ...prevState,
        PageNumber: 1,
        Filter: {
          Status: selectedStatus,
        },
      };
    });
  };
  const onCheckboxChangeHandler = (e, value) => {
    const { checked } = e.target;
    if (value === "all") {
      setVendorTableData((items) => {
        SelectionFilter(items, "multiple", checked);
        return items.map((data) => {
          return {
            ...data,
            isChecked: checked,
          };
        });
      });
    } else {
      setVendorTableData((items) => {
        return items.map((user) => {
          let userRecord = { ...user };
          if (user.vendorContactId === value) {
            SelectionFilter(user, "single", checked, "vendorContactId");
            userRecord.isChecked = checked;
          }
          return userRecord;
        });
      });
    }
  };

  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };
  /*istanbul ignore next */
  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return {
          ...prevState,
          PageNumber: 1,
          searchText: event.target.value,
        };
      });
    }, 1000);
  };

  const sortHandler = (sortOrder, sortBy) => {
    setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };

  const onToggleHandler = async (
    column,
    userId,
    contentSubscriberStatus,
    e,
    selectedVendor
  ) => {
    setSelectedVendor(selectedVendor);
    if (selectedVendor.status === 1) {
      handleConfirmationOpen(
        "Inactivate",
        "Are you sure you want to mark the Contact as Inactive?"
      );
    } else {
      changeContentStatus(selectedVendor, "", STATUS_ENUM.ACTIVE);
    }
  };
  const changeContentStatus = async (vendorObj, reason, status) => {
    try {
      const response = await ApiServices.httpPut(VENDORCONTACT_STATUS, {
        vendorContactId: vendorObj.vendorContactId,
        status: status,
        reason: reason,
      });

      if (response.responseStatus.isSuccess) {
        toast.success(response.responseStatus.description);
        handleConfirmationClose();
        vendorContactListDetails();
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      toast.error("Internal server error");
    }
  };

  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, listOfContactDataColumns: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    }
    setSelectionColumns();
    setTableColumnModalVisibility(false);
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let adminCols = selectedCols?.listOfContactDataColumns
      ? selectedCols?.listOfContactDataColumns
      : null;
    if (adminCols) {
      setSelectedColumns(adminCols);
    }
  };

  const onCancelTableColumnSelection = () => {
    setSelectionColumns();
    setTableColumnModalVisibility(false);
  };

  const handleExport = async () => {
    try {
      let ids = GetAllSelectedIds("vendorContactId");
      let queryState = {
        ...query,
        Filter: { ...query.Filter, vendorId: Number(id) },
      };
      queryState.SelectedIds = ids;
      showLoader(true);
      queryState.Filter.selectedColumns = GetSelectedColumns(13);
      const exportedContent = await ApiServices.httpGet(
        `${VENDORCONTACT_EXPORT}/${JSON.stringify(queryState)}`
      );
      if (exportedContent?.result?.file) {
        ExportToExcelHandler(
          exportedContent.result.file.fileContents,
          exportedContent.result.file.fileDownloadName
        );
      } else {
        toast.error(exportedContent?.responseStatus?.error);
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const vendorDetails = async () => {
    showLoader(true);
    const data = await ApiServices.httpGet(`${VENDOR}/${id}`);
    showLoader(false);
    data.result.countryId = data.result?.countryId || COUNTRY_OPTIONS.USA;
    formik.setValues(data.result);
  };

  const vendorContactDetails = async () => {
    showLoader(true);
    const data = await ApiServices.httpGet(
      `${VENDOR_CONTACT}/${vendorContactId}`
    );
    showLoader(false);
    contactFormik.setValues(data.result);
  };

  const vendorContactListDetails = async () => {
    try {
      showLoader(true);
      const contactQuery = {
        ...query,
        Filter: { ...query.Filter, vendorId: Number(id) },
      };
      const vendorData = await ApiServices.httpGet(
        `${VENDOR_CONTACT_LIST}/${JSON.stringify(contactQuery)}`
      );
      showLoader(false);
      if (vendorData.responseStatus.isSuccess) {
        setVendorTableData(vendorData?.result?.records);
        setTotalPages(vendorData?.result?.totalPages);
        setPageSize(vendorData?.result?.pageSize);
      } else {
        toast.error(vendorData.responseStatus.error);
      }
    } catch (e) {
      showLoader(false);
      toast.error();
    }
  };

  const addVendorDetails = async (isNext) => {
    try {
      const payload = {
        ...formik.values,
      };
      showLoader(true);
      const response = await ApiServices.httpPost(VENDOR, payload);
      if (response.responseStatus.isSuccess) {
        formik.setValues(response.result);
        if (isNext) {
          setIsEditMode(false);
          setSelectedTab(TABS.CONTACTS);
          history.push(`/vendor/${response.result?.vendorId}`);
        } else {
          history.push(`/vendors`);
        }
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      toast.error("Internal server error");
    } finally {
      showLoader(false);
    }
  };

  const addVendorContactDetails = async () => {
    try {
      const payload = {
        ...contactFormik.values,
      };
      showLoader(true);
      const response = await ApiServices.httpPost(VENDOR_CONTACT, payload);
      if (response.responseStatus.isSuccess) {
        vendorContactListDetails();
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      toast.error("Internal server error");
    } finally {
      showLoader(false);
    }
  };

  const updateVendorContactDetails = async () => {
    try {
      const payload = {
        ...contactFormik.values,
      };
      showLoader(true);
      const response = await ApiServices.httpPut(VENDOR_CONTACT, payload);
      if (response.responseStatus.isSuccess) {
        vendorContactListDetails();
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      toast.error("Internal server error");
    } finally {
      showLoader(false);
    }
  };

  const updateVendorDetails = async (isNext) => {
    try {
      const payload = {
        ...formik.values,
      };
      showLoader(true);
      const response = await ApiServices.httpPut(VENDOR, payload);
      if (response.responseStatus.isSuccess) {
        formik.setValues(response.result);
        if (isNext && vendorContactPermission?.includes(PermissionEnum.LIST)) {
          setIsEditMode(true);
          setSelectedTab(TABS.CONTACTS);
        } else {
          onCancelHandler();
        }
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      toast.error("Internal server error");
    } finally {
      showLoader(false);
    }
  };

  useEffect(() => {
    if (id && vendorContactPermission?.includes(PermissionEnum.LIST)) {
      vendorContactListDetails();
    }
    const response = populateDropdownOptions();
    setAllStateOptions(response.states);
    setCountryOptions(response.countries);
  }, [query]);

  useEffect(() => {
    populateStateOptions(formik.values.countryId);
  }, [formik.values.countryId, allStateOptions]);

  const populateStateOptions = (countryId) => {
    const result = allStateOptions?.filter(
      (res) => res.countryId === Number(countryId)
    );
    setStateOptions(result);
  };
  let moduleId = MenuListItems.find(
    (item) => item.name === SystemModulesEnum.VENDORS
  ).moduleId;
  const permissions = PermissionService(moduleId);

  let vendorContactModuleId = MenuListItems.find(
    (item) => item.name === SystemModulesEnum.VENDORS
  )?.moduleId;
  const vendorContactPermission = childModulePermissions(
    vendorContactModuleId,
    SystemModulesEnum.VENDOR_CONTACT
  );
  const vendorSoluitonListPermission = childModulePermissions(
    vendorContactModuleId,
    SystemModulesEnum.VENDOR_INTEGRATION_SOLUTION
  );

  const handleZipChange = (e, formik) => {
    formik.setFieldValue("dirty", true);
    const formattedValue = isCountryCanada(formik.values.countryId)
      ? formatCanadianPostalCode(e.target.value)
      : formatUSZipCode(e.target.value);
    formik.setFieldValue("zipCode", formattedValue);
  };

  const populateDropdownOptions = () => {
    const response = JSON.parse(localStorage.getItem("masterData"));
    if (response.responseStatus.isSuccess) {
      return {
        countries: response.result.countries,
        states: response.result.states,
      };
    } else {
      return {
        countries: [],
        states: [],
      };
    }
  };

  const onCancelHandler = () => {
    setIsEditMode(false);
    history.push(`/vendors`);
  };
  const onEditHandler = () => {
    setIsEditMode(true);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const phoneNumberRegex = (value) => {
    if (!value) {
      return value;
    }
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const isX2 = x[3] ? "-" + x[3] : "";
    x = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + isX2;
    return x;
  };
  const whenEditMode = isEditMode ? "Edit Contact" : "View Contact";
  const getContactTitle = vendorContactId > 0 ? whenEditMode : "Add Contact";

  return (
    <div className="approval-container">
      <div className="approval-basic-details">
        <div>
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            className="distributor-breadcrumbs"
          >
            <Link
              underline="hover"
              color="inherit"
              to="/vendors"
              className="breadcrumb-title"
            >
              Vendors
            </Link>

            <Typography
              color="text.primary"
              className={
                selectedVendorTab === TABS.INTEGRATION_SOLUTIONS
                  ? ""
                  : "breadcrumb-title-intsolution"
              }
            >
              {selectedVendorTab === TABS.VENDOR_BASIC && "Basic Information"}
              {selectedVendorTab === TABS.CONTACTS && "Contacts"}
              <Link
                underline="hover"
                color="inherit"
                className="breadcrumb-title"
              >
                {selectedVendorTab === TABS.INTEGRATION_SOLUTIONS &&
                  "Integration Solutions"}
              </Link>
            </Typography>
            {selectedVendorTab === TABS.INTEGRATION_SOLUTIONS && (
              <Typography color="text.primary" className="content-last">
                {selectedSubTab === TABS.SOLUTION_DETAILS && "Solution Details"}
                {selectedSubTab === TABS.VENDOR_SERVICES && "Services"}
              </Typography>
            )}
          </Breadcrumbs>
        </div>
        {selectedVendorTab === TABS.CONTACTS && (
          <Grid
            item
            xs={12}
            justifyContent={"flex-end"}
            display={"flex"}
            mr={5}
          >
            <div className="add-welcome-widgets">
              <div>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  id={"simple-popover"}
                  open={open2}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                >
                  <div className="expanded-filter">
                    <div className="filter-options">
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                          <ListSubheader
                            component="div"
                            id="nested-list-subheader"
                          >
                            Filters will be applied to your grid
                          </ListSubheader>
                        }
                      >
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography sx={{ width: "33%", flexShrink: 0 }}>
                              Status
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CheckboxList
                              id="status"
                              options={[
                                { value: 1, label: "Active" },
                                { value: 0, label: "Inactive" },
                              ]}
                              onChange={onFilterStatusChangeHandler}
                              selectedValues={selectedStatus}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </List>
                    </div>
                    <div className="modal-footer">
                      <Button
                        variant="outlined"
                        className="action-button mr-4"
                        onClick={clearFilterHandler}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        className="action-button"
                        onClick={() => {
                          applyFilterHandler();
                          handleClose();
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>

              {vendorContactPermission?.includes(PermissionEnum.CREATE) && (
                <Tooltip placement="bottom" title="Add New Contact">
                  <AddOutlinedIcon
                    name="add-icon"
                    onClick={() => {
                      contactFormik.setValues(vendorContactInit);
                      setVendorContactId(0);
                      setOpen(true);
                      setIsEditMode(true);
                    }}
                  />
                </Tooltip>
              )}

              {
                <Tooltip placement="bottom" title="Filter">
                  <FilterAltOutlinedIcon
                    onClick={handleClick}
                    title="filter-icon"
                  />
                </Tooltip>
              }
              {
                <Tooltip placement="bottom" title="Change View">
                  <BorderColorIcon
                    data-testid="TableColumnSelectionIcon"
                    onClick={() => {
                      setTableColumnModalVisibility(true);
                    }}
                  />
                </Tooltip>
              }
              {
                <Tooltip placement="bottom" title="Export to Excel">
                  <IosShareRoundedIcon
                    data-testid="IosShareRoundedIcon"
                    onClick={handleExport}
                  />
                </Tooltip>
              }
            </div>
          </Grid>
        )}
      </div>
      <Modal
        open={open}
        onClose={() => {
          handleVendorContactClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="custom-modal">
          <form>
            <div className="modal-header">
              <div className="modal-heading-title">{getContactTitle}</div>
              <div className="modal-header-right" style={{ cursor: "pointer" }}>
                <CloseOutlinedIcon
                  data-testid="CloseNewAdminPopup"
                  onClick={() => {
                    handleVendorContactClose();
                  }}
                />
              </div>
            </div>
            <div className="modal-body">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <label htmlFor="firstName">First Name</label>
                  <MandatoryField />
                  <input
                    className="form-label form-control"
                    placeholder="Enter First Name"
                    id="firstName"
                    name="firstName"
                    type="text"
                    maxLength={25}
                    autoComplete="off"
                    value={contactFormik.values?.firstName}
                    onChange={(e) => {
                      contactFormik.setFieldValue("dirty", true);
                      contactFormik.handleChange(e);
                    }}
                    disabled={!isEditMode}
                  />
                  <InlineMessage error={contactFormik.errors?.firstName} />
                </Grid>
                <Grid item xs={6}>
                  <label htmlFor="lastName">Last Name</label>
                  <MandatoryField />
                  <input
                    id="lastName"
                    name="lastName"
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter Last Name"
                    maxLength={25}
                    autoComplete="off"
                    value={contactFormik.values?.lastName}
                    onChange={(e) => {
                      contactFormik.setFieldValue("dirty", true);
                      contactFormik.handleChange(e);
                    }}
                    disabled={!isEditMode}
                  />
                  <InlineMessage error={contactFormik.errors?.lastName} />
                </Grid>
                <Grid item xs={8}>
                  <label htmlFor="primaryEmail">Email Address</label>
                  <MandatoryField />
                  <input
                    id="email"
                    name="email"
                    className="form-label form-control"
                    type="email"
                    placeholder="Enter Email Address"
                    autoComplete="off"
                    value={contactFormik.values?.email}
                    onChange={(e) => {
                      contactFormik.setFieldValue("dirty", true);
                      contactFormik.handleChange(e);
                    }}
                    disabled={!isEditMode}
                  />
                  <InlineMessage error={contactFormik.errors?.email} />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={6}>
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <MandatoryField />
                  <BusinessPhoneNumberTextbox
                    id="phoneNumber"
                    className="form-label form-control"
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    onChange={(e) => {
                      contactFormik.setFieldValue("dirty", true);
                      contactFormik.setFieldValue("phoneNumber", e);
                    }}
                    value={contactFormik.values.phoneNumber}
                    disabled={!isEditMode}
                    phoneCode={getPhoneCode(
                      countryOptions,
                      contactFormik.values.countryId
                    )}
                  />
                  <InlineMessage error={contactFormik.errors.phoneNumber} />
                </Grid>

                <Grid item xs={6}>
                  <label htmlFor="mobile">Mobile</label>
                  <MandatoryField />
                  <PhoneNumberTextbox
                    id="mobile"
                    data-testid="mobile"
                    className="form-label form-control"
                    name="mobile"
                    placeholder="Enter Mobile Number"
                    phoneCode={["+1"]}
                    onChange={(e) => {
                      contactFormik.setFieldValue("dirty", true);
                      contactFormik.handleChange(e);
                    }}
                    value={phoneNumberRegex(contactFormik.values?.mobile)}
                    disabled={!isEditMode}
                  />
                  <InlineMessage error={contactFormik.errors?.mobile} />
                </Grid>
                <Grid item xs={5}>
                  <label htmlFor="fax">Fax</label>
                  <PhoneNumberTextbox
                    id="fax"
                    data-testid="fax"
                    className="form-label form-control"
                    name="fax"
                    placeholder="Enter Fax"
                    phoneCode={["+1"]}
                    onChange={(e) => {
                      contactFormik.setFieldValue("dirty", true);
                      contactFormik.handleChange(e);
                    }}
                    value={phoneNumberRegex(contactFormik.values?.fax)}
                    disabled={!isEditMode}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="modal-footer">
              <div>
                <Button
                  variant="outlined"
                  className="action-button mr-4"
                  role="button"
                  name="cancel-create-new-admin"
                  onClick={() => {
                    handleVendorContactClose();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  className="action-button"
                  data-testid="save-button-2"
                  onClick={() => {
                    if (vendorContactId > 0) {
                      updateVendorContactDetails();
                    } else {
                      addVendorContactDetails();
                    }
                    handleVendorContactClose();
                  }}
                  disabled={!contactFormik.isValid || !isEditMode}
                >
                  {vendorContactId > 0 ? "Update Details" : "Save Details"}
                </Button>
              </div>
            </div>
          </form>
          ;
        </Box>
      </Modal>
      <Grid container spacing={2} paddingLeft={4} marginTop={2}></Grid>
      <Grid container spacing={2} paddingLeft={4}>
        <Grid item xs={3} p={2}>
          <div>Vendor Name</div>
          <div className="approval-basic-info">
            {formik.values.name || "xxxxxxxxxxxx"}
          </div>
        </Grid>
        <Grid item xs={3} p={2}>
          <div>Primary Email Address</div>
          <div
            className="approval-basic-info "
            style={{ lineBreak: "anywhere", overflow: "hidden" }}
          >
            {formik.values.email || "xxxxxx@xxxxx.com"}
          </div>
        </Grid>
        <Grid item xs={3} p={2}>
          <div>Secondary Email Address</div>
          <div
            className="approval-basic-info"
            style={{ lineBreak: "anywhere", overflow: "hidden" }}
          >
            {formik.values.secondaryEmail || "xxxxxx@xxxxx.com"}
          </div>
        </Grid>
        <Grid item xs={3} p={2}>
          <div>Phone Number</div>
          <div
            className="approval-basic-info"
            style={{ lineBreak: "anywhere", overflow: "hidden" }}
          >
            {formik.values.phoneNumber || "xxxxxxxxxx"}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0} paddingLeft={4} marginTop={2}>
        <Grid item md={9} lg={11} sm={12} xs={12}>
          <Box
            p={1}
            className="right-section-vendor"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tabs
              value={selectedVendorTab}
              onChange={(e, newValue) => {
                setSelectedTab(newValue);
              }}
            >
              <Tab
                className="my-tabs"
                label={"Basic Information"}
                value={TABS.VENDOR_BASIC}
                disabled={false}
              />
              {vendorContactPermission?.includes(PermissionEnum.LIST) && (
                <Tab
                  className="my-tabs"
                  label={"Contacts"}
                  value={TABS.CONTACTS}
                  disabled={false || formik.values.vendorId === 0}
                />
              )}
              {vendorSoluitonListPermission?.includes(PermissionEnum.LIST) && (
                <Tab
                  className="my-tabs"
                  label={"Integration Solutions"}
                  value={TABS.INTEGRATION_SOLUTIONS}
                  disabled={false || formik.values.vendorId === 0}
                />
              )}
            </Tabs>

            <TabPanel
              value={selectedVendorTab}
              index={TABS.VENDOR_BASIC}
              className="distributor-tab-panel"
            >
              <div className="parent-div-accord">
                <Accordion
                  className="accordion-ent"
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ fontWeight: "700" }}>
                      Basic Details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div className="modal-body">
                        <Grid
                          container
                          spacing={2}
                          className="blue-field-section"
                        >
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="firstName">Vendor Name</label>
                            <MandatoryField />
                            <input
                              id="name"
                              name="name"
                              onChange={(e) => {
                                formik.setFieldValue("dirty", true);
                                formik.handleChange(e);
                              }}
                              type="text"
                              className="form-label form-control"
                              placeholder="Enter Vendor Name"
                              maxLength={25}
                              value={formik.values.name}
                              disabled={!isEditMode}
                            />
                            <InlineMessage error={formik.errors.name} />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="firstName">
                              Primary Email Address
                            </label>
                            <MandatoryField />
                            <input
                              id="email"
                              name="email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              className="form-label form-control"
                              type="text"
                              disabled={!isEditMode}
                              placeholder="Enter Primary Email Address"
                            />
                            <InlineMessage error={formik.errors.email} />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="lastName">
                              Secondary Email Address
                            </label>
                            <input
                              id="secondaryEmail"
                              name="secondaryEmail"
                              onChange={(e) => {
                                formik.setFieldValue("dirty", true);
                                formik.handleChange(e);
                              }}
                              value={formik.values.secondaryEmail}
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Secondary Email Address"
                              disabled={!isEditMode}
                            />
                            <InlineMessage
                              error={formik.errors.secondaryEmail}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="phoneNumber">Phone Number</label>
                            <MandatoryField />
                            <BusinessPhoneNumberTextbox
                              id="phoneNumber"
                              className="form-label form-control"
                              name="phoneNumber"
                              placeholder="Enter Phone Number"
                              onChange={(e) => {
                                formik.setFieldValue("dirty", true);
                                formik.setFieldValue("phoneNumber", e);
                              }}
                              value={formik.values.phoneNumber}
                              disabled={!isEditMode}
                              phoneCode={getPhoneCode(
                                countryOptions,
                                formik.values.countryId
                              )}
                            />
                            <InlineMessage error={formik.errors.phoneNumber} />
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="billingAddressLine1">
                              Billing Address Line 1
                            </label>
                            <input
                              id="billingAddressLine1"
                              name="billingAddressLine1"
                              value={formik.values.billingAddressLine1}
                              className="form-label form-control"
                              onChange={(e) => {
                                formik.setFieldValue("dirty", true);
                                formik.handleChange(e);
                              }}
                              type="text"
                              placeholder="Enter Billing Address Line 1 "
                              disabled={!isEditMode}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="billingAddressLine2">
                              Billing Address Line 2
                            </label>
                            <input
                              id="billingAddressLine2"
                              name="billingAddressLine2"
                              value={formik.values.billingAddressLine2}
                              onChange={(e) => {
                                formik.setFieldValue("dirty", true);
                                formik.handleChange(e);
                              }}
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter Billing Address Line 2"
                              disabled={!isEditMode}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="countryId">Country</label>
                            <select
                              id="countryId"
                              name="countryId"
                              value={formik.values?.countryId}
                              onChange={(e) => {
                                formik.setValues({
                                  ...formik.values,
                                  dirty: true,
                                  stateId: null,
                                  countryId: Number(e.target.value),
                                });
                              }}
                              className="form-control form-select"
                              disabled={!isEditMode}
                            >
                              {countryOptions?.map((res) => {
                                return (
                                  <option
                                    key={res.countryId}
                                    value={res.countryId}
                                  >
                                    {res.name}
                                  </option>
                                );
                              })}
                            </select>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="stateId">State</label>
                            <select
                              id="stateId"
                              name="stateId"
                              value={formik.values.stateId}
                              onChange={(e) => {
                                formik.setFieldValue("dirty", true);
                                formik.handleChange(e);
                              }}
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter State"
                              disabled={!isEditMode}
                            >
                              <option>Select State</option>
                              {stateOptions?.map((res) => {
                                return (
                                  <option key={res.stateId} value={res.stateId}>
                                    {res.name}
                                  </option>
                                );
                              })}
                            </select>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="city">City</label>
                            <input
                              name="city"
                              id="city"
                              value={formik.values.city}
                              onChange={(e) => {
                                formik.setFieldValue("dirty", true);
                                formik.handleChange(e);
                              }}
                              className="form-label form-control"
                              type="text"
                              placeholder="Enter City"
                              disabled={!isEditMode}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={4}
                            p={2}
                            className="blue-field-section"
                          >
                            <label htmlFor="zipCode">
                              {getZipLabel(formik.values.countryId)}
                            </label>
                            <input
                              id="zipCode"
                              name="zipCode"
                              className="form-label form-control"
                              type="text"
                              maxLength={getMaxLength(formik.values.countryId)}
                              placeholder={getZipPlaceholder(
                                formik.values.countryId
                              )}
                              value={formik.values.zipCode}
                              onChange={(e) => handleZipChange(e, formik)}
                              disabled={!isEditMode}
                            />
                            <InlineMessage error={formik.errors.zipCode} />
                          </Grid>
                          <Grid item xs={4} p={2}></Grid>
                          <Grid item xs={4} p={2}></Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="createdDate">Created Date</label>
                            <input
                              id="createdDate"
                              name="createdDate"
                              className="form-label form-control border-cus-cus"
                              type="text"
                              disabled
                              value={
                                formik.values.createdOn
                                  ? formatDate(formik.values.createdOn)
                                  : formatDate(new Date())
                              }
                            />
                          </Grid>
                          <Grid item xs={4} p={2}>
                            <label htmlFor="createdBy">Created By</label>
                            <input
                              id="createdBy"
                              name="createdBy"
                              className="form-label form-control border-cus-cus"
                              type="text"
                              disabled
                              value={
                                formik.values.createdByName
                                  ? formik.values.createdByName
                                  : `${user?.firstName + ""} ${user?.lastName}`
                              }
                            />
                          </Grid>
                          <Grid item xs={4} p={2}></Grid>
                          {formik.values.vendorId ? (
                            <>
                              <Grid item xs={4} p={2}>
                                <label htmlFor="lastUpdatedDate">
                                  Last Updated On
                                </label>
                                <input
                                  id="updatedDate"
                                  name="updatedDate"
                                  className="form-label form-control border-cus-cus"
                                  type="text"
                                  disabled
                                  value={formatDate(formik.values.updatedOn)}
                                />
                              </Grid>
                              <Grid item xs={4} p={2}>
                                <label htmlFor="lastUpdatedBy">
                                  Last Updated By
                                </label>
                                <input
                                  id="updatedBy"
                                  name="updatedBy"
                                  className="form-label form-control border-cus-cus"
                                  type="text"
                                  disabled
                                  value={formik.values.updatedByName}
                                />
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </div>
                    </div>
                    <div>
                      <FooterButtons
                        isEditMode={isEditMode}
                        permissions={permissions}
                        permissionId={permissionId}
                        editPermission={formik.values.vendorId > 0}
                        showUpdateNext={formik.values.vendorId > 0}
                        showSaveNext={!formik.values.vendorId}
                        disableUpdateNext={!isEditMode || !formik.isValid}
                        disableSaveNext={!isEditMode || !formik.isValid}
                        showUpdateDetail={formik.values.vendorId > 0}
                        disableUpdateDetail={!isEditMode || !formik.isValid}
                        disableSaveDetail={!isEditMode || !formik.isValid}
                        onCancel={onCancelHandler}
                        onEdit={onEditHandler}
                        onUpdateNext={() => updateVendorDetails(true)}
                        onSaveNext={() => {
                          addVendorDetails(true);
                        }}
                        onUpdate={() => updateVendorDetails(false)}
                        onSave={() => {
                          addVendorDetails(false);
                        }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </TabPanel>
            <TabPanel
              value={selectedVendorTab}
              index={TABS.CONTACTS}
              className="distributor-tab-panel"
            >
              <div className="manage-subscriber-table">
                <DataTable
                  uniqueKey="vendorContactId"
                  columns={selectedColumns}
                  query={query}
                  adminTable={vendorTable}
                  showView={true}
                  showEdit={true}
                  onSearch={onSearchHandler}
                  onSort={sortHandler}
                  checkboxesNotRequired={false}
                  paginationNotRequired={false}
                  serachBarNotRequired={false}
                  onCheckboxChange={onCheckboxChangeHandler}
                  onPageSizeChange={pageSizeChangeHandler}
                  handleChangePage={handleChangePageNumber}
                  onToggle={onToggleHandler}
                  showToggle={true}
                  totalPages={totalPages}
                  pageSize={pageSize}
                  onEdit={(data) => onEdit(data)}
                  onView={(data) => onView(data)}
                  permissions={vendorContactPermission}
                />

                {tableColumnModalVisibility && (
                  <TableColumnModal
                    isOpen={tableColumnModalVisibility}
                    onCancelTableColumnSelection={onCancelTableColumnSelection}
                    onSaveTableColumnSelection={onSaveTableColumnSelection}
                    selection={selectedColumns}
                  />
                )}
                <Confirmation
                  open={confirmation}
                  handleClose={handleConfirmationClose}
                  title={confirmationMessage}
                  cancelButtonLabel="No"
                  confirmButtonLabel="Yes"
                  onCancel={() => {
                    handleConfirmationClose();
                  }}
                  onConfirm={() => {
                    changeContentStatus(
                      selectedVendor,
                      "",
                      STATUS_ENUM.INACTIVE
                    );
                  }}
                />
              </div>
            </TabPanel>
            <TabPanel
              value={selectedVendorTab}
              index={TABS.INTEGRATION_SOLUTIONS}
              className="distributor-tab-panel-services"
            >
              <div style={{ display: "inline-block" }}>
                <Tabs
                  value={selectedSubTab}
                  onChange={(e, newValue) => {
                    setSelectedSubTab(newValue);
                  }}
                  aria-label="Distributor Tabs"
                  className="content-tabs"
                >
                  <Tab
                    label="Solution Details"
                    value={TABS.SOLUTION_DETAILS}
                    className="content-sub-tabs"
                    {...a11yProps("Solution Details", "SolutionDetails")}
                    disabled={false || formik.values.vendorId === 0}
                  />
                  <Tab
                    label="Services"
                    value={TABS.VENDOR_SERVICES}
                    className="content-sub-tabs"
                    {...a11yProps("VendorServices", "VendorServices")}
                    onClick={() => {
                      setIntegrationSolutionId(0);
                    }}
                    disabled
                  />
                </Tabs>
              </div>
              {selectedSubTab === TABS.SOLUTION_DETAILS && (
                <IntSolutionList
                  selectedSubTab={selectedSubTab}
                  setSelectedSubTab={setSelectedSubTab}
                  setIntegrationSolutionId={setIntegrationSolutionId}
                  vendorId={id}
                  setName={setName}
                  setDescription={setDescription}
                />
              )}
              {selectedSubTab === TABS.VENDOR_SERVICES && (
                <div className="manage-subscriber-table">
                  <IntServiceList
                    selectedSubTab={selectedSubTab}
                    vendorId={id}
                    integrationSolutionId={integrationSolutionId}
                    name={name}
                    description={description}
                    setName={setName}
                    setDescription={setDescription}
                  />
                </div>
              )}
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddEditVendor;

function formatUSZipCode(value) {
  value = value.replace(/\D/g, "");
  if (value.length > 5) {
    value = value.slice(0, 5) + "-" + value.slice(5, 9);
  }
  return value.slice(0, 10);
}

function formatCanadianPostalCode(value) {
  value = value.replace(/\s/g, "").toUpperCase();
  const validFirstLetter = /^[ABCEGHJKLMNPRSTVXY]/;
  if (!validFirstLetter.test(value.charAt(0))) {
    return "";
  }
  value = value.replace(/[^A-Z0-9]/g, "").slice(0, 6);
  if (value.length > 3) {
    value = value.slice(0, 3) + " " + value.slice(3);
  }
  return value;
}
