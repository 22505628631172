import React, { useState } from "react";
import { useParams } from "react-router";
import { Button, Container, Grid, Typography } from "@mui/material";
import { toast } from "react-toastify";
import PublicApiServices from "../../services/PublicApiServices";
import "./SignInWidget.css";
import {
  REUSABLE_LINK_ACTION,
  REUSABLE_LINK_FORM,
} from "../../constants/api-constants";
import { useLoading } from "../../hooks/useLoader";
import XFDLViewer from "../../common/XFDLViewer/XFDLViewer";
import { execeptionMessage } from "../../constants/common";
import "./Share.css";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import { useFormik } from "formik";
import { ReusableLinkSchema } from "../../common/ValidationSchema/ValidationSchema";
import InlineMessage from "../../common/InlineMessage/InlineMessage";

/*istanbul ignore next */
function ReusableLink() {
  const { id } = useParams();
  const { showLoader } = useLoading();
  const [linkValidated, setLinkValidated] = useState(false);
  const [linkValidationError, setLinkValidationError] = useState("");
  const [result, setResult] = useState(null);
  const [resultList, setResultList] = useState([]);
  const [docIndex, setDocIndex] = useState(null);
  const [xfdlCurrentJson, setXfdlCurrentJson] = useState(null);
  const [token] = useState(null);
  const [isInValid, setIsInValid] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
    },
    enableReinitialize: true,
    validationSchema: ReusableLinkSchema,
  });
  const [totalRemainingMandatoryFields, setTotalRemainingMandatoryFields] =
    useState(0);
  const validateLink = async () => {
    try {
      showLoader(true);
      setLinkValidationError("");
      const response = await PublicApiServices.httpGet(
        `${REUSABLE_LINK_FORM}/${id}`
      );
      if (response.responseStatus.isSuccess) {
        setLinkValidated(true);
        showLoader(false);
        if (response.result.contents && response.result.contents.length > 0) {
          let allContent = response.result.contents.sort((a, b) =>
            a.reusableLinkContentId > b.reusableLinkContentId ? 1 : -1
          );
          allContent.forEach((item) => {
            item.xfdlJson = JSON.parse(item.xfdlJson);
            item.formData = [];
          });
          handleContentLevelFirst(allContent);
          let currentContent = allContent[0];
          setDocIndex(currentContent.reusableLinkContentId);
          initializeFormDetails(currentContent);
        }
      } else {
        setLinkValidationError(response.responseStatus.error);
      }
    } catch (e) {
      setLinkValidationError(execeptionMessage);
      toast.error(execeptionMessage);
    } finally {
      showLoader(false);
    }
  };

  const saveOrSubmit = (isSubmit) => {
    apiHandler(isSubmit);
  };

  const apiHandler = async (isSubmit) => {
    try {
      showLoader(true);
      let payload = {
        isSubmit: isSubmit,
        name: formik.values.name,
        email: formik.values.email,
        phone: formik.values.phoneNumber,
        linkCode: `${id}`,
        contents: resultList.map((item) => {
          return {
            contentId: item.contentId,
            reusableLinkContentId: item.reusableLinkContentId,
            usersFormData: item.formData,
          };
        }),
        token: token,
      };
      const response = await PublicApiServices.httpPost(
        REUSABLE_LINK_ACTION,
        payload
      );
      if (response?.responseStatus?.isSuccess) {
        toast.success(response.responseStatus.description);
        window.location.reload();
      } else {
        toast.error(response.responseStatus.error);
      }
      showLoader(false);
      if (isSubmit) {
        setIsSubmitted(true);
        setIsInValid(true);
      }
    } catch (e) {
      showLoader(false);
    }
  };

  const changeHandler = (event, itemName, isRequired) => {
    let results = [...resultList];
    results.forEach((item) => {
      let value = item.formData?.filter((el) => el.key === itemName);
      if (value?.length > 0) {
        item.formData.forEach((item) => {
          if (item.key === itemName) {
            item.value = event.target.value;
          }
        });
      } else {
        item.formData = [
          ...item.formData,
          { key: itemName, value: event.target.value },
        ];
      }
    });
    setResultList(results);
    checkRequiredFields();
  };

  const checkBoxHandler = (event, itemName, isRequired) => {
    let results = [...resultList];
    results.forEach((item) => {
      let value = item.formData?.filter((el) => el.key === itemName);
      if (value?.length > 0) {
        item.formData.forEach((item) => {
          if (item.key === itemName) {
            item.value = event.target.checked ? "true" : "false";
          }
        });
      } else {
        item.formData = [
          ...item.formData,
          { key: itemName, value: event.target.checked ? "true" : "false" },
        ];
      }
    });

    setResultList(results);
    checkRequiredFields();
  };

  const checkRequiredFields = (resultListInput = null) => {
    let results = resultListInput ? [...resultListInput] : resultList;
    let invalid = results.length < 1;
    let totolManReqFieldCount = 0;
    results.forEach((item) => {
      if (item.xfdlJson?.Canvas?.length > 0) {
        item.requiredFeilds = checkIfRequiredFieldExist(
          item.xfdlJson,
          item.requiredFeilds
        );
      }
      item.MandatoryReqFieldCount = 0;
      for (let i = 0; i < item.requiredFeilds.length; i++) {
        const reqField = item.requiredFeilds[i];
        const matchingField = item.formData.find(
          (field) => field.key === reqField.key
        );
        if (
          !matchingField ||
          matchingField.value.trim() === "" ||
          matchingField.value === "false"
        ) {
          invalid = true; // Required field is empty
          item.MandatoryReqFieldCount++;
        }
      }
      totolManReqFieldCount += item.MandatoryReqFieldCount;
    });
    // All required fields have been checked values
    setTotalRemainingMandatoryFields(totolManReqFieldCount);
    setIsInValid(invalid);
  };

  useState(() => {
    validateLink();
  });

  // Function to check if a field with a given name exists in a canvas section
  const fieldExistsInSection = (section, requriredFieldsValue) => {
    let fields = [];
    for (const requiredField of requriredFieldsValue) {
      if (
        section?.TextBoxes.some((field) => field.Name === requiredField.key)
      ) {
        fields.push(requiredField);
      }
      if (
        section?.CheckBoxes.some((field) => field.Name === requiredField.key)
      ) {
        fields.push(requiredField);
      }
    }
    return fields;
  };

  const checkIfRequiredFieldExist = (xfdlJsonValue, requriredFieldsValue) => {
    let totalPages = xfdlJsonValue.TotalPages;
    let newRequiredFields = new Set();

    for (let i = 0; i <= totalPages; i++) {
      let newFieldValue = fieldExistsInSection(
        xfdlJsonValue.Canvas[i],
        requriredFieldsValue
      );
      if (newFieldValue?.length > 0) {
        for (const field of newFieldValue) {
          newRequiredFields.add(field);
        }
      }
    }
    const array = Array.from(newRequiredFields);
    return array;
  };

  return (
    <>
      {linkValidated ? (
        loadPage()
      ) : (
        <div className="myContainer-link">
          <Container maxWidth="md" className="main-grid-container">
            <Grid container justifyContent="center" className="invalid-links">
              <Grid item xs={7} md={7} className="login-form ">
                <div className="signup-container invalid-link">
                  <Grid item xs={12} m={2}>
                    {linkValidationError}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </>
  );

  function initializeFormDetails(formDetails) {
    setResult(formDetails);
    setXfdlCurrentJson(formDetails.xfdlJson);
  }

  function handleContentLevelFirst(results) {
    results.forEach((selectedShare) => {
      if (selectedShare.usersFormData?.length > 0) {
        const uniqueKeysMap = new Map();
        for (const item of selectedShare.usersFormData) {
          uniqueKeysMap.set(item.key, item.value);
        }
        const filteredFormData = selectedShare.formData.filter(
          (item) => !uniqueKeysMap.has(item.key)
        );
        const mergedFormData = filteredFormData.concat(
          selectedShare.usersFormData
        );
        selectedShare.formData = mergedFormData;
      }
    });
    setResultList(results);
    checkRequiredFields(results);
  }

  function loadPage() {
    return (
      <Container
        maxWidth="md"
        className="width-container-share"
        style={{ width: `${Number(xfdlCurrentJson?.FormViewSize) + 30}px` }}
      >
        {result.typeOfContent === 11 && result?.webLink ? (
          <Grid item xs={12} pt={2}>
            <Typography className="login-para" varient="h5" paragraph>
              Kindly click on the link below to open the document.
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12} pt={2} className="Grid-div-share">
            <div className="btns-share-xfdl-down">
              {xfdlCurrentJson && (
                <Button
                  disabled={isSubmitted}
                  variant="contained"
                  onClick={() => {
                    window.location.reload();
                  }}
                  className="button-mrgin-share"
                >
                  Reset
                </Button>
              )}
              {xfdlCurrentJson && (
                <Button
                  variant="contained"
                  onClick={() => {
                    saveOrSubmit(true);
                  }}
                  disabled={
                    !formik.values.name ||
                    !formik.values.email ||
                    !formik.values.phoneNumber ||
                    !formik.isValid ||
                    totalRemainingMandatoryFields > 0
                  }
                  className="button-mrgin-share"
                >
                  Submit
                </Button>
              )}
            </div>
          </Grid>
        )}
        <Grid item xs={12} m={2} style={{ margin: "50px 0px 10px 0px" }}>
          {xfdlCurrentJson && (
            <>
              {resultList && (
                <Grid
                  container
                  className="select-share-xfdl"
                  spacing={2}
                  // style={{ display: "flex", flexDirection: "column" }}
                >
                  <Grid
                    item
                    xs={6}
                    container
                    alignItems="center"
                    style={{ display: "flex" }}
                  >
                    <Typography
                      className="agency-name-align"
                      style={{ marginRight: "8px" }}
                    >
                      Name:
                      <MandatoryField />
                    </Typography>
                    <input
                      className="form-label form-control"
                      placeholder="Enter Name"
                      id="name"
                      name="name"
                      type="text"
                      maxLength={50}
                      autoComplete="off"
                      style={{
                        flex: 1,
                        width: "100%",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                      value={formik.values?.name}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                    />
                    <InlineMessage error={formik.errors?.name} />
                  </Grid>
                  <Grid item xs={6} container alignItems="center">
                    <Typography
                      className="agency-name-align"
                      style={{ marginRight: "8px" }}
                    >
                      Email:
                      <MandatoryField />
                    </Typography>
                    <input
                      id="email"
                      name="email"
                      className="form-label form-control"
                      type="email"
                      placeholder="Enter Email address"
                      autoComplete="off"
                      style={{
                        flex: 1,
                        width: "100%",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                      value={formik.values?.email}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                    />
                    <InlineMessage error={formik.errors?.email} />
                  </Grid>
                  <Grid item xs={6} container alignItems="center">
                    <Typography
                      className="agency-name-align"
                      style={{
                        marginRight: "8px",
                      }}
                    >
                      Phone:
                      <MandatoryField />
                    </Typography>
                    <span
                      style={{
                        padding: "7.5px 7.5px",
                        border: "1px solid #ccc",
                        borderRadius: "0px 0px 0 0px",
                        backgroundColor: "#f8f9fa",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      +1
                    </span>
                    <input
                      id="phoneNumber"
                      className="form-label form-control"
                      name="phoneNumber"
                      placeholder="Enter Phone Number"
                      maxLength={10}
                      style={{
                        flex: 1,
                        width: "100%",
                        border: "1px solid #ccc",
                        borderRadius: "0px",
                      }}
                      value={formik.values?.phoneNumber}
                      onChange={(e) => {
                        formik.setFieldValue("dirty", true);
                        formik.handleChange(e);
                      }}
                    />
                    <InlineMessage error={formik.errors?.phoneNumber} />
                  </Grid>
                  <Grid item xs={8}>
                    <label
                      htmlFor="sharedForm"
                      style={{
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      Total Mandatory fields left:{" "}
                      {totalRemainingMandatoryFields}
                    </label>
                  </Grid>
                  <Grid item xs={12} container>
                    <select
                      id="selectSharedForm"
                      name="selectSharedForm"
                      value={docIndex}
                      onChange={(e) => {
                        setDocIndex(Number(e.target.value));
                        initializeFormDetails(
                          resultList.find(
                            (x) =>
                              x.reusableLinkContentId === Number(e.target.value)
                          )
                        );
                      }}
                      className="form-control form-select"
                    >
                      {resultList.map((res) => (
                        <option
                          key={res.contentName}
                          value={res.reusableLinkContentId}
                        >
                          {res.contentName}
                        </option>
                      ))}
                    </select>
                  </Grid>
                </Grid>
              )}

              <Grid container>
                <Grid item xs={6}>
                  <label style={{ color: "red" }}>
                    Mandatory fields left:{" "}
                    {
                      resultList.find(
                        (x) => x.reusableLinkContentId === docIndex
                      )?.MandatoryReqFieldCount
                    }
                  </label>
                </Grid>
              </Grid>

              <XFDLViewer
                document={xfdlCurrentJson}
                fileName={result?.contentName}
                formData={
                  resultList.find((x) => x.reusableLinkContentId === docIndex)
                    ?.formData
                }
                requiredFields={
                  resultList.find((x) => x.reusableLinkContentId === docIndex)
                    ?.requiredFeilds
                }
                saveOrSubmit={saveOrSubmit}
                from="share"
                changeHandler={changeHandler}
                checkBoxHandler={checkBoxHandler}
                isValid={isInValid}
                isSubmitted={isSubmitted}
                checkRequiredFields={checkRequiredFields}
              />
            </>
          )}
        </Grid>
      </Container>
    );
  }
}

export default ReusableLink;
