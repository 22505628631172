import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tab,
  Tabs,
  Typography,
  Tooltip,
  Box,
  Popover,
  List,
  ListSubheader,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import MultiSelectDropDown from "../../../../common/MultiSelectDropDown/MultiSelectDropDown";
import { useFormik } from "formik";
import InlineMessage from "../../../../common/InlineMessage/InlineMessage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./AddEditCatalog.css";
import { a11yProps } from "../../../../common/TabPanel/a11yProps";
import TabPanel from "../../../../common/TabPanel/TabPanel";
import { CatalogSchema } from "../../../../common/ValidationSchema/CatalogSchema";
import {
  CATALOG,
  CATALOG_ASSOCIATED_CONTENT,
  DISTRIBUTOR_SUBSCROPTIONTYPES,
} from "../../../../constants/api-constants";
import ApiServices from "../../../../services/ApiServices";
import { toast } from "react-toastify";
import { getFrequency, handleStateChange } from "../../../../utilities/common";
import { MODE, TABS } from "../../../../constants/common";
import { useCatalogContext } from "../Catalog.context";
import { useHistory, useParams } from "react-router";
import { MandatoryField } from "../../../../common/MandatoryFieldsIcon/MandatoryField";
import TagsInput from "../../../../common/TagsInput/TagsInput";
import {
  contentTypeOptions,
  DocumentType,
  ContentPublishStatus,
  deleteSubscriptionConfirmationMessage,
  catalogCofirmationToPublish,
  subscriptionStatusFilters,
} from "../../constants";
import { formatDate } from "../../../../utilities/formatter";
import { useLoading } from "../../../../hooks/useLoader";
import { useMasterData } from "../../../../hooks/useMasterData";
import ListContent from "../ListContent/ListContent";
import { usePageMode } from "../../../../hooks/usePageMode";
import ManageSubscriber from "../../Content/ManageSubscriber/ManageSubscriber";
import Select from "@mui/material/Select";
import Confirmation from "../../../../common/Confirmation/Confirmation";
import CheckboxList from "../../../../common/CheckboxList/CheckboxList";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CurrencyTextbox from "../../../../common/CurrencyTextbox/CurrencyTextbox";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Group2252459 from "../../../../common/Modal/Distributor/Group2252459.png";
import Group2252519 from "../../../../common/Modal/Distributor/Group2252519.png";
import {
  getModuleId,
  PermissionEnum,
  PermissionService,
} from "../../../../constants/permissionService";
import { MenuListItems } from "../../../../common/Sidebar/MenuListItems";
import { SystemModulesEnum } from "../../../../constants/SystemUsers";

/*istanbul ignore next */
function AddEditCatalog(props) {
  const {
    id,
    catalogId,
    mode,
    onSave,
    onNext,
    setSelectedSubTab,
    onEdit,
    query,
    onView,
    anchorEl,
    setAnchorEl,
    signUpApproval,
    setContentId,
    setContentMode,
  } = props;
  const history = useHistory();
  const page = usePageMode();
  const catalogContext = useCatalogContext();
  const { subTabId } = useParams();
  const timerId = useRef(null);
  const [selectedTab, setSelectedTab] = useState(TABS.CATALOG_INFORMATION);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const { showLoader } = useLoading();
  const [contentList, setContentList] = useState([]);
  const [showEditAction, setShowEditAction] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openConfirmations, setOpenConfirmations] = useState(false);
  const [request, setRequest] = useState(null);
  const masterDataContext = useMasterData();
  const [distributorUserIdOfContent, setDistributorUserIdOfContent] =
    useState(0);

  const formik = useFormik({
    initialValues: {
      name: "",
      userId: id,
      lineOfBusinessList: [],
      countryId: 1,
      stateId: 1,
      latestVersion: "",
      marketingDescription: "",
      keywords: "",
      createdOn: null,
      updatedOn: null,
      createdByName: null,
      updatedByName: null,
      numberOfEnrolledUser: 0,
      egEnrolledUser: 0,
      isPublic: false,
      isPreview: false,
      isDisplayPublic: false,
      oneTimeCost: 0,
      subscriptions: [],
      actualValue: 0,
      frequency: 0,
      status: 0,
      isRecurring: false,
      isFavorite: false,
      publishStatus: 0,
      contentId: 0,
      documentType: DocumentType.CATALOG,
      contentType: 1,
      previewType: 0,
      content: {},
      states: [],
      reason: "",
    },
    enableReinitialize: true,
    validationSchema: CatalogSchema,
  });

  const [lobOptions, setLobOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [allStateOptions, setAllStateOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateNames, setStateNames] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showIndividual, setShowIndividual] = useState(true);
  const [activeButton, setActiveButton] = useState("individual");
  const [expandedFilter, setExpandedFilter] = useState("NA");
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedFreq, setSelectedFreq] = useState([]);
  const [setExpanded] = React.useState("panel1");
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [entSubsTypes, setEntSubsTypes] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [filterQuery, setFilterQuery] = useState({
    status: [1, 4],
    frequency: [99, 1, 2, 3, 4],
  });

  const frequencyOptions = [
    { value: 1, label: "Monthly" },
    { value: 2, label: "Quarterly" },
    { value: 3, label: "Semi-Annual" },
    { value: 4, label: "Annual" },
  ];

  const frequencyOptionsIndividual = [
    { value: 99, label: "One Time Cost" },
    { value: 1, label: "Monthly" },
    { value: 2, label: "Quarterly" },
    { value: 3, label: "Semi-Annual" },
    { value: 4, label: "Annual" },
  ];

  const FREQUENCYENUM = {
    // 99: "One Time Cost",
    1: "Monthly",
    2: "Quarterly",
    3: "Semi-Annual",
    4: "Annual",
  };

  const permissions = PermissionService(
    getModuleId(props.routeData?.userTypeId)
  );

  let moduleId = MenuListItems.find(
    (item) => item.name === SystemModulesEnum.CATALOG
  ).moduleId;
  const permissionss = PermissionService(moduleId);
  const isAllSelected =
    stateNames &&
    stateNames.length > 0 &&
    selected.length === stateNames.length - 1;

  useEffect(() => {
    loadDropdownOptions();
  }, []);

  useEffect(() => {
    loadDistSubscriptions();
  }, [distributorUserIdOfContent]);

  useEffect(() => {
    if (catalogId > 0) {
      getContentDetail();
      getContentList();
      if (history.location.pathname.includes("enterprisegroup")) {
        setShowEditAction(false);
      }
    } else {
      formik.resetForm();
      formik.setValues({
        ...formik.values,
        createdOn: new Date().toISOString(),
      });
      setSelectedKeywords([]);

      if (
        props.permissions?.includes("Update") &&
        !history.location.pathname.includes("enterprisegroup")
      ) {
        setShowEditAction(true);
      } else {
        setShowEditAction(false);
      }
    }
    formik.validateForm();

    return () => {
      //Clearing a timeout
      clearTimeout(timerId.current);
    };
  }, [catalogId, subTabId]);

  useEffect(() => {
    if (
      [
        TABS.CATALOG_INFORMATION,
        TABS.CONTENT_LIST,
        TABS.ENROLLED_USERS,
      ].includes(subTabId)
    ) {
      setSelectedSubTab(subTabId);
      setSelectedTab(subTabId || TABS.CONTENT_INFORMATION);
    }
  }, [subTabId]);

  useEffect(() => {
    if (catalogContext.copy) {
      formik.setValues({
        ...formik.values,
        publishStatus: 0,
        status: 0,
      });
    }
  }, [catalogContext.copy]);

  useEffect(() => {
    let isEditTrue =
      !history.location.pathname.includes("enterprisegroup") &&
      (mode === MODE.EDIT || mode === MODE.ADD);
    setIsEditMode(isEditTrue);
  }, [mode]);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        catalogContext.setCatalogMode(MODE.LIST);
        catalogContext.setSelectedTab(TABS.CATALOG_INFORMATION);
        history.push(`/distributor/${formik.values.userId}`);
      }
    };
  }, [history]);

  const handleIndividualClick = () => {
    setShowIndividual(true);
    if (
      !formik.values.subscriptions?.filter((x) => x.isIndividual)?.length > 0
    ) {
      addNewSubscription(true);
    }
  };

  const handleEnterpriseClick = () => {
    setShowIndividual(false);
    if (
      !formik.values.subscriptions?.filter((x) => !x.isIndividual)?.length > 0
    ) {
      addNewSubscription(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const clearFilterHandler = () => {
    setFilterQuery({
      status: [1, 4],
      frequency: [99, 1, 2, 3, 4],
    });
    setSelectedStatus([]);
    setSelectedFreq([]);
  };
  const applyFilterHandler = () => {
    setFilterQuery((prevState) => {
      return {
        ...prevState,
        status: selectedStatus.length > 0 ? selectedStatus : [1, 4],
        frequency: selectedFreq.length > 0 ? selectedFreq : [99, 1, 2, 3, 4],
      };
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const filterAccordianHandleChange = (panel) => (event, isExpanded) => {
    setExpandedFilter(isExpanded ? panel : "NA");
  };

  const onFilterStatusChangeHandler = (values) => {
    setSelectedStatus(values);
  };
  const onFilterFreqChangeHandler = (values) => {
    setSelectedFreq(values);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const deleteRow = (item) => {
    setOpenConfirmations(true);
    setSelectedItem(item.uid);
  };

  const loadDropdownOptions = async () => {
    showLoader(true);
    const response = masterDataContext.masterDataValue;
    showLoader(false);

    // LOB
    setLobOptions(
      response.result.lineOfBusiness.map((r) => {
        return {
          value: r.lineOfBusinessId,
          label: r.name,
        };
      })
    );
    // Countries
    setCountryOptions(response.result.countries);
    // States
    setAllStateOptions(response.result.states);
  };

  const handleSubscriptionChanges = (e, index, field) => {
    if (["frequency", "distributorSubscriptionId"].includes(field)) {
      formik.setFieldValue(
        `subscriptions[${index}].${field}`,
        Number(e.target.value)
      );
    } else {
      formik.setFieldValue(`subscriptions[${index}].${field}`, e.target.value);
    }
  };

  const handleAssociationChanges = async (e, index, field) => {
    let d = e?._d ? e._d : "";
    await formik.setFieldValue(
      `subscriptions[${index}].${field}`,
      d ? moment(d).format("YYYY-MM-DD") : null
    );
  };

  const totalEnrolledUsers =
    formik.values.numberOfEnrolledUser + formik.values.egEnrolledUser;

  const addNewSubscription = (isInd) => {
    const addSubscriptionInitialValues = {
      distributorSubscriptionId: 0,
      distributorSubscriptionPlanId: 0,
      contentSubscriptionId: 0,
      uid: Math.floor(Math.random() * 1000),
      oneTimeCost: 0,
      baseValue: "",
      frequency: 0,
      isRecurring: true,
      isIndividual: isInd,
      savedEffectiveToDate: null,
      isAssociated: false,
    };
    let newData = [
      ...formik.values.subscriptions,
      addSubscriptionInitialValues,
    ];
    formik.setFieldValue("subscriptions", newData);
  };
  const deleteSubscription = () => {
    const newList = formik.values.subscriptions.filter(
      (item) => item.uid !== selectedItem
    );
    if (!(newList?.length > 0)) {
      formik.setValues({
        ...formik.values,
        subscriptions: newList,
        contentType: 1,
      });
    } else {
      formik.setValues({
        ...formik.values,
        subscriptions: newList,
      });
    }
  };

  const getDateAlreadyExistsValidation = () => {
    let response = false;
    formik.values.subscriptions?.every((item) => {
      let subs =
        formik.values.subscriptions?.filter(
          (x) => x.isIndividual === item.isIndividual
        ) || [];
      let { minFromDate } = getMinFromAndToDate(item, subs);
      let {
        effFromDateRangeExists,
        effToDateRangeExists,
        isEffFromDateExeeds,
        isEffToDateExeeds,
      } = effDateRange(item, minFromDate, entSubsTypes);

      if (
        effFromDateRangeExists ||
        effToDateRangeExists ||
        isEffFromDateExeeds ||
        isEffToDateExeeds
      ) {
        response = true;
        return false;
      }
      return true;
    });
    return response;
  };

  const renderSubscriptionItem = (item, index, len, isIndi) => {
    let subs =
      formik.values.subscriptions?.filter(
        (x) => x.isIndividual === item.isIndividual
      ) || [];
    let { minFromDate, minToDate } = getMinFromAndToDate(item, subs);
    let { effFromDateRangeExists, effToDateRangeExists } = effDateRange(
      item,
      minFromDate
    );
    return filterSubs(item, isIndi, filterQuery) ? (
      <div
        className={`subscription${index} ${
          formik.values.subscriptions[index]?.status === 1
            ? "active-record"
            : ""
        }`}
      >
        <Grid
          container
          className={`blue-field-section${
            formik.values.subscriptions[index]?.status === 1
              ? "active-record"
              : ""
          }`}
        >
          <Grid item xs={1.8} p={2}>
            <label
              className="extra-padding-input montserrat-14-500 label-gap"
              htmlFor="baseValue"
            >
              Base Cost
            </label>
            <MandatoryField />
            <CurrencyTextbox
              id="baseValue"
              name="baseValue"
              value={formik.values.subscriptions[index]?.baseValue}
              onChange={(e) => {
                formik.setFieldValue(
                  `subscriptions[${index}].baseValue`,
                  Number(e) || null
                );
                page.setDirty(true);
              }}
              placeholder="Enter Base Value"
              disabled={!isEditMode || item.contentSubscriptionId > 0}
              data-testid="currency"
              type="number"
            />
            <InlineMessage error={baseValueValidationMessage(formik, index)} />
          </Grid>
          <Grid item xs={2} p={2}>
            <label
              className="extra-padding-input montserrat-14-500 label-gap"
              htmlFor="frequency"
            >
              Frequency
            </label>
            <MandatoryField />
            <select
              id="frequency"
              name="frequency"
              value={formik.values.subscriptions[index]?.frequency}
              onChange={(e) => {
                const selectedFrequency = Number(e.target.value);
                formik.setFieldValue("dirty", true);
                handleSubscriptionChanges(e, index, "frequency");
                const updatedSubscriptions = [...formik.values.subscriptions];
                updatedSubscriptions[index] = {
                  ...updatedSubscriptions[index],
                  frequency: selectedFrequency,
                  isRecurring: selectedFrequency !== 99,
                };
                formik.setValues({
                  ...formik.values,
                  subscriptions: updatedSubscriptions,
                });
              }}
              className="form-control form-select input-border"
              disabled={!isEditMode || item.contentSubscriptionId > 0}
            >
              <option disabled="true" value={0}>
                Select Frequency
              </option>
              {loadFilterOptions(frequencyOptionsIndividual, item, formik)}
            </select>
            {formik.errors?.subscriptions?.length > 0 &&
              index < formik.errors?.subscriptions?.length && (
                <InlineMessage
                  error={formik.errors?.subscriptions[index]?.frequency}
                />
              )}
          </Grid>
          <Grid item xs={1.6} p={2}>
            <label
              className="extra-padding-input montserrat-14-500 label-gap"
              htmlFor="oneTimeCost"
            >
              Actual Cost
            </label>
            <CurrencyTextbox
              id="actualValue"
              name="actualValue"
              className="form-control form-select input-border"
              value={
                (
                  formik.values.subscriptions[index]?.baseValue *
                  getFrequency(formik.values.subscriptions[index]?.frequency)
                )?.toFixed(2) || 0
              }
              disabled={true}
              type="text"
            />
          </Grid>
          <Grid item xs={1.8} p={2} mt={0.5}>
            <label htmlFor="membershipExpirationDate">
              Effective From Date
            </label>
            <MandatoryField />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disablePast
                minDate={new Date(Math.max(minFromDate, new Date()))}
                className="form-label form-control date-field"
                value={
                  formik.values.subscriptions[index]?.effectiveFromDate || null
                }
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  handleAssociationChanges(e, index, "effectiveFromDate");
                }}
                renderInput={(params) => (
                  <TextField
                    id="startDate"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM/DD/YYYY",
                    }}
                  />
                )}
                disabled={
                  !isEditMode ||
                  !item.frequency ||
                  item.contentSubscriptionId > 0
                }
              />
            </LocalizationProvider>
            {
              <InlineMessage
                error={effFDateValidationMessage(
                  formik,
                  index,
                  effFromDateRangeExists
                )}
              />
            }
          </Grid>
          <Grid item xs={1.8} p={2} mt={0.5}>
            <label htmlFor="membershipExpirationDate">Effective To Date</label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disablePast
                minDate={new Date(Math.max(minToDate, new Date()))}
                className="form-label form-control date-field "
                inputFormat="MM/DD/YYYY"
                value={
                  formik.values.subscriptions[index]?.effectiveToDate || null
                }
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  handleAssociationChanges(e, index, "effectiveToDate");
                }}
                renderInput={(params) => (
                  <TextField
                    id="startDate"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM/DD/YYYY",
                    }}
                  />
                )}
                disabled={disableEToDate(isEditMode, item, subs)}
              />
            </LocalizationProvider>
            {
              <InlineMessage
                error={EToDateValidationMessage(
                  formik,
                  index,
                  effToDateRangeExists
                )}
              />
            }
          </Grid>
          <Grid item xs={1.7} p={2} mt={1}>
            <FormControlLabel
              className="content-checkbox-field form-label form-control input-border cus-cus-fm"
              checked={formik.values.subscriptions[index]?.isRecurring}
              name="Display to Public"
              data-testid="recurring-type-checkbox"
              onChange={(e) => {
                const updatedSubscriptions = [...formik.values.subscriptions];
                updatedSubscriptions[index] = {
                  ...updatedSubscriptions[index],
                  isRecurring: e.target.checked,
                };
                formik.setValues({
                  ...formik.values,
                  subscriptions: updatedSubscriptions,
                });
                page.setDirty(true);
              }}
              control={
                <Checkbox
                  disabled={
                    !isEditMode ||
                    formik.values.subscriptions[index]?.frequency === 99 ||
                    item.contentSubscriptionId > 0
                  }
                />
              }
              label={<Typography>Auto Renewal</Typography>}
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={0.5} p={2} mt={4} className="add-association">
            {isEditMode && index === len && (
              <Tooltip placement="bottom" title="Add">
                <AddOutlinedIcon
                  name="add-icon"
                  title="add-icon"
                  style={{ marginTop: "-7px" }}
                  onClick={() => {
                    addNewSubscription(true);
                  }}
                />
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={0.5} p={2} mt={3.2}>
            {isEditMode && (
              <span>
                <Tooltip placement="bottom" title="Delete">
                  <IconButton
                    className="delete-icon-color"
                    onClick={() => deleteRow(item)}
                    aria-label="delete"
                    color="primary"
                    data-testid="btn-del"
                    disabled={item.isAssociated}
                  >
                    {item.isAssociated ? (
                      <img
                        src={Group2252519}
                        alt="Disabled Delete"
                        height={35}
                      />
                    ) : (
                      <img src={Group2252459} alt="Delete" height={35} />
                    )}
                  </IconButton>
                </Tooltip>
              </span>
            )}
          </Grid>
        </Grid>
      </div>
    ) : null;
  };
  const renderSubscriptionItemEnterprise = (item, index, len, isIndi) => {
    let subs =
      formik.values.subscriptions?.filter(
        (x) => x.isIndividual === item.isIndividual
      ) || [];
    let { minFromDate, minToDate } = getMinFromAndToDate(item, subs);
    let {
      effFromDateRangeExists,
      effToDateRangeExists,
      isEffFromDateExeeds,
      isEffToDateExeeds,
    } = effDateRange(item, minFromDate, entSubsTypes);
    return filterSubs(item, isIndi, filterQuery) ? (
      <div
        className={`subscription${index} ${
          formik.values.subscriptions[index]?.status === 1
            ? "active-record"
            : ""
        }`}
      >
        <Grid
          container
          className={`blue-field-section${
            formik.values.subscriptions[index]?.status === 1
              ? "active-record"
              : ""
          }`}
        >
          <Grid item xs={2} p={2}>
            <label
              className="extra-padding-input-2 montserrat-14-500 label-gap"
              htmlFor="subscriptions"
            >
              Select Subscriptions
            </label>
            <select
              name="subscriptions"
              id="subscriptions"
              className="form-label form-control input-border"
              disabled={!isEditMode || item.contentSubscriptionId > 0}
              value={
                formik.values.subscriptions[index]?.distributorSubscriptionId
              }
              onChange={(e) => {
                handleSubscriptionChanges(
                  e,
                  index,
                  "distributorSubscriptionId"
                );
                formik.setFieldValue(
                  `subscriptions[${index}].${"frequency"}`,
                  0
                );
                formik.setFieldValue(
                  `subscriptions[${index}].${"baseValue"}`,
                  0
                );
                formik.setFieldValue(
                  `subscriptions[${index}].${"effectiveToDate"}`,
                  null
                );
                page.setDirty(true);
                formik.setFieldValue("dirty", true);
              }}
            >
              <option disabled="true" value={0}>
                Select Subscriptions
              </option>
              {subscriptionOptions?.map((res) => {
                return (
                  <option key={res.value} value={res.value}>
                    {res.label}
                  </option>
                );
              })}
            </select>
          </Grid>

          <Grid item xs={2} p={2}>
            <label
              className="extra-padding-input montserrat-14-500 label-gap"
              htmlFor="frequency"
            >
              Frequency
            </label>
            <span
              style={{
                color: "red",
                marginLeft: "3px",
                marginTop: "2px",
              }}
            >
              *
            </span>
            <select
              id="frequency"
              name="frequency"
              value={formik.values.subscriptions[index]?.frequency}
              onChange={(e) => {
                formik.setFieldValue("dirty", true);
                handleSubscriptionChanges(e, index, "frequency");
                let selectedFreq = entSubsTypes
                  .find(
                    (x) =>
                      x.distributorSubscriptionId ==
                      formik.values.subscriptions[index]
                        ?.distributorSubscriptionId
                  )
                  ?.subscriptionPlan.find((x) => x.frequency == e.target.value);
                formik.setFieldValue(
                  `subscriptions[${index}].${"baseValue"}`,
                  selectedFreq?.baseValue
                );

                formik.setFieldValue(
                  `subscriptions[${index}].${"effectiveToDate"}`,
                  selectedFreq?.effectiveToDate
                    ? moment(
                        new Date(selectedFreq.effectiveToDate).setHours(
                          0,
                          0,
                          0,
                          0
                        )
                      ).format("YYYY-MM-DD")
                    : null
                );
                formik.setFieldValue(
                  `subscriptions[${index}].${"distributorSubscriptionPlanId"}`,
                  selectedFreq?.distributorSubscriptionPlanId
                );
              }}
              className="form-control form-select input-border"
              disabled={
                !isEditMode ||
                item.contentSubscriptionId > 0 ||
                !item.distributorSubscriptionId
              }
            >
              <option disabled="true" value={0}>
                Select Frequency
              </option>
              {renderEntFreq(entSubsTypes, item, formik, FREQUENCYENUM)}
            </select>
            {formik.errors?.subscriptions?.length > 0 &&
              index < formik.errors?.subscriptions?.length && (
                <InlineMessage
                  error={formik.errors?.subscriptions[index]?.frequency}
                />
              )}
          </Grid>
          <Grid item xs={2} p={2}>
            <label
              className="extra-padding-input montserrat-14-500 label-gap"
              htmlFor="oneTimeCost"
            >
              Base Cost
            </label>
            <CurrencyTextbox
              id="actualValue"
              name="actualValue"
              className="form-control form-select input-border"
              value={formik.values.subscriptions[index]?.baseValue || ""}
              disabled={true}
              type="text"
            />
          </Grid>
          <Grid item xs={2} p={2} mt={0.5}>
            <label htmlFor="membershipExpirationDate">
              Effective From Date
            </label>
            <span
              style={{
                color: "red",
                marginLeft: "3px",
                marginTop: "2px",
              }}
            >
              *
            </span>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disablePast
                maxDate={getMaxEToDateEG(entSubsTypes, item)}
                minDate={new Date(Math.max(minFromDate, new Date()))}
                className="form-label form-control date-field "
                inputFormat="MM/DD/YYYY"
                value={
                  formik.values.subscriptions[index]?.effectiveFromDate || null
                }
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  handleAssociationChanges(e, index, "effectiveFromDate");
                }}
                renderInput={(params) => (
                  <TextField
                    id="startDate"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM/DD/YYYY",
                    }}
                  />
                )}
                disabled={
                  !isEditMode ||
                  !item.frequency > 0 ||
                  item.contentSubscriptionId > 0
                }
              />
            </LocalizationProvider>
            {
              <InlineMessage
                error={fromDateValidationMessage(
                  formik,
                  index,
                  effFromDateRangeExists,
                  isEffFromDateExeeds
                )}
              />
            }
          </Grid>
          <Grid item xs={2} p={2} mt={0.5}>
            <label htmlFor="membershipExpirationDate">Effective To Date</label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disablePast
                maxDate={getMaxEToDateEG(entSubsTypes, item)}
                minDate={new Date(Math.max(minToDate, new Date()))}
                className="form-label form-control date-field "
                inputFormat="MM/DD/YYYY"
                value={
                  formik.values.subscriptions[index]?.effectiveToDate || null
                }
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  handleAssociationChanges(e, index, "effectiveToDate");
                }}
                renderInput={(params) => (
                  <TextField
                    id="startDate"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM/DD/YYYY",
                    }}
                  />
                )}
                disabled={disableEToDate(isEditMode, item, subs)}
              />
            </LocalizationProvider>
            {
              <InlineMessage
                error={getErrorMessage(
                  index,
                  effToDateRangeExists,
                  isEffToDateExeeds
                )}
              />
            }
          </Grid>
          <Grid item xs={0.5} p={2} mt={4} className="add-association">
            {isEditMode && index === len && (
              <Tooltip placement="bottom" title="Add">
                <AddOutlinedIcon
                  name="add-icon"
                  title="add-icon"
                  style={{ marginTop: "-7px" }}
                  onClick={() => {
                    addNewSubscription(false);
                  }}
                />
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={0.5} p={2} mt={3.2}>
            {isEditMode && (
              <span>
                <Tooltip placement="bottom" title="Delete">
                  <IconButton
                    className="delete-icon-color"
                    onClick={() => deleteRow(item)}
                    aria-label="delete"
                    color="primary"
                    data-testid="btn-del"
                    disabled={item.isAssociated}
                  >
                    {item.isAssociated ? (
                      <img
                        src={Group2252519}
                        alt="Disabled Delete"
                        height={35}
                      />
                    ) : (
                      <img src={Group2252459} alt="Delete" height={35} />
                    )}
                  </IconButton>
                </Tooltip>
              </span>
            )}
          </Grid>
        </Grid>
      </div>
    ) : null;
  };

  function getErrorMessage(index, effToDateRangeExists, isEffToDateExeeds) {
    return (
      (formik.errors?.subscriptions?.length > 0 &&
        index < formik.errors?.subscriptions?.length &&
        formik.errors?.subscriptions[index]?.effectiveToDate) ||
      (effToDateRangeExists &&
        "Date already exists for this frequency. Please enter valid date") ||
      (isEffToDateExeeds &&
        "Date must be less than or equal to the subscription's Effective To date.")
    );
  }

  /*istanbul ignore next */
  const handleChangeDropDown = (event) => {
    const selectedStates = handleStateChange(
      event,
      stateNames,
      selected,
      setSelected,
      formik.values.states
    );
    formik.setFieldValue("states", selectedStates);
  };

  const getContentList = async () => {
    try {
      showLoader(true);
      const adminData = await ApiServices.httpGet(
        `${CATALOG_ASSOCIATED_CONTENT}/${id}/${catalogId}/${JSON.stringify(
          query
        )}`
      );
      showLoader(false);
      setContentList(adminData?.result?.records);
      props.setSelectionColumns();
    } catch (e) {
      showLoader(false);
    }
  };
  /*istanbul ignore next */
  const handleHandleChange = (event, newValue) => {
    history.push(
      `${history.location.pathname.substring(
        0,
        history.location.pathname.indexOf("catalog")
      )}${TABS.CATALOG}/${newValue}`
    );
  };
  /*istanbul ignore next */
  const getContentDetail = async () => {
    try {
      const cId = catalogId;
      showLoader(true);
      const response = await ApiServices.httpGet(`${CATALOG}/${cId}`);
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        timerId.current = setTimeout(() => {
          page.setDirty(false);
        }, 100);

        let savedStates = response.result.states?.map((el) => el.name);
        setSelected(savedStates?.length ? savedStates : ["Not Applicable"]);
        formik.setValues(fillFormikDetails(response, savedStates));
        setSelectedKeywords(
          response.result.contentKeywordList.map((item) => item.keyword)
        );
        setDistributorUserIdOfContent(response.result.userId);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      showLoader(false);
      console.log("C");
      toast.error("Internal server error");
    }
  };

  const populateStateOptions = (countryId) => {
    const result = allStateOptions?.filter(
      (res) => res.countryId === Number(countryId)
    );
    setStateOptions(result);
    let stateNameOptions = result && result.map((item) => item.name);
    stateNameOptions = [...stateNameOptions, "Not Applicable"];
    setStateNames(stateNameOptions);
  };

  useEffect(() => {
    populateStateOptions(formik.values.countryId);
  }, [formik.values.countryId, allStateOptions]);
  /*istanbul ignore next */
  const createContent = async (event, actionName) => {
    try {
      showLoader(true);
      let payload = { ...formik.values };
      if (catalogId > 0) {
        payload.contentId = catalogId;
      } else if (catalogContext.copy) {
        payload.contentId = catalogContext.sourceCatalogId;
      }
      let selectedStates = stateOptions?.filter((el) =>
        formik.values.states?.includes(el.name)
      );
      payload.states = selectedStates.includes("Not Applicable")
        ? []
        : selectedStates;

      payload.lineOfBusinessList = formik.values.lineOfBusinessList.map(
        (item) => ({
          lineOfBusinessId: item.value,
        })
      );
      let subs = formik.values.subscriptions;
      subs.forEach((record) => {
        if (!record.isIndividual && (record.effectiveToDate ? false : true)) {
          record.effectiveToDate =
            getMaxEToDateEG(entSubsTypes, record) || null;
        }
      });
      payload.subscriptions = subs;
      payload.frequency = parseInt(payload.frequency);

      // republish and publish scenarios starts here
      let status;
      const publishStatus = formik.values.publishStatus;
      status = statusOnAction(actionName, publishStatus, status);
      payload.publishStatus = status;
      payload.publishContentId = formik.values.publishContentId || 0;
      payload.isEditAction = actionName === "Publish" ? false : true;
      // republish and publish scenarios ends here

      if (contentList) {
        let savedContentIds = contentList.map((el) => el.contentId);
        payload.content = {
          contentId: catalogId,
          userId: id,
          associateContentIds: [...savedContentIds],
        };
      }
      payload.countryId = Number(formik.values.countryId);
      payload.reason = formik.values.reason;
      payload.previewType = Number(formik.values.previewType);
      payload.contentType = Number(formik.values.contentType);
      page.setDirty(false);

      await requestOnAction(actionName, payload);

      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };
  /*istanbul ignore next */
  const makeRequest = async (payload, actionName) => {
    const response =
      catalogId === 0 || catalogContext.copy
        ? await ApiServices.httpPost(CATALOG, payload)
        : await ApiServices.httpPut(CATALOG, payload);
    if (response.responseStatus?.isSuccess) {
      catalogContext.resetCopyCatalog();
      toast.success(response.responseStatus.description);
      if (actionName === "Next") {
        onNext(
          catalogId > 0 ? "Update" : "Save",
          response.result.contentId,
          "catalog"
        );
      } else {
        onSave("catalog", response.result);
      }
    } else {
      toast.error(response.responseStatus.error);
    }
  };

  const handleCancelClick = () => {
    catalogContext.setSelectedTab(TABS.CATALOG);
    catalogContext.setCatalogMode(MODE.LIST);

    history.push(
      `${history.location.pathname.substring(
        0,
        history.location.pathname.indexOf("catalog")
      )}${TABS.CATALOG}`
    );
  };

  return (
    <div className="welcome">
      <div className="addedit-content-main">
        <div className="sticky-top-element" style={{ background: "#f4f4f4" }}>
          <div style={{ display: "inline-block" }}>
            <Tabs
              value={selectedTab}
              onChange={handleHandleChange}
              aria-label="Distributor Tabs"
              className="content-tabs"
            >
              <Tab
                label="Catalog Information"
                value={TABS.CATALOG_INFORMATION}
                className="content-sub-tabs"
                {...a11yProps("Catalog Information", "CatalogInformation")}
              />
              <Tab
                label="List of Content"
                value={TABS.CONTENT_LIST}
                className="content-sub-tabs"
                {...a11yProps("List of Content", "ContentList")}
                disabled={!catalogId > 0 || catalogContext.copy}
              />
              <Tab
                label="Manage Subscribers"
                value={TABS.ENROLLED_USERS}
                className="content-sub-tabs"
                {...a11yProps("Manage Subscribers", "ManageSubscribers")}
                disabled={!catalogId > 0 || catalogContext.copy}
              />
            </Tabs>
          </div>
          <div
            style={{
              display: "inline-block",
              paddingTop: "2px",
              float: "right",
            }}
          >
            {selectedTab === TABS.CATALOG_INFORMATION && (
              <div>
                {mode === MODE.VIEW &&
                  showEditAction &&
                  ((permissions &&
                    permissions.includes(PermissionEnum.CREATE)) ||
                    (permissions &&
                      permissions.includes(PermissionEnum.UPDATE))) && (
                    <Button
                      variant="contained"
                      type="submit"
                      className="action-button mr-4"
                      onClick={() => {
                        onEdit(formik.values, formik.values.documentType);
                      }}
                      disabled={
                        formik.values.publishStatus ===
                          ContentPublishStatus.MODIFIED &&
                        formik.values.status === 0
                      }
                    >
                      Edit
                    </Button>
                  )}
                {isEditMode && (
                  <Button
                    variant="contained"
                    type="submit"
                    className="action-button mr-4"
                    data-testid="copyBleIcon"
                    onClick={(e) => {
                      createContent(e, "Exit");
                    }}
                    disabled={
                      !formik.isValid ||
                      !isEditMode ||
                      getDateAlreadyExistsValidation()
                    }
                  >
                    {catalogId === 0 || catalogContext.copy ? "Save" : "Update"}{" "}
                    & Exit
                  </Button>
                )}
                {isEditMode && (
                  <Button
                    variant="contained"
                    type="submit"
                    className="action-button mr-4"
                    onClick={(e) => {
                      createContent(e, "Next");
                    }}
                    disabled={
                      !formik.isValid ||
                      !isEditMode ||
                      getDateAlreadyExistsValidation()
                    }
                  >
                    {catalogId === 0 || catalogContext.copy ? "Save" : "Update"}{" "}
                    & Next
                  </Button>
                )}
                {isEditMode && (
                  <Button
                    data-testid="publish-btn"
                    variant="contained"
                    type="submit"
                    className="action-button mr-4"
                    disabled={
                      (signUpApproval?.approvalId > 0 &&
                        signUpApproval?.status !== 1) ||
                      !contentList?.length ||
                      !formik.isValid ||
                      !isEditMode ||
                      getDateAlreadyExistsValidation()
                    }
                    onClick={(e) => {
                      createContent(e, "Publish");
                    }}
                  >
                    Publish
                  </Button>
                )}
                <Button
                  data-testid="cancel-action-catalog"
                  variant="outlined"
                  className="action-button"
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
        <Confirmation
          open={openConfirmation}
          description={catalogCofirmationToPublish}
          onCancel={() => {
            setOpenConfirmation(false);
          }}
          onConfirm={async () => {
            await makeRequest(request.payload, request.actionName);
            setOpenConfirmation(false);
          }}
          cancelButtonLabel="Cancel"
          confirmButtonLabel="OK"
          customDescriptionStyle={{
            fontSize: "18px",
            fontWeight: "700",
            marginTop: "0",
          }}
        />
        <Confirmation
          open={openConfirmations}
          title={deleteSubscriptionConfirmationMessage}
          cancelButtonLabel="No"
          confirmButtonLabel="Yes"
          onConfirm={() => {
            deleteSubscription();
            setOpenConfirmations(false);
          }}
          onCancel={() => {
            setOpenConfirmations(false);
          }}
        />
        <TabPanel value={selectedTab} index={TABS.CATALOG_INFORMATION}>
          <Grid item xs={12}>
            <div className="welcome-main">
              <div className="welcome-message">
                <Typography className="montserrat-16-600">
                  Basic Information
                </Typography>
              </div>
            </div>
          </Grid>
          <div
            className="menu-bar"
            style={{
              display: "flex",
              height: "100%",
              paddingTop: "1rem",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3} p={2}>
                <label htmlFor="name">Catalog Name</label>
                <MandatoryField />
                <input
                  id="name"
                  name="name"
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  value={formik.values.name}
                  className="form-label form-control"
                  type="text"
                  placeholder="Enter Catalog Name"
                  autocomplete="off"
                  maxLength={150}
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                />
                <InlineMessage error={formik.errors.name} />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="lineOfBusinessList">LOB</label>
                <MultiSelectDropDown
                  name="lineOfBusinessList"
                  id="lineOfBusinessList"
                  value={formik.values.lineOfBusinessList}
                  onChange={(value) => {
                    formik.setValues({
                      ...formik.values,
                      lineOfBusinessList: value,
                    });
                    page.setDirty(true);
                  }}
                  options={lobOptions}
                  disabled={!isEditMode}
                  placeholder="Select LOB"
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="countryId">Country</label>
                <select
                  id="countryId"
                  name="countryId"
                  data-testid="country-dropdown"
                  value={formik.values?.countryId}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      dirty: true,
                      stateId: null,
                      countryId: Number(e.target.value),
                    });
                    setSelected([]);
                  }}
                  className="form-control form-select"
                  disabled={!isEditMode}
                >
                  {countryOptions?.map((res) => {
                    return (
                      <option key={res.countryId} value={res.countryId}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="stateId">State/Province</label>
                <Select
                  displayEmpty
                  data-testid="states-dropdown"
                  id="stateId"
                  name="stateId"
                  labelId="mutiple-select-label"
                  className="form-select form-control select-user roles-dropdown"
                  multiple
                  onChange={(e) => {
                    handleChangeDropDown(e);
                    page.setDirty(true);
                  }}
                  value={selected}
                  disabled={!isEditMode}
                  renderValue={(selectedItem) => {
                    if (selected.length === 0) {
                      return "Select State";
                    }
                    return selectedItem.join(", ");
                  }}
                >
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < stateNames.length - 1
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={isAllSelected ? "Unselect All" : "Select All"}
                    />
                  </MenuItem>
                  {stateNames.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          disabled={formik.values.states?.includes(
                            "Not Applicable"
                          )}
                          checked={selected.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="latestVersion">Edition Date</label>
                <MandatoryField />
                <input
                  id="latestVersion"
                  name="latestVersion"
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  value={formik.values.latestVersion}
                  className="form-label form-control"
                  type="text"
                  placeholder="Enter Edition Date"
                  autocomplete="off"
                  maxLength={25}
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                />
                <InlineMessage error={formik.errors.latestVersion} />
              </Grid>
              {formik.values.reason && (
                <Grid item xs={3} p={2}>
                  <label htmlFor="reason">Reason for Deactivation</label>
                  <input
                    id="reason"
                    name="reason"
                    value={formik.values.reason}
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter reason"
                    autocomplete="off"
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={3} p={2}>
                <label htmlFor="createdDate">Created Date</label>
                <input
                  id="createdDate"
                  name="createdDate"
                  value={formatDate(formik.values.createdOn)}
                  className="form-label form-control"
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="createdBy">Created By</label>
                <input
                  id="createdBy"
                  name="createdBy"
                  value={formik.values.createdByName}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="lastUpdatedDate">Last Updated Date</label>
                <input
                  id="updatedDate"
                  name="updatedDate"
                  value={formatDate(formik.values.updatedOn)}
                  className="form-label form-control"
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="lastUpdatedBy">Last Updated By</label>
                <input
                  id="updatedBy"
                  name="updatedBy"
                  value={formik.values.updatedByName}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  disabled
                />
              </Grid>

              <Grid
                item
                xs={3}
                p={2}
                className="tag-input tag-input-search-keyword"
              >
                <label htmlFor="keywords">Search Keywords</label>
                <TagsInput
                  id="keywords"
                  name="keywords"
                  value={selectedKeywords}
                  onChange={(value) => {
                    formik.setValues({
                      ...formik.values,
                      keywords: value.join(),
                    });
                    setSelectedKeywords(value);
                    page.setDirty(true);
                  }}
                  placeHolder="Enter keyword(s) here. Use a comma or the enter key to add each additional keyword(s)."
                  className={!isEditMode && "disabled"}
                  disabled={!isEditMode}
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="marketingDescription">
                  Marketing Description
                </label>
                <div className="textarea-container">
                  <textarea
                    id="marketingDescription"
                    name="marketingDescription"
                    onChange={(e) => {
                      formik.handleChange(e);
                      page.setDirty(true);
                    }}
                    value={formik.values.marketingDescription}
                    className="form-label form-control bs-cus scrollable-input-content"
                    type="text"
                    placeholder="Enter Marketing Description"
                    autoComplete="off"
                    disabled={!isEditMode}
                  />
                </div>
                <InlineMessage error={formik.errors.marketingDescription} />
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} p={2}>
                {showEditAction && (
                  <Accordion>
                    <AccordionSummary
                      className="accordion-content-account-information"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="montserrat-16-600">
                        Subscription Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div
                          className="menu-bar"
                          style={{
                            display: "flex",
                            height: "100%",
                            marginLeft: "0",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={3} p={2} mt={1}>
                              <label
                                className="extra-padding-input montserrat-14-500 label-gap"
                                htmlFor="totalEnrolledUsers"
                              >
                                Total Enrolled Users
                              </label>
                              <input
                                id="totalEnrolledUsers"
                                name="totalEnrolledUsers"
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  page.setDirty(true);
                                }}
                                value={totalEnrolledUsers}
                                className="form-label form-control input-border"
                                type="text"
                                placeholder=""
                                maxLength={25}
                                disabled
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              p={2}
                              mt={-1}
                              className="cus-cus-grid"
                            >
                              <FormControlLabel
                                className="content-checkbox-field form-label form-control input-border cus-cus-fm"
                                label={
                                  <Typography className="montserrat-14-500">
                                    Available to Public
                                  </Typography>
                                }
                                checked={formik.values.isPublic}
                                name="Available Public"
                                data-testid="available-public-checkbox"
                                onChange={(e) => {
                                  formik.setValues({
                                    ...formik.values,
                                    isPublic: e.target.checked,
                                  });
                                  page.setDirty(true);
                                }}
                                control={<Checkbox disabled={!isEditMode} />}
                                labelPlacement="end"
                              />
                            </Grid>
                            <Grid item xs={3} p={2} mt={1}>
                              <label
                                className="extra-padding-input montserrat-14-500 label-gap"
                                htmlFor="catalogType"
                              >
                                Catalog Type(Free/Paid)
                              </label>
                              <select
                                id="contentType"
                                name="contentType"
                                value={formik.values.contentType || 1}
                                onChange={(e) => {
                                  if (e.target.value === "2") {
                                    handleIndividualClick();
                                  }
                                  if (e.target.value === "1") {
                                    formik.setFieldValue(`subscriptions`, []);
                                  }
                                  page.setDirty(true);
                                  formik.handleChange(e);
                                }}
                                className="form-control form-select input-border"
                                disabled={!isEditMode}
                              >
                                {contentTypeOptions?.map((res) => {
                                  return (
                                    <option key={res.value} value={res.value}>
                                      {res.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </Grid>
                            <Grid item xs={3} p={2} mt={1}></Grid>
                            {Number(formik.values.contentType) === 2 && (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  p={2}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Button
                                    onClick={() => {
                                      handleIndividualClick();
                                      setActiveButton("individual");
                                    }}
                                    className={
                                      activeButton === "individual"
                                        ? "active-button"
                                        : "dis-active-button"
                                    }
                                  >
                                    <Typography
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Individual
                                    </Typography>
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      handleEnterpriseClick();
                                      setActiveButton("enterprise");
                                    }}
                                    className={
                                      activeButton === "enterprise"
                                        ? "active-button"
                                        : "dis-active-button"
                                    }
                                  >
                                    <Typography
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Enterprise
                                    </Typography>
                                  </Button>
                                </Grid>
                                <Grid item xs={12}>
                                  <hr
                                    style={{
                                      width: "100%",
                                      border: "0.5px solid #e9ecef",
                                      marginTop: "-32px",
                                      marginBottom: "0px",
                                    }}
                                  />
                                </Grid>
                                {showIndividual && (
                                  <Grid
                                    item
                                    xs={3}
                                    p={2}
                                    mt={1}
                                    style={{ marginTop: "-36px" }}
                                  >
                                    <label
                                      className="extra-padding-input montserrat-14-500 label-gap"
                                      htmlFor="numberOfEnrolledUsers"
                                    >
                                      Enrolled Users
                                    </label>
                                    <input
                                      id="numberOfEnrolledUsers"
                                      name="numberOfEnrolledUsers"
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                        page.setDirty(true);
                                      }}
                                      value={formik.values.numberOfEnrolledUser}
                                      className="form-label form-control input-border"
                                      type="text"
                                      placeholder="Enter Last Name"
                                      maxLength={25}
                                      disabled
                                    />
                                  </Grid>
                                )}

                                {showIndividual && (
                                  <Grid item xs={0} p={2} mt={1}></Grid>
                                )}
                                {showIndividual && (
                                  <>
                                    <Grid item xs={8} p={2} mt={1}>
                                      <Box>
                                        <div className=" filter-icon-subs">
                                          <div>
                                            <div className="welcome-widgets">
                                              <Popover
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "right",
                                                }}
                                                id={"simple-popover"}
                                                open={Boolean(anchorEl)}
                                                anchorEl={anchorEl}
                                                onClose={() => {
                                                  handleClose();
                                                }}
                                              >
                                                <div className="expanded-filter">
                                                  <div className="filter-options">
                                                    <List
                                                      sx={{
                                                        width: "100%",
                                                        maxWidth: 360,
                                                        bgcolor:
                                                          "background.paper",
                                                      }}
                                                      component="nav"
                                                      aria-labelledby="nested-list-subheader"
                                                      subheader={
                                                        <ListSubheader
                                                          component="div"
                                                          id="nested-list-subheader"
                                                        >
                                                          Filters will be
                                                          applied to your grid
                                                        </ListSubheader>
                                                      }
                                                    >
                                                      <Accordion
                                                        expanded={
                                                          expandedFilter ===
                                                          "status"
                                                        }
                                                        onChange={filterAccordianHandleChange(
                                                          "status"
                                                        )}
                                                      >
                                                        <AccordionSummary
                                                          expandIcon={
                                                            <ExpandMoreIcon />
                                                          }
                                                          onChange={handleChange(
                                                            "panel1"
                                                          )}
                                                          aria-controls="panel1bh-content"
                                                          id="panel1bh-header"
                                                        >
                                                          <Typography
                                                            sx={{
                                                              flexShrink: 0,
                                                            }}
                                                          >
                                                            Status
                                                          </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                          <CheckboxList
                                                            id="status"
                                                            options={
                                                              subscriptionStatusFilters
                                                            }
                                                            onChange={
                                                              onFilterStatusChangeHandler
                                                            }
                                                            selectedValues={
                                                              selectedStatus
                                                            }
                                                          />
                                                        </AccordionDetails>
                                                      </Accordion>
                                                      <Accordion
                                                        expanded={
                                                          expandedFilter ===
                                                          "frequency"
                                                        }
                                                        onChange={filterAccordianHandleChange(
                                                          "frequency"
                                                        )}
                                                      >
                                                        <AccordionSummary
                                                          expandIcon={
                                                            <ExpandMoreIcon />
                                                          }
                                                          aria-controls="panel1bh-content"
                                                          id="panel1bh-header"
                                                        >
                                                          <Typography
                                                            sx={{
                                                              flexShrink: 0,
                                                            }}
                                                          >
                                                            Frequency
                                                          </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                          <CheckboxList
                                                            id="frequency"
                                                            options={
                                                              frequencyOptionsIndividual
                                                            }
                                                            onChange={
                                                              onFilterFreqChangeHandler
                                                            }
                                                            selectedValues={
                                                              selectedFreq
                                                            }
                                                          />
                                                        </AccordionDetails>
                                                      </Accordion>
                                                    </List>
                                                  </div>
                                                  <div className="modal-footer">
                                                    <Button
                                                      variant="outlined"
                                                      className="action-button mr-4"
                                                      onClick={
                                                        clearFilterHandler
                                                      }
                                                    >
                                                      Clear
                                                    </Button>
                                                    <Button
                                                      variant="contained"
                                                      className="action-button"
                                                      onClick={() => {
                                                        applyFilterHandler();
                                                        handleClose();
                                                      }}
                                                    >
                                                      Apply
                                                    </Button>
                                                  </div>
                                                </div>
                                              </Popover>

                                              <Tooltip
                                                placement="bottom"
                                                title="Filter"
                                              >
                                                <FilterAltOutlinedIcon
                                                  onClick={handleClick}
                                                  title="filter-icon"
                                                />
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </div>
                                      </Box>
                                    </Grid>
                                  </>
                                )}

                                {!showIndividual && (
                                  <Grid
                                    item
                                    xs={3}
                                    p={2}
                                    mt={1}
                                    style={{ marginTop: "-36px" }}
                                  >
                                    <label
                                      className="extra-padding-input montserrat-14-500 label-gap"
                                      htmlFor="egEnrolledUser"
                                    >
                                      Enrolled Users
                                    </label>
                                    <input
                                      id="egEnrolledUser"
                                      name="egEnrolledUser"
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                        page.setDirty(true);
                                      }}
                                      value={formik.values.egEnrolledUser}
                                      className="form-label form-control input-border"
                                      type="text"
                                      placeholder=""
                                      maxLength={25}
                                      disabled
                                    />
                                  </Grid>
                                )}

                                {!showIndividual && (
                                  <Grid item xs={0} p={2} mt={1}></Grid>
                                )}
                                {!showIndividual && (
                                  <Grid item xs={8} p={2} mt={1}>
                                    <div className=" filter-icon-subs">
                                      <div className="welcome-widgets">
                                        <Popover
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                          }}
                                          id={"simple-popover"}
                                          open={Boolean(anchorEl)}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                        >
                                          <div className="expanded-filter">
                                            <div className="filter-options">
                                              <List
                                                sx={{
                                                  width: "100%",
                                                  maxWidth: 360,
                                                  bgcolor: "background.paper",
                                                }}
                                                component="nav"
                                                aria-labelledby="nested-list-subheader"
                                                subheader={
                                                  <ListSubheader
                                                    component="div"
                                                    id="nested-list-subheader"
                                                  >
                                                    Filters will be applied to
                                                    your grid
                                                  </ListSubheader>
                                                }
                                              >
                                                <Accordion
                                                  expanded={
                                                    expandedFilter === "status"
                                                  }
                                                  onChange={filterAccordianHandleChange(
                                                    "status"
                                                  )}
                                                >
                                                  <AccordionSummary
                                                    expandIcon={
                                                      <ExpandMoreIcon />
                                                    }
                                                    onChange={handleChange(
                                                      "panel1"
                                                    )}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                  >
                                                    <Typography
                                                      sx={{
                                                        flexShrink: 0,
                                                      }}
                                                    >
                                                      Status
                                                    </Typography>
                                                  </AccordionSummary>
                                                  <AccordionDetails>
                                                    <CheckboxList
                                                      id="status"
                                                      options={
                                                        subscriptionStatusFilters
                                                      }
                                                      onChange={
                                                        onFilterStatusChangeHandler
                                                      }
                                                      selectedValues={
                                                        selectedStatus
                                                      }
                                                    />
                                                  </AccordionDetails>
                                                </Accordion>
                                                <Accordion
                                                  expanded={
                                                    expandedFilter ===
                                                    "frequency"
                                                  }
                                                  onChange={filterAccordianHandleChange(
                                                    "frequency"
                                                  )}
                                                >
                                                  <AccordionSummary
                                                    expandIcon={
                                                      <ExpandMoreIcon />
                                                    }
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                  >
                                                    <Typography
                                                      sx={{
                                                        flexShrink: 0,
                                                      }}
                                                    >
                                                      Frequency
                                                    </Typography>
                                                  </AccordionSummary>
                                                  <AccordionDetails>
                                                    <CheckboxList
                                                      id="frequency"
                                                      options={frequencyOptions}
                                                      onChange={
                                                        onFilterFreqChangeHandler
                                                      }
                                                      selectedValues={
                                                        selectedFreq
                                                      }
                                                    />
                                                  </AccordionDetails>
                                                </Accordion>
                                              </List>
                                            </div>
                                            <div className="modal-footer">
                                              <Button
                                                variant="outlined"
                                                className="action-button mr-4"
                                                onClick={clearFilterHandler}
                                              >
                                                Clear
                                              </Button>
                                              <Button
                                                variant="contained"
                                                className="action-button"
                                                onClick={() => {
                                                  applyFilterHandler();
                                                  handleClose();
                                                }}
                                              >
                                                Apply
                                              </Button>
                                            </div>
                                          </div>
                                        </Popover>

                                        <Tooltip
                                          placement="bottom"
                                          title="Filter"
                                        >
                                          <FilterAltOutlinedIcon
                                            onClick={handleClick}
                                            title="filter-icon"
                                          />
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </Grid>
                                )}
                                {showIndividual && (
                                  <Grid item xs={12} p={0}>
                                    {formik.values.subscriptions &&
                                      formik.values.subscriptions.map(
                                        (item, index) => {
                                          let subs = [
                                            ...formik.values.subscriptions,
                                          ];
                                          subs = subs.reverse();
                                          let revIndex =
                                            subs.length -
                                            1 -
                                            subs.findIndex((x) =>
                                              filterSubs(x, true, filterQuery)
                                            );
                                          return renderSubscriptionItem(
                                            item,
                                            index,
                                            revIndex,
                                            true
                                          );
                                        }
                                      )}
                                  </Grid>
                                )}
                                {!showIndividual && (
                                  <Grid item xs={12} p={0}>
                                    {formik.values.subscriptions &&
                                      formik.values.subscriptions.map(
                                        (item, index) => {
                                          let subs = [
                                            ...formik.values.subscriptions,
                                          ];
                                          subs = subs.reverse();
                                          let revIndex =
                                            subs.length -
                                            1 -
                                            subs.findIndex((x) =>
                                              filterSubs(x, false, filterQuery)
                                            );
                                          return renderSubscriptionItemEnterprise(
                                            item,
                                            index,
                                            revIndex,
                                            false
                                          );
                                        }
                                      )}
                                  </Grid>
                                )}
                              </>
                            )}
                          </Grid>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Grid>
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index={TABS.CONTENT_LIST}>
          <ListContent
            userId={id}
            distId={formik.values.userId}
            contentId={catalogId}
            query={query}
            contentList={contentList}
            showColumnPopup={props.showColumnPopup}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            publishStatus={formik.values.publishStatus}
            onCancelTableColumnSelection={props.onCancelTableColumnSelection}
            onSaveTableColumnSelection={props.onSaveTableColumnSelection}
            setSelectionColumns={props.setSelectionColumns}
            catalogName={formik.values.name}
            catalogPublishStatus={formik.values.publishStatus}
            setSelectedSubTab={setSelectedSubTab}
            setContentId={setContentId}
            setContentMode={setContentMode}
            onEdit={onEdit}
            onView={onView}
            routeData={props.routeData}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={TABS.ENROLLED_USERS}>
          <ManageSubscriber
            contentId={catalogId}
            contentName={formik.values.name}
            bundleSubscriberStatus={formik.values.status}
            catalogPublishStatus={formik.values.publishStatus}
            userId={formik.values.userId}
            id={id}
            from="catalog"
            permissions={permissionss}
          />
        </TabPanel>
      </div>
    </div>
  );
  function fillFormikDetails(response, savedStates) {
    return {
      ...formik.values,
      contentId: response.result.contentId,
      documentType: response.result.documentType,
      name: response.result.name,
      userId: response.result.userId,
      lineOfBusinessList: response.result.lineOfBusinessList.map((item) => {
        return { value: item.lineOfBusinessId, label: item.name };
      }),
      countryId: response.result.countryId,
      states: savedStates,
      latestVersion: response.result.latestVersion,
      marketingDescription: response.result.marketingDescription,
      keywords: response.result.contentKeywordList
        .map((item) => item.keyword)
        .join(","),
      numberOfEnrolledUser: catalogContext.copy
        ? 0
        : response.result.numberOfEnrolledUser || 0,
      egEnrolledUser: catalogContext.copy
        ? 0
        : response.result.egEnrolledUser || 0,
      isPublic: response.result.isPublic,
      isPreview: response.result.isPreview,
      isDisplayPublic: response.result.isDisplayPublic,
      publishStatus: catalogContext.copy
        ? false
        : response.result.publishStatus,
      publishContentId: response.result.publishContentId || 0,

      createdOn: catalogContext.copy
        ? new Date().toISOString()
        : response.result.createdOn,
      updatedOn: catalogContext.copy ? null : response.result.updatedOn,
      createdByName: catalogContext.copy ? null : response.result.createdByName,
      updatedByName: catalogContext.copy ? null : response.result.updatedByName,

      mode: catalogContext.copy ? null : mode,
      status: response.result.status,
      contentType: catalogContext.copy ? 1 : response.result.contentType,
      reason: response.result.reason,
      previewType: response.result.previewType,
      content: response.result.content,
      actualValue: (
        response.result.baseValue * getFrequency(response.result.frequency)
      ).toFixed(2),
      subscriptions: fillFormikSubscriptions(response),
    };
  }

  function fillFormikSubscriptions(response) {
    return catalogContext.copy
      ? []
      : response.result.subscriptions?.map((item) => {
          return {
            uid: Math.floor(Math.random() * 1000),
            distributorSubscriptionId: item.distributorSubscriptionId,
            baseValue: item.baseValue?.toFixed(2) || 0,
            oneTimeCost: item.oneTimeCost?.toFixed(2) || 0,
            isRecurring: item.isRecurring,
            frequency: item.frequency,
            isIndividual: item.isIndividual,
            effectiveFromDate: item.effectiveFromDate,
            effectiveToDate: item.effectiveToDate,
            savedEffectiveToDate: item.effectiveToDate,
            contentSubscriptionId: item.contentSubscriptionId,
            status: item.status,
            isAssociated: item.isAssociated,
          };
        });
  }

  /*istanbul ignore next */
  function statusOnAction(actionName, publishStatus, status) {
    if (actionName === "Publish") {
      if (publishStatus === ContentPublishStatus.PUBLISHED) {
        status = ContentPublishStatus.MODIFIED;
      } else if (
        !publishStatus ||
        publishStatus === ContentPublishStatus.DRAFT
      ) {
        status = ContentPublishStatus.PUBLISHED;
      }
    } else {
      status = publishStatus || ContentPublishStatus.DRAFT;
    }
    return status;
  }
  /*istanbul ignore next */
  async function requestOnAction(actionName, payload) {
    if (actionName === "Publish") {
      if (
        contentList.length > 0 &&
        contentList.some((item) => item.publishStatus !== 1)
      ) {
        // Show confirmation in case any unpublishd content associated
        setRequest({
          payload: payload,
          actionName: actionName,
        });
        setOpenConfirmation(true);
      } else {
        await makeRequest(payload, actionName);
      }
    } else {
      await makeRequest(payload, actionName);
    }
  }

  async function loadDistSubscriptions() {
    if (distributorUserIdOfContent || !catalogId > 0) {
      const response2 = await ApiServices.httpGet(
        `${DISTRIBUTOR_SUBSCROPTIONTYPES}/${
          catalogId > 0 ? distributorUserIdOfContent : id
        }`
      );
      setEntSubsTypes(response2.result);
      setSubscriptionOptions(
        response2?.result?.map((r) => {
          return {
            value: r.distributorSubscriptionId,
            label: r.name,
          };
        }) || []
      );
    }
  }
}

export default AddEditCatalog;
/*istanbul ignore next */
function fromDateValidationMessage(
  formik,
  index,
  effFromDateRangeExists,
  isEffFromDateExeeds
) {
  return (
    (formik.errors?.subscriptions?.length > 0 &&
      index < formik.errors?.subscriptions?.length &&
      formik.errors?.subscriptions[index]?.effectiveFromDate) ||
    (effFromDateRangeExists &&
      "Date already exists for this frequency. Please enter valid date") ||
    (isEffFromDateExeeds &&
      "Date must be less than or equal to the subscription's Effective To date.")
  );
}

/*istanbul ignore next */
function getMaxEToDateEG(entSubsTypes, item) {
  return (
    entSubsTypes
      .find(
        (x) => x.distributorSubscriptionId == item.distributorSubscriptionId
      )
      ?.subscriptionPlan.find((x) => x.frequency == item.frequency)
      ?.effectiveToDate || undefined
  );
}

/*istanbul ignore next */
function renderEntFreq(entSubsTypes, item, formik, FREQUENCYENUM) {
  return entSubsTypes
    .find((x) => x.distributorSubscriptionId == item?.distributorSubscriptionId)
    ?.subscriptionPlan?.filter((x) => {
      let filteredValues =
        item.contentSubscriptionId > 0 ||
        formik.values.subscriptions?.filter(
          (y) =>
            y.frequency === x.frequency &&
            !y.isIndividual &&
            y.distributorSubscriptionId === item.distributorSubscriptionId &&
            y.uid !== item.uid &&
            (!y.effectiveToDate ||
              moment(y.effectiveToDate) >
                moment(new Date().setHours(0, 0, 0, 0)))
        );

      return (
        item.contentSubscriptionId > 0 ||
        (x.status > 0 &&
          filteredValues.length < 2 &&
          filteredValues.filter((x) => !x.contentSubscriptionId > 0)?.length <
            1)
      );
    })
    ?.map((res) => {
      return (
        <option
          key={res.frequency}
          value={res.frequency}
          actualCost={res.baseValue}
        >
          {FREQUENCYENUM[res.frequency]}
        </option>
      );
    });
}

/*istanbul ignore next */
function EToDateValidationMessage(formik, index, effToDateRangeExists) {
  return (
    (formik.errors?.subscriptions?.length > 0 &&
      index < formik.errors?.subscriptions?.length &&
      formik.errors?.subscriptions[index]?.effectiveToDate) ||
    (effToDateRangeExists &&
      "Date already exists for this frequency. Please enter valid date")
  );
}

/*istanbul ignore next */
function disableEToDate(isEditMode, item, subs) {
  return (
    !isEditMode ||
    !item.effectiveFromDate ||
    !item.frequency ||
    subs.find(
      (x) =>
        !x.contentSubscriptionId > 0 &&
        item.contentSubscriptionId > 0 &&
        x.frequency === item.frequency
    ) ||
    (item.contentSubscriptionId > 0 && item.savedEffectiveToDate)
  );
}
/*istanbul ignore next */
function effFDateValidationMessage(formik, index, effFromDateRangeExists) {
  return (
    (formik.errors?.subscriptions?.length > 0 &&
      index < formik.errors?.subscriptions?.length &&
      formik.errors?.subscriptions[index]?.effectiveFromDate) ||
    (effFromDateRangeExists &&
      "Date already exists for this frequency. Please enter valid date")
  );
}
/*istanbul ignore next */
function loadFilterOptions(frequencyOptions, item, formik) {
  return frequencyOptions
    ?.filter((x) => {
      let filteredValues =
        item.contentSubscriptionId > 0 ||
        formik.values.subscriptions?.filter(
          (y) =>
            y.frequency === x.value &&
            y.isIndividual &&
            y.distributorSubscriptionId === item.distributorSubscriptionId &&
            y.uid !== item.uid &&
            (!y.effectiveToDate ||
              moment(y.effectiveToDate) >
                moment(new Date().setHours(0, 0, 0, 0)))
        );
      return (
        item.contentSubscriptionId > 0 ||
        (filteredValues.length < 2 &&
          filteredValues.filter((x) => !x.contentSubscriptionId > 0)?.length <
            1)
      );
    })
    ?.map((res) => {
      return (
        <option key={res.value} value={res.value}>
          {res.label}
        </option>
      );
    });
}
/*istanbul ignore next */
function baseValueValidationMessage(formik, index) {
  return formik.errors &&
    formik.errors.subscriptions &&
    formik.errors.subscriptions[index]
    ? formik.errors.subscriptions[index].baseValue
    : "";
}
/*istanbul ignore next */
function filterSubs(item, isIndi, filterQuery) {
  return (
    item.isIndividual === isIndi &&
    ((filterQuery.status.includes(item.status) &&
      filterQuery.frequency.includes(item.frequency)) ||
      !item.contentSubscriptionId > 0)
  );
}
/*istanbul ignore next */
function effDateRange(item, minFromDate, entSubsTypes) {
  let isEffFromDateExeeds = false;
  let isEffToDateExeeds = false;
  let effFromDateRangeExists =
    !item.contentSubscriptionId > 0 &&
    item.effectiveFromDate &&
    new Date(item.effectiveFromDate.replace(/-/g, "/").replace(/T.+/, "")) <
      new Date(
        new Date(Math.max(minFromDate, new Date())).setHours(0, 0, 0, 0)
      );
  let effToDateRangeExists =
    !item.contentSubscriptionId > 0 &&
    item.effectiveToDate &&
    new Date(item.effectiveToDate.replace(/-/g, "/").replace(/T.+/, "")) <
      new Date(
        new Date(Math.max(minFromDate, new Date())).setHours(0, 0, 0, 0)
      );

  if (!item.isIndividual && entSubsTypes && entSubsTypes.length > 0) {
    let maxEDate = getMaxEToDateEG(entSubsTypes, item);
    isEffFromDateExeeds =
      !item.contentSubscriptionId > 0 &&
      item.effectiveFromDate &&
      new Date(
        item.effectiveFromDate.replace(/-/g, "/").replace(/T.+/, "")
      ).setHours(0, 0, 0, 0) > new Date(maxEDate);
    isEffToDateExeeds =
      !item.contentSubscriptionId > 0 &&
      item.effectiveToDate &&
      new Date(
        item.effectiveToDate.replace(/-/g, "/").replace(/T.+/, "")
      ).setHours(0, 0, 0, 0) > new Date(maxEDate);
  }

  return {
    effFromDateRangeExists,
    effToDateRangeExists,
    isEffFromDateExeeds,
    isEffToDateExeeds,
  };
}
/*istanbul ignore next */
function getMinFromAndToDate(item, subs) {
  let minFromDate = new Date(
    Math.max(
      null,
      ...subs
        ?.filter(
          (x) =>
            x.distributorSubscriptionId === item.distributorSubscriptionId &&
            x.frequency === item.frequency &&
            x.uid !== item.uid
        )
        ?.map((e) => new Date(e.effectiveFromDate)),
      ...subs
        ?.filter(
          (x) =>
            x.distributorSubscriptionId === item.distributorSubscriptionId &&
            x.frequency === item.frequency &&
            x.uid !== item.uid
        )
        ?.map((e) => new Date(e.effectiveToDate))
    )
  );
  let minToDate = new Date(item.effectiveFromDate);
  minFromDate.setDate(minFromDate.getDate() + 1);
  minToDate.setDate(minToDate.getDate());
  return { minFromDate, minToDate };
}
