import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Tab,
  Box,
  Tabs,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TextField,
  IconButton,
  Tooltip,
  Popover,
  List,
  ListSubheader,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../../../hooks/useAuth";
import MultiSelectDropDown from "../../../../common/MultiSelectDropDown/MultiSelectDropDown";
import { useFormik } from "formik";
import InlineMessage from "../../../../common/InlineMessage/InlineMessage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./AddEditContent.css";
import { a11yProps } from "../../../../common/TabPanel/a11yProps";
import TabPanel from "../../../../common/TabPanel/TabPanel";
import { ContentSchema } from "../../../../common/ValidationSchema/ContentSchema";
import { useLoading } from "../../../../hooks/useLoader";
import { useMasterData } from "../../../../hooks/useMasterData";
import {
  CONTENT,
  DISTRIBUTOR_SUBSCROPTIONTYPES,
} from "../../../../constants/api-constants";
import ApiServices from "../../../../services/ApiServices";
import { toast } from "react-toastify";
import {
  getFileExtension,
  getFrequency,
  handleStateChange,
} from "../../../../utilities/common";
import DocumentViewer from "../../../../common/DocumentViewer/DocumentViewer";
import { MODE, TABS } from "../../../../constants/common";
import { useContentContext } from "../Content.context";
import { useHistory, useParams } from "react-router";
import { MandatoryField } from "../../../../common/MandatoryFieldsIcon/MandatoryField";
import FileUploader from "../../../../common/FileUploader/FileUploader";
import TagsInput from "../../../../common/TagsInput/TagsInput";
import ManageSubscriber from "../ManageSubscriber/ManageSubscriber";
import {
  previewTypeOptions,
  cofirmationToPublish,
  confirmationNoDocAttached,
  contentTypeOptions,
  typeOfContentOptions,
  ContentPublishStatus,
  TypeofConstantENUM,
  deleteSubscriptionConfirmationMessage,
  subscriptionStatusFilters,
} from "../../constants";
import XFDLViewer from "../../../../common/XFDLViewer/XFDLViewer";
import Confirmation from "../../../../common/Confirmation/Confirmation";
import { formatDate } from "../../../../utilities/formatter";
import CurrencyTextbox from "../../../../common/CurrencyTextbox/CurrencyTextbox";
import { usePageMode } from "../../../../hooks/usePageMode";
import Select from "@mui/material/Select";
import { AudioPreview } from "../../../../common/AudioPreview/AudioPreview";
import { VideoPreview } from "../../../../common/VideoPreview/VideoPreview";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import moment from "moment";
import CheckboxList from "../../../../common/CheckboxList/CheckboxList";
import Group2252459 from "../../../../common/Modal/Distributor/Group2252459.png";
import Group2252519 from "../../../../common/Modal/Distributor/Group2252519.png";
import {
  SystemModulesEnum,
  SystemUsersEnum,
} from "../../../../constants/SystemUsers";
import {
  getModuleId,
  PermissionEnum,
  PermissionService,
} from "../../../../constants/permissionService";
import { MenuListItems } from "../../../../common/Sidebar/MenuListItems";

/*istanbul ignore next */
function AddEditContent(props) {
  const {
    id,
    contentId,
    mode,
    onEdit,
    onSave,
    onCancel,
    onNext,
    setSelectedSubTab,
    signUpApproval,
  } = props;
  const history = useHistory();
  const auth = useAuth();
  const page = usePageMode();
  const { showLoader, show } = useLoading();
  const contentContext = useContentContext();
  const { subTabId } = useParams();
  const timerId = useRef(null);
  const [selectedTab, setSelectedTab] = useState(TABS.CONTENT_INFORMATION);
  const [file, setFile] = useState(null);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [acceptFileType, setAcceptFileType] = useState("");
  const [showEditAction, setShowEditAction] = useState(true);
  const [fileContent, setFileContent] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [distributorUserIdOfContent, setDistributorUserIdOfContent] =
    useState(0);
  const masterDataContext = useMasterData();
  const allowedContentTypes = [4, 5, 6, 1, 8, 9, 3];

  const handleConfirmationOpen = (actionName, cnfMessage) => {
    setConfirmationMessage(cnfMessage);
    setAction(actionName);
    setConfirmation(true);
  };
  const handleConfirmationClose = () => {
    setConfirmationMessage("");
    setAction("");
    setConfirmation(false);
  };
  const handleNextButtonClick = () => {
    const requiresAttachment = formik.values.typeOfContent;
    const hasAttachment = !!formik.values.filePath;

    if (
      requiresAttachment &&
      !hasAttachment &&
      formik.values.typeOfContent !== "11" // For web link
    ) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };
  const [action, setAction] = useState("");
  const dataFetchedRef = useRef(false);
  const maxSizeInBytes = 25 * 1024 * 1024; // 25MB in bytes
  const maxSizeExeedError =
    "File size exceeds maximum 25MB allowed.  Either reduce size or contact support@inchanted.com regarding an exception"; // 25MB Exeeded

  const handleCancelClick = () => {
    contentContext.setSelectedTab(TABS.CONTENT_LIST);
    contentContext.setContentMode(MODE.LIST);
    history.push(
      `${history.location.pathname.substring(
        0,
        history.location.pathname.indexOf("content")
      )}${TABS.CONTENT}`
    );
  };

  const formik = useFormik({
    initialValues: {
      contentId: 0,
      typeOfContent: "",
      formNumber: "",
      contentOriginator: "",
      name: "",
      userId: id,
      typeOfFormList: [],
      lineOfBusinessList: [],
      countryId: 1,
      stateId: "",
      latestVersion: "",
      marketingDescription: "",
      webLink: "",
      keywords: "",
      tages: "",
      fillable: 1,
      file: null,
      fileStream: null,
      filePath: "",
      isFileDeleted: true,
      createdOn: null,
      updatedOn: null,
      createdByName: null,
      updatedByName: null,
      numberOfEnrolledUser: 0,
      egEnrolledUser: 0,
      isPublic: false,
      isPreview: false,
      isDisplayPublic: false,
      contentType: 1,
      isRecurring: true,
      previewType: 0,
      oneTimeCost: 0,
      subscriptions: [],
      states: [],
      dirty: false,
      reason: "",
    },
    enableReinitialize: true,
    validationSchema: ContentSchema,
  });

  const [typeOfFormOptions, setTypeOfFormOptions] = useState([]);
  const [lobOptions, setLobOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [allStateOptions, setAllStateOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateNames, setStateNames] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showIndividual, setShowIndividual] = useState(true);
  const [activeButton, setActiveButton] = useState("individual");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [expandedFilter, setExpandedFilter] = useState("NA");
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedFreq, setSelectedFreq] = useState([]);
  const [entSubsTypes, setEntSubsTypes] = useState([]);
  const [setExpanded] = React.useState("panel1");
  const [filterQuery, setFilterQuery] = useState({
    status: [1, 4],
    frequency: [99, 1, 2, 3, 4],
  });

  const isAllSelected =
    stateNames &&
    stateNames.length > 0 &&
    selected.length === stateNames.length - 1;
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);

  const fillableOptions = [
    { value: 1, name: "Non-Fillable" },
    { value: 2, name: "Fillable" },
    { value: 3, name: "Fillable-Mapped" },
  ];
  const frequencyOptions = [
    { value: 1, label: "Monthly" },
    { value: 2, label: "Quarterly" },
    { value: 3, label: "Semi-Annual" },
    { value: 4, label: "Annual" },
  ];

  const frequencyOptionsIndividual = [
    { value: 99, label: "One Time Cost" },
    { value: 1, label: "Monthly" },
    { value: 2, label: "Quarterly" },
    { value: 3, label: "Semi-Annual" },
    { value: 4, label: "Annual" },
  ];

  const FREQUENCYENUM = {
    // 99: "One Time Cost",
    1: "Monthly",
    2: "Quarterly",
    3: "Semi-Annual",
    4: "Annual",
  };

  const permissions = PermissionService(
    getModuleId(props.routeData?.userTypeId)
  );

  let moduleId = MenuListItems.find(
    (item) => item.name === SystemModulesEnum.CONTENT
  ).moduleId;
  const permissionss = PermissionService(moduleId);
  useEffect(() => {
    loadDropdownOptions();
  }, []);

  useEffect(() => {
    loadDistSubscriptions();
  }, [distributorUserIdOfContent]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (contentId > 0) {
      getContentDetail();
      // Hide Edit button for enterprise user
      if (history.location.pathname.includes("enterprisegroup")) {
        setShowEditAction(false);
      }
    } else {
      formik.setValues({
        ...formik.values,
        createdOn: new Date().toISOString(),
      });
      setSelectedKeywords([]);
      setSelectedTags([]);
    }
    formik.validateForm();

    if (
      props.permissions?.includes("Update") &&
      !history.location.pathname.includes("enterprisegroup")
    ) {
      setShowEditAction(true);
    } else {
      setShowEditAction(false);
    }

    return () => {
      //Clearing a timeout
      clearTimeout(timerId.current);
    };
  }, [contentId]);

  useEffect(() => {
    if (
      [
        TABS.CONTENT_INFORMATION,
        TABS.CONTENT_PREVIEW,
        TABS.ENROLLED_USERS,
      ].includes(subTabId)
    ) {
      setSelectedSubTab(subTabId);
      setSelectedTab(subTabId || TABS.CONTENT_INFORMATION);
    }
  }, [subTabId]);

  useEffect(() => {
    if (contentContext.copy) {
      formik.setValues({
        ...formik.values,
        publishStatus: 0,
        status: 0,
      });
    }
  }, [contentContext.copy]);

  useEffect(() => {
    let isEditTrue =
      !history.location.pathname.includes("enterprisegroup") &&
      (mode === MODE.EDIT || mode === MODE.ADD);
    setIsEditMode(isEditTrue);
  }, [mode]);

  useEffect(() => {
    setAcceptFileType(
      typeOfContentOptions?.find(
        (item) => item.value === Number(formik.values.typeOfContent)
      )?.accept
    );
    formik.validateForm();
  }, [formik.values.typeOfContent]);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        handleCancelClick();
      }
    };
  }, [history]);

  const handleIndividualClick = () => {
    setShowIndividual(true);
    if (
      !formik.values.subscriptions?.filter((x) => x.isIndividual)?.length > 0
    ) {
      addNewSubscription(true);
    }
  };

  const handleEnterpriseClick = () => {
    setShowIndividual(false);
    if (
      !formik.values.subscriptions?.filter((x) => !x.isIndividual)?.length > 0
    ) {
      addNewSubscription(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const clearFilterHandler = () => {
    setFilterQuery({
      status: [1, 4],
      frequency: [99, 1, 2, 3, 4],
    });
    setSelectedStatus([]);
    setSelectedFreq([]);
  };
  const applyFilterHandler = () => {
    setFilterQuery((prevState) => {
      return {
        ...prevState,
        status: selectedStatus.length > 0 ? selectedStatus : [1, 4],
        frequency: selectedFreq.length > 0 ? selectedFreq : [99, 1, 2, 3, 4],
      };
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const filterAccordianHandleChange = (panel) => (event, isExpanded) => {
    setExpandedFilter(isExpanded ? panel : "NA");
  };

  const onFilterStatusChangeHandler = (values) => {
    setSelectedStatus(values);
  };
  const onFilterFreqChangeHandler = (values) => {
    setSelectedFreq(values);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const deleteRow = (item) => {
    setOpenConfirmation(true);
    setSelectedItem(item.uid);
  };

  const deleteSubscription = () => {
    const newList = formik.values.subscriptions.filter(
      (item) => item.uid !== selectedItem
    );
    if (!(newList?.length > 0)) {
      formik.setValues({
        ...formik.values,
        subscriptions: newList,
        contentType: 1,
      });
    } else {
      formik.setValues({
        ...formik.values,
        subscriptions: newList,
      });
    }
  };

  const totalEnrolledUsers =
    formik.values.numberOfEnrolledUser + formik.values.egEnrolledUser;

  const addNewSubscription = (isInd) => {
    const addSubscriptionInitialValues = {
      distributorSubscriptionId: 0,
      distributorSubscriptionPlanId: 0,
      uid: Math.floor(Math.random() * 1000),
      contentSubscriptionId: 0,
      oneTimeCost: 0,
      baseValue: "",
      frequency: 0,
      isRecurring: true,
      isIndividual: isInd,
      savedEffectiveToDate: null,
      isAssociated: false,
    };
    let newData = [
      ...formik.values.subscriptions,
      addSubscriptionInitialValues,
    ];
    formik.setFieldValue("subscriptions", newData);
  };

  const handleSubscriptionChanges = (e, index, field) => {
    if (["frequency", "distributorSubscriptionId"].includes(field)) {
      formik.setFieldValue(
        `subscriptions[${index}].${field}`,
        Number(e.target.value)
      );
    } else {
      formik.setFieldValue(`subscriptions[${index}].${field}`, e.target.value);
    }
  };

  const handleAssociationChanges = async (e, index, field) => {
    let d = e?._d ? e._d : "";
    await formik.setFieldValue(
      `subscriptions[${index}].${field}`,
      d ? moment(d).format("YYYY-MM-DD") : null
    );
  };

  const loadDropdownOptions = async () => {
    showLoader(true);
    const response = masterDataContext.masterDataValue;
    showLoader(false);
    // Type of Forms
    setTypeOfFormOptions(
      response.result.typeOfForm.map((r) => {
        return {
          value: r.typeOfFormId,
          label: r.name,
        };
      })
    );
    // LOB
    setLobOptions(
      response.result.lineOfBusiness.map((r) => {
        return {
          value: r.lineOfBusinessId,
          label: r.name,
        };
      })
    );
    // Countries
    setCountryOptions(response.result.countries);
    // States
    setAllStateOptions(response.result.states);
  };

  const getDateAlreadyExistsValidation = () => {
    let response = false;
    formik.values.subscriptions?.every((item) => {
      let subs =
        formik.values.subscriptions?.filter(
          (x) => x.isIndividual === item.isIndividual
        ) || [];
      let { minFromDate } = getMinFromAndToDate(item, subs);
      let {
        effFromDateRangeExists,
        effToDateRangeExists,
        isEffFromDateExeeds,
        isEffToDateExeeds,
      } = effDateRange(item, minFromDate, entSubsTypes);

      if (
        effFromDateRangeExists ||
        effToDateRangeExists ||
        isEffFromDateExeeds ||
        isEffToDateExeeds
      ) {
        response = true;
        return false;
      }
      return true;
    });
    return response;
  };

  const renderSubscriptionItem = (item, index, len, isIndi) => {
    let subs =
      formik.values.subscriptions?.filter(
        (x) => x.isIndividual === item.isIndividual
      ) || [];
    let { minFromDate, minToDate } = getMinFromAndToDate(item, subs);
    let { effFromDateRangeExists, effToDateRangeExists } = effDateRange(
      item,
      minFromDate
    );
    return filterSubs(item, isIndi, filterQuery) ? (
      <div
        className={`subscription${index} ${
          formik.values.subscriptions[index]?.status === 1
            ? "active-record"
            : ""
        }`}
      >
        <Grid
          container
          className={`blue-field-section${
            formik.values.subscriptions[index]?.status === 1
              ? "active-record"
              : ""
          }`}
        >
          <Grid item xs={1.8} p={2}>
            <label
              className="extra-padding-input montserrat-14-500 label-gap"
              htmlFor="baseValue"
            >
              Base Cost
            </label>
            <MandatoryField />
            <CurrencyTextbox
              id="baseValue"
              name="baseValue"
              value={formik.values.subscriptions[index]?.baseValue}
              onChange={(e) => {
                formik.setFieldValue(
                  `subscriptions[${index}].baseValue`,
                  Number(e) || null
                );
                page.setDirty(true);
              }}
              placeholder="Enter Base Value"
              disabled={!isEditMode || item.contentSubscriptionId > 0}
              data-testid="currency"
              type="number"
            />
            <InlineMessage error={baseValueValidationMessage(formik, index)} />
          </Grid>
          <Grid item xs={2} p={2}>
            <label
              className="extra-padding-input montserrat-14-500 label-gap"
              htmlFor="frequency"
            >
              Frequency
            </label>
            <MandatoryField />
            <select
              id="frequency"
              name="frequency"
              value={formik.values.subscriptions[index]?.frequency}
              onChange={(e) => {
                const selectedFrequency = Number(e.target.value);
                formik.setFieldValue("dirty", true);
                handleSubscriptionChanges(e, index, "frequency");
                const updatedSubscriptions = [...formik.values.subscriptions];
                updatedSubscriptions[index] = {
                  ...updatedSubscriptions[index],
                  frequency: selectedFrequency,
                  isRecurring: selectedFrequency !== 99,
                };
                formik.setValues({
                  ...formik.values,
                  subscriptions: updatedSubscriptions,
                });
              }}
              className="form-control form-select input-border"
              disabled={!isEditMode || item.contentSubscriptionId > 0}
            >
              <option disabled="true" value={0}>
                Select Frequency
              </option>
              {loadFilterOptions(frequencyOptionsIndividual, item, formik)}
            </select>
            {formik.errors?.subscriptions?.length > 0 &&
              index < formik.errors?.subscriptions?.length && (
                <InlineMessage
                  error={formik.errors?.subscriptions[index]?.frequency}
                />
              )}
          </Grid>
          <Grid item xs={1.6} p={2}>
            <label
              className="extra-padding-input montserrat-14-500 label-gap"
              htmlFor="oneTimeCost"
            >
              Actual Cost
            </label>
            <CurrencyTextbox
              id="actualValue"
              name="actualValue"
              className="form-control form-select input-border"
              value={
                (
                  formik.values.subscriptions[index]?.baseValue *
                  getFrequency(formik.values.subscriptions[index]?.frequency)
                )?.toFixed(2) || 0
              }
              disabled={true}
              type="text"
            />
          </Grid>
          <Grid item xs={1.8} p={2} mt={0.5}>
            <label htmlFor="membershipExpirationDate">
              Effective From Date
            </label>
            <MandatoryField />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disablePast
                minDate={new Date(Math.max(minFromDate, new Date()))}
                className="form-label form-control date-field"
                value={
                  formik.values.subscriptions[index]?.effectiveFromDate || null
                }
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  handleAssociationChanges(e, index, "effectiveFromDate");
                }}
                renderInput={(params) => (
                  <TextField
                    id="startDate"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM/DD/YYYY",
                    }}
                  />
                )}
                disabled={
                  !isEditMode ||
                  !item.frequency ||
                  item.contentSubscriptionId > 0
                }
              />
            </LocalizationProvider>
            {
              <InlineMessage
                error={effFDateValidationMessage(
                  formik,
                  index,
                  effFromDateRangeExists
                )}
              />
            }
          </Grid>
          <Grid item xs={1.8} p={2} mt={0.5}>
            <label htmlFor="membershipExpirationDate">Effective To Date</label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disablePast
                minDate={new Date(Math.max(minToDate, new Date()))}
                className="form-label form-control date-field "
                inputFormat="MM/DD/YYYY"
                value={
                  formik.values.subscriptions[index]?.effectiveToDate || null
                }
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  handleAssociationChanges(e, index, "effectiveToDate");
                }}
                renderInput={(params) => (
                  <TextField
                    id="startDate"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM/DD/YYYY",
                    }}
                  />
                )}
                disabled={disableEToDate(isEditMode, item, subs)}
              />
            </LocalizationProvider>
            {
              <InlineMessage
                error={EToDateValidationMessage(
                  formik,
                  index,
                  effToDateRangeExists
                )}
              />
            }
          </Grid>
          <Grid item xs={1.7} p={2} mt={1}>
            <FormControlLabel
              className="content-checkbox-field form-label form-control input-border cus-cus-fm"
              checked={formik.values.subscriptions[index]?.isRecurring}
              name="Display to Public"
              data-testid="recurring-type-checkbox"
              onChange={(e) => {
                const updatedSubscriptions = [...formik.values.subscriptions];
                updatedSubscriptions[index] = {
                  ...updatedSubscriptions[index],
                  isRecurring: e.target.checked,
                };
                formik.setValues({
                  ...formik.values,
                  subscriptions: updatedSubscriptions,
                });
                page.setDirty(true);
              }}
              control={
                <Checkbox
                  disabled={
                    !isEditMode ||
                    formik.values.subscriptions[index]?.frequency === 99 ||
                    item.contentSubscriptionId > 0
                  }
                />
              }
              label={<Typography>Auto Renewal</Typography>}
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={0.5} p={2} mt={4} className="add-association">
            {isEditMode && index === len && (
              <Tooltip placement="bottom" title="Add">
                <AddOutlinedIcon
                  name="add-icon"
                  title="add-icon"
                  style={{ marginTop: "-7px" }}
                  onClick={() => {
                    addNewSubscription(true);
                  }}
                />
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={0.5} p={2} mt={3.2}>
            {isEditMode && (
              <span>
                <Tooltip placement="bottom" title="Delete">
                  <IconButton
                    className="delete-icon-color"
                    onClick={() => deleteRow(item)}
                    aria-label="delete"
                    color="primary"
                    data-testid="btn-del"
                    disabled={item.isAssociated}
                  >
                    {item.isAssociated ? (
                      <img
                        src={Group2252519}
                        alt="Disabled Delete"
                        height={35}
                      />
                    ) : (
                      <img src={Group2252459} alt="Delete" height={35} />
                    )}
                  </IconButton>
                </Tooltip>
              </span>
            )}
          </Grid>
        </Grid>
      </div>
    ) : null;
  };
  const renderSubscriptionItemEnterprise = (item, index, len, isIndi) => {
    let subs =
      formik.values.subscriptions?.filter(
        (x) => x.isIndividual === item.isIndividual
      ) || [];
    let { minFromDate, minToDate } = getMinFromAndToDate(item, subs);
    let {
      effFromDateRangeExists,
      effToDateRangeExists,
      isEffFromDateExeeds,
      isEffToDateExeeds,
    } = effDateRange(item, minFromDate, entSubsTypes);
    return filterSubs(item, isIndi, filterQuery) ? (
      <div
        className={`subscription${index} ${
          formik.values.subscriptions[index]?.status === 1
            ? "active-record"
            : ""
        }`}
      >
        <Grid
          container
          className={`blue-field-section${
            formik.values.subscriptions[index]?.status === 1
              ? "active-record"
              : ""
          }`}
        >
          <Grid item xs={2} p={2}>
            <label
              className="extra-padding-input-2 montserrat-14-500 label-gap"
              htmlFor="subscriptions"
            >
              Select Subscriptions
            </label>
            <select
              name="subscriptions"
              id="subscriptions"
              className="form-label form-control input-border"
              disabled={!isEditMode || item.contentSubscriptionId > 0}
              value={
                formik.values.subscriptions[index]?.distributorSubscriptionId
              }
              onChange={(e) => {
                handleSubscriptionChanges(
                  e,
                  index,
                  "distributorSubscriptionId"
                );
                formik.setFieldValue(
                  `subscriptions[${index}].${"frequency"}`,
                  0
                );
                formik.setFieldValue(
                  `subscriptions[${index}].${"baseValue"}`,
                  0
                );
                formik.setFieldValue(
                  `subscriptions[${index}].${"effectiveToDate"}`,
                  null
                );
                page.setDirty(true);
                formik.setFieldValue("dirty", true);
              }}
            >
              <option disabled="true" value={0}>
                Select Subscriptions
              </option>
              {subscriptionOptions?.map((res) => {
                return (
                  <option key={res.value} value={res.value}>
                    {res.label}
                  </option>
                );
              })}
            </select>
          </Grid>

          <Grid item xs={2} p={2}>
            <label
              className="extra-padding-input montserrat-14-500 label-gap"
              htmlFor="frequency"
            >
              Frequency
            </label>
            <span
              style={{
                color: "red",
                marginLeft: "3px",
                marginTop: "2px",
              }}
            >
              *
            </span>
            <select
              id="frequency"
              name="frequency"
              value={formik.values.subscriptions[index]?.frequency}
              onChange={(e) => {
                formik.setFieldValue("dirty", true);
                handleSubscriptionChanges(e, index, "frequency");
                let selectedFreq = entSubsTypes
                  .find(
                    (x) =>
                      x.distributorSubscriptionId ==
                      formik.values.subscriptions[index]
                        ?.distributorSubscriptionId
                  )
                  ?.subscriptionPlan.find((x) => x.frequency == e.target.value);
                formik.setFieldValue(
                  `subscriptions[${index}].${"baseValue"}`,
                  selectedFreq?.baseValue
                );

                formik.setFieldValue(
                  `subscriptions[${index}].${"effectiveToDate"}`,
                  selectedFreq?.effectiveToDate
                    ? moment(
                        new Date(selectedFreq.effectiveToDate).setHours(
                          0,
                          0,
                          0,
                          0
                        )
                      ).format("YYYY-MM-DD")
                    : null
                );
                formik.setFieldValue(
                  `subscriptions[${index}].${"distributorSubscriptionPlanId"}`,
                  selectedFreq?.distributorSubscriptionPlanId
                );
              }}
              className="form-control form-select input-border"
              disabled={
                !isEditMode ||
                item.contentSubscriptionId > 0 ||
                !item.distributorSubscriptionId
              }
            >
              <option disabled="true" value={0}>
                Select Frequency
              </option>
              {renderEntFreq(entSubsTypes, item, formik, FREQUENCYENUM)}
            </select>
            {formik.errors?.subscriptions?.length > 0 &&
              index < formik.errors?.subscriptions?.length && (
                <InlineMessage
                  error={formik.errors?.subscriptions[index]?.frequency}
                />
              )}
          </Grid>
          <Grid item xs={2} p={2}>
            <label
              className="extra-padding-input montserrat-14-500 label-gap"
              htmlFor="oneTimeCost"
            >
              Base Cost
            </label>
            <CurrencyTextbox
              id="actualValue"
              name="actualValue"
              className="form-control form-select input-border"
              value={formik.values.subscriptions[index]?.baseValue || ""}
              disabled={true}
              type="text"
            />
          </Grid>
          <Grid item xs={2} p={2} mt={0.5}>
            <label htmlFor="membershipExpirationDate">
              Effective From Date
            </label>
            <span
              style={{
                color: "red",
                marginLeft: "3px",
                marginTop: "2px",
              }}
            >
              *
            </span>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disablePast
                maxDate={getMaxEToDateEG(entSubsTypes, item)}
                minDate={new Date(Math.max(minFromDate, new Date()))}
                className="form-label form-control date-field "
                inputFormat="MM/DD/YYYY"
                value={
                  formik.values.subscriptions[index]?.effectiveFromDate || null
                }
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  handleAssociationChanges(e, index, "effectiveFromDate");
                }}
                renderInput={(params) => (
                  <TextField
                    id="startDate"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM/DD/YYYY",
                    }}
                  />
                )}
                disabled={
                  !isEditMode ||
                  !item.frequency > 0 ||
                  item.contentSubscriptionId > 0
                }
              />
            </LocalizationProvider>
            {
              <InlineMessage
                error={fromDateValidationMessage(
                  formik,
                  index,
                  effFromDateRangeExists,
                  isEffFromDateExeeds
                )}
              />
            }
          </Grid>
          <Grid item xs={2} p={2} mt={0.5}>
            <label htmlFor="membershipExpirationDate">Effective To Date</label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                disablePast
                maxDate={getMaxEToDateEG(entSubsTypes, item)}
                minDate={new Date(Math.max(minToDate, new Date()))}
                className="form-label form-control date-field "
                inputFormat="MM/DD/YYYY"
                value={
                  formik.values.subscriptions[index]?.effectiveToDate || null
                }
                onChange={(e) => {
                  formik.setFieldValue("dirty", true);
                  handleAssociationChanges(e, index, "effectiveToDate");
                }}
                renderInput={(params) => (
                  <TextField
                    id="startDate"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "MM/DD/YYYY",
                    }}
                  />
                )}
                disabled={disableEToDate(isEditMode, item, subs)}
              />
            </LocalizationProvider>
            {
              <InlineMessage
                error={getErrorMessage(
                  index,
                  effToDateRangeExists,
                  isEffToDateExeeds
                )}
              />
            }
          </Grid>
          <Grid item xs={0.5} p={2} mt={4} className="add-association">
            {isEditMode && index === len && (
              <Tooltip placement="bottom" title="Add">
                <AddOutlinedIcon
                  name="add-icon"
                  title="add-icon"
                  style={{ marginTop: "-7px" }}
                  onClick={() => {
                    addNewSubscription(false);
                  }}
                />
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={0.5} p={2} mt={3.2}>
            {isEditMode && (
              <span>
                <Tooltip placement="bottom" title="Delete">
                  <IconButton
                    className="delete-icon-color"
                    onClick={() => deleteRow(item)}
                    aria-label="delete"
                    color="primary"
                    data-testid="btn-del"
                    disabled={item.isAssociated}
                  >
                    {item.isAssociated ? (
                      <img
                        src={Group2252519}
                        alt="Disabled Delete"
                        height={35}
                      />
                    ) : (
                      <img src={Group2252459} alt="Delete" height={35} />
                    )}
                  </IconButton>
                </Tooltip>
              </span>
            )}
          </Grid>
        </Grid>
      </div>
    ) : null;
  };

  function getErrorMessage(index, effToDateRangeExists, isEffToDateExeeds) {
    return (
      (formik.errors?.subscriptions?.length > 0 &&
        index < formik.errors?.subscriptions?.length &&
        formik.errors?.subscriptions[index]?.effectiveToDate) ||
      (effToDateRangeExists &&
        "Date already exists for this frequency. Please enter valid date") ||
      (isEffToDateExeeds &&
        "Date must be less than or equal to the subscription's Effective To date.")
    );
  }
  const handleHandleChange = (event, newValue) => {
    history.push(
      `${history.location.pathname.substring(
        0,
        history.location.pathname.indexOf("content")
      )}${TABS.CONTENT}/${newValue}`
    );
  };

  const getContentDetail = async (value) => {
    try {
      const cId = value || contentId;
      const response = await ApiServices.httpGet(`${CONTENT}/${cId}`);
      if (response.responseStatus.isSuccess) {
        timerId.current = setTimeout(() => {
          page.setDirty(false);
        }, 100);
        let savedStates = response.result.states?.map((el) => el.name);
        setSelected(savedStates?.length ? savedStates : ["Not Applicable"]);
        formik.setValues(fillFormikDetails(response, savedStates));
        setSelectedKeywords(
          response.result.contentKeywordList.map((item) => item.keyword)
        );
        setSelectedTags(response.result.contentTagList.map((item) => item.tag));
        // For Word, Excel & Power Point (4,5,6) Need to use gcpPath
        msContent(response);
        // For PDF & JPG (1, 3) Need to use fileStream as base64 string
        streamContent(response);
        // For XFDL (2) Need to use xfdlJson as json string
        xfdlContent(response);
        setFile();
        setDistributorUserIdOfContent(response.result.userId);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      toast.error("Internal server error");
    }
  };

  const makeRequest = async (event, actionName) => {
    let selectedStates =
      stateOptions &&
      stateOptions.filter((el) => formik.values.states?.includes(el.name));
    let statePayload = selectedStates.includes("Not Applicable")
      ? []
      : selectedStates;
    const formData = new FormData();
    contentFormData(formData);
    formDataChangesOne(formData, statePayload);
    formData.append(
      "typeOfFormList",
      JSON.stringify(
        formik.values.typeOfFormList.map((item) => ({
          typeOfFormId: item.value,
        }))
      )
    );
    formData.append(
      "lineOfBusinessList",
      JSON.stringify(
        formik.values.lineOfBusinessList.map((item) => ({
          lineOfBusinessId: item.value,
        }))
      )
    );
    formData.append("file", file);
    if (formik.values.isFileDeleted || actionName === "Delete") {
      formData.append("file", null);
      formData.append("filePath", "");
      formik.setValues({
        ...formik.values,
        filePath: "",
      });
      formData.append("isFileDeleted", true);
    }
    formData.append("isPublic", formik.values.isPublic);
    formData.append("isPreview", formik.values.isPreview);
    formData.append("isDisplayPublic", formik.values.isDisplayPublic);
    if (Number(formik.values.contentType) > 0) {
      formData.append("contentType", formik.values.contentType);
    }
    if (Number(formik.values.previewType) > 0) {
      formData.append("previewType", formik.values.previewType);
    }
    let status;
    const publishStatus = formik.values.publishStatus;
    status = handleStatusOnAction(actionName, publishStatus, status);
    formDataChangesSecond(formData, status, actionName);
    const response = await apiCall(formData);
    return response;
  };

  const createContent = async (event, actionName) => {
    try {
      if (file) {
        let ext = getFileExtension(file.name);
        const fileType = typeOfContentOptions.find(
          (option) => option.value === Number(formik.values.typeOfContent)
        )?.fileType;
        if (!ext.toUpperCase().includes(fileType?.toUpperCase())) {
          toast.error(`Please select ${fileType?.toLowerCase()} file`);
          return;
        }
      }
      showLoader(true);
      dataFetchedRef.current = false;
      page.setDirty(false);
      const response = await makeRequest(event, actionName);
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        contentContext.resetCopyContent();
        await getContentDetail(response.result.contentId);
        toast.success(response.responseStatus.description);
        if (actionName === "Next") {
          onNext(contentId > 0 ? "Update" : "Save", response.result.contentId);
        } else {
          onSave("content", response.result);
        }
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const deleteContent = async (e) => {
    try {
      showLoader(true);
      const response = await makeRequest(e, "Delete");
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        setSelectedDocument([]);
        setFileContent(null);
        toast.success("Content deleted sucessfully.");
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const uploadContent = async (e) => {
    try {
      showLoader(true);
      setSelectedDocument([]);
      const response = await makeRequest(e, "Upload");
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        await getContentDetail();
        toast.success("Document uploaded sucessfully.");
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    let ext = getFileExtension(selectedFile.name);
    if (
      !acceptFileType ||
      !acceptFileType.some((x) => x.toLowerCase().includes(ext.toLowerCase()))
    ) {
      toast.error(
        `Please select ${(
          typeOfContentOptions?.find(
            (item) => item.value === Number(formik.values.typeOfContent)
          )?.name || "type of content before"
        ).toLowerCase()} file`
      );
      return;
    }
    formik.setValues({
      ...formik.values,
      file: e.target.files[0],
      isFileDeleted: false,
      filePath: selectedFile.name,
    });
    setFile(e.target.files[0]);
  };
  const onDeleteFileHandler = () => {
    formik.setValues({
      ...formik.values,
      file: null,
      isFileDeleted: true,
      filePath: "",
    });
    setFile(null);
  };

  const populateStateOptions = (countryId) => {
    const result = allStateOptions?.filter(
      (res) => res.countryId === Number(countryId)
    );
    setStateOptions(result);
    let stateNameOptions = result && result.map((item) => item.name);
    stateNameOptions = [...stateNameOptions, "Not Applicable"];
    setStateNames(stateNameOptions);
  };

  useEffect(() => {
    populateStateOptions(formik.values.countryId || 1);
  }, [formik.values.countryId, allStateOptions]);

  const handleChangeDropDown = (event) => {
    const selectedStates = handleStateChange(
      event,
      stateNames,
      selected,
      setSelected,
      formik.values.states
    );
    formik.setFieldValue("states", selectedStates);
  };

  return (
    <div className="welcome">
      <div className="addedit-content-main">
        <div className="sticky-top-element" style={{ background: "#f4f4f4" }}>
          <div style={{ display: "inline-block" }}>
            <Tabs
              value={selectedTab}
              onChange={handleHandleChange}
              aria-label="Distributor Tabs"
              className="content-tabs"
            >
              <Tab
                label="Content Information"
                value={TABS.CONTENT_INFORMATION}
                className="content-sub-tabs"
                {...a11yProps("Content Information", "ContentInformation")}
              />
              <Tab
                label="Content Preview"
                value={TABS.CONTENT_PREVIEW}
                className="content-sub-tabs"
                {...a11yProps("Content Preview", "ContentPreview")}
                disabled={!contentId > 0 || contentContext.copy}
              />
              <Tab
                label="Manage Subscribers"
                value={TABS.ENROLLED_USERS}
                className="content-sub-tabs"
                {...a11yProps("Manage Subscriber", "ManageSubscribers")}
                disabled={!contentId > 0 || contentContext.copy}
              />
            </Tabs>
          </div>
          <div
            style={{
              display: "inline-block",
              paddingTop: "2px",
              float: "right",
            }}
          >
            {selectedTab === TABS.CONTENT_INFORMATION && (
              <div>
                {mode === MODE.VIEW &&
                  showEditAction &&
                  ((permissions &&
                    permissions.includes(PermissionEnum.CREATE)) ||
                    (permissions &&
                      permissions.includes(PermissionEnum.UPDATE))) && (
                    <Button
                      variant="contained"
                      type="submit"
                      className="action-button mr-4"
                      onClick={() => onEdit(formik.values)}
                      disabled={
                        formik.values.publishStatus ===
                          ContentPublishStatus.MODIFIED &&
                        formik.values.status === 0
                      }
                    >
                      Edit
                    </Button>
                  )}
                {isEditMode && (
                  <Button
                    variant="contained"
                    type="submit"
                    className="action-button mr-4"
                    onClick={(e) => {
                      if (formik.values.isFileDeleted) {
                        handleConfirmationOpen(
                          contentId === 0 || contentContext.copy
                            ? "Save & Exit"
                            : "Update & Exit",
                          confirmationNoDocAttached
                        );
                      } else {
                        createContent(e);
                      }
                    }}
                    disabled={
                      !formik.isValid ||
                      !isEditMode ||
                      getDateAlreadyExistsValidation()
                    }
                  >
                    {contentId === 0 || contentContext.copy ? "Save" : "Update"}{" "}
                    & Exit
                  </Button>
                )}

                {isEditMode && (
                  <Button
                    variant="contained"
                    type="submit"
                    className="action-button mr-4"
                    onClick={(e) => {
                      if (formik.values.isFileDeleted) {
                        handleConfirmationOpen(
                          contentId === 0 || contentContext.copy
                            ? "Save & Next"
                            : "Update & Next",
                          confirmationNoDocAttached
                        );
                      } else {
                        createContent(e, "Next");
                      }
                    }}
                    disabled={
                      !formik.isValid ||
                      !isEditMode ||
                      getDateAlreadyExistsValidation()
                    }
                  >
                    {contentId === 0 || contentContext.copy ? "Save" : "Update"}{" "}
                    & Next
                  </Button>
                )}

                {isEditMode && (
                  <Button
                    variant="contained"
                    type="submit"
                    className="action-button mr-4"
                    onClick={(e) => {
                      handleNextButtonClick();
                      if (formik.values.isFileDeleted) {
                        handleConfirmationOpen(
                          "Publish",
                          confirmationNoDocAttached
                        );
                      } else {
                        handleConfirmationOpen("OK", cofirmationToPublish);
                      }
                    }}
                    disabled={disablePublishCondition()}
                  >
                    Publish
                  </Button>
                )}
                <Button
                  variant="outlined"
                  className="action-button"
                  onClick={() => {
                    handleCancelClick();
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
        <TabPanel value={selectedTab} index="ContentInformation">
          <Grid item xs={12}>
            <div className="welcome-main">
              <div className="welcome-message">
                <Typography className="montserrat-16-600">
                  Basic Information
                </Typography>
              </div>

              <div>
                <Confirmation
                  open={openConfirmation}
                  title={deleteSubscriptionConfirmationMessage}
                  cancelButtonLabel="No"
                  confirmButtonLabel="Yes"
                  onConfirm={() => {
                    deleteSubscription();
                    setOpenConfirmation(false);
                  }}
                  onCancel={() => {
                    setOpenConfirmation(false);
                  }}
                />
                {showWarning && (
                  <div className="warning-message">
                    Please upload an attachment for the selected type of
                    content.
                  </div>
                )}
                <Confirmation
                  open={confirmation}
                  handleClose={handleConfirmationClose}
                  title={confirmationMessage}
                  cancelButtonLabel="Cancel"
                  confirmButtonLabel={action}
                  onConfirm={(e) => {
                    if (
                      action === "Save & Next" ||
                      action === "Update & Next"
                    ) {
                      createContent(e, "Next");
                      handleConfirmationClose();
                    } else if (action === "Publish") {
                      handleConfirmationOpen("OK", cofirmationToPublish);
                    } else if (action === "OK") {
                      createContent(e, "Publish");
                      handleConfirmationClose();
                    } else {
                      createContent(e);
                      handleConfirmationClose();
                    }
                  }}
                  onCancel={() => {
                    handleConfirmationClose();
                  }}
                />
              </div>
            </div>
          </Grid>

          <div
            className="menu-bar"
            style={{
              display: "flex",
              height: "100%",
              paddingTop: "1rem",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3} p={2}>
                <label htmlFor="typeOfContent">Type of Content</label>
                <MandatoryField />
                <select
                  id="typeOfContent"
                  name="typeOfContent"
                  value={formik.values.typeOfContent}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      file: null,
                      isFileDeleted: true,
                      filePath: "",
                      fillable: 1,
                      formNumber: ["2", "1"].includes(
                        formik.values.typeOfContent
                      )
                        ? formik.values.formNumber
                        : "",
                    });
                    setFile(null);
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  className="form-control form-select"
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                >
                  <option value="">Select Type of Content</option>
                  {typeOfContentOptions?.map((res) => {
                    return (
                      <option key={res.value} value={res.value}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>

              {["2", "1"].includes(formik.values.typeOfContent) && (
                <Grid item xs={3} p={2}>
                  <label htmlFor="formNumber">Form Number</label>
                  <MandatoryField />
                  <input
                    id="formNumber"
                    name="formNumber"
                    onChange={(e) => {
                      formik.handleChange(e);
                      page.setDirty(true);
                    }}
                    value={formik.values.formNumber || ""}
                    className="form-label form-control border-cus-cus"
                    type="text"
                    placeholder="Enter Form Number"
                    maxLength={150}
                    autocomplete="off"
                    disabled={!isEditMode}
                  />
                  <InlineMessage error={formik.errors.formNumber} />
                </Grid>
              )}
              <Grid item xs={3} p={2}>
                <label htmlFor="name">Content Name</label>
                <MandatoryField />
                <input
                  id="name"
                  name="name"
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  value={formik.values.name}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  placeholder="Enter Content Name"
                  maxLength={150}
                  autocomplete="off"
                  disabled={!isEditMode}
                />
                <InlineMessage error={formik.errors.name} />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="contentOriginator">Content Originator</label>
                <input
                  id="contentOriginator"
                  name="contentOriginator"
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  value={formik.values.contentOriginator}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  placeholder="Enter Content Originator"
                  maxLength={150}
                  autocomplete="off"
                  disabled={!isEditMode}
                />
                <InlineMessage error={formik.errors.contentOriginator} />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="typeOfFormList">Type of Forms</label>
                <MultiSelectDropDown
                  name="typeOfFormList"
                  id="typeOfFormList"
                  value={formik.values.typeOfFormList}
                  onChange={(value) => {
                    formik.setValues({
                      ...formik.values,
                      typeOfFormList: value,
                    });
                    page.setDirty(true);
                  }}
                  options={typeOfFormOptions}
                  disabled={!isEditMode}
                  placeholder="Select Type of Forms"
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="lineOfBusinessList">LOB</label>
                <MultiSelectDropDown
                  name="lineOfBusinessList"
                  id="lineOfBusinessList"
                  value={formik.values.lineOfBusinessList}
                  onChange={(value) => {
                    formik.setValues({
                      ...formik.values,
                      lineOfBusinessList: value,
                    });
                    page.setDirty(true);
                  }}
                  options={lobOptions}
                  disabled={!isEditMode}
                  placeholder="Select LOB"
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="countryId">Country</label>
                <select
                  id="countryId"
                  name="countryId"
                  data-testid="country-dropdown"
                  value={formik.values?.countryId}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      dirty: true,
                      stateId: null,
                      countryId: Number(e.target.value),
                    });
                    setSelected([]);
                    page.setDirty(true);
                  }}
                  className="form-control form-select"
                  disabled={!isEditMode}
                >
                  {countryOptions?.map((res) => {
                    return (
                      <option key={res.countryId} value={res.countryId}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="stateId">State/Province</label>
                <Select
                  displayEmpty
                  data-testid="states-dropdown"
                  id="stateId"
                  name="stateId"
                  labelId="mutiple-select-label"
                  className={
                    !isEditMode
                      ? "form-select form-control select-user cus-cus-border-none roles-dropdown-disabled"
                      : "form-select form-control select-user cus-cus-border-none roles-dropdown"
                  }
                  disabled={!isEditMode}
                  multiple
                  onChange={(e) => {
                    handleChangeDropDown(e);
                    page.setDirty(true);
                  }}
                  value={selected}
                  renderValue={(selectedItem) => {
                    if (selected.length === 0) {
                      return "Select State";
                    }
                    return selectedItem.join(", ");
                  }}
                >
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < stateNames.length - 1
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={isAllSelected ? "Unselect All" : "Select All"}
                    />
                  </MenuItem>
                  {stateNames.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          disabled={formik.values.states?.includes(
                            "Not Applicable"
                          )}
                          checked={selected.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="latestVersion">Edition Date</label>
                <MandatoryField />
                <input
                  id="latestVersion"
                  name="latestVersion"
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  value={formik.values.latestVersion}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  placeholder="Enter Edition Date"
                  autocomplete="off"
                  maxLength={25}
                  disabled={!isEditMode}
                />
                <InlineMessage error={formik.errors.latestVersion} />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="fillable">Fillable</label>
                <select
                  id="fillable"
                  name="fillable"
                  value={formik.values.fillable}
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  className="form-control form-select"
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED ||
                    formik.values.typeOfContent !== "2"
                  }
                >
                  {fillableOptions?.map((res) => {
                    return (
                      <option key={res.value} value={res.value}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>

              <Grid item xs={3} p={2}>
                <FormControlLabel
                  className={
                    !isEditMode
                      ? "content-checkbox-field form-label border-cus-cus form-control input-border top-pad cus-cus-fm border-cus-cus-disabled"
                      : "content-checkbox-field form-label border-cus-cus form-control input-border top-pad cus-cus-fm"
                  }
                  label={<Typography>Preview Available</Typography>}
                  checked={formik.values.isPreview}
                  name="Available Public"
                  data-testid="preview-available-checkbox"
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      isPreview: e.target.checked,
                    });
                    page.setDirty(true);
                  }}
                  control={<Checkbox disabled={!isEditMode} />}
                  labelPlacement="end"
                />
              </Grid>

              <Grid item xs={3} p={1}>
                <label htmlFor="previewType">Preview Available</label>
                <select
                  id="previewType"
                  name="previewType"
                  data-testid="preview-type"
                  value={formik.values.previewType}
                  onChange={(e) => {
                    page.setDirty(true);
                    formik.handleChange(e);
                  }}
                  className="form-label form-control input-border custom-sel-ect cus-cus-avl"
                  disabled={!isEditMode}
                >
                  <option>Select Preview Type</option>
                  {previewTypeOptions?.map((res) => {
                    return (
                      <option key={res.value} value={res.value}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="application" className="file-upload-label">
                  <span>Upload File </span>
                  {fileActionType()}
                </label>
                <FileUploader
                  accept={acceptFileType}
                  onSelectFile={handleFileChange}
                  onDeleteFile={onDeleteFileHandler}
                  maxSizeInBytes={maxSizeInBytes}
                  maxSizeExeedError={maxSizeExeedError}
                  disabled={
                    !isEditMode ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED ||
                    !acceptFileType
                  }
                  sourceFile={file}
                />
                {formik.values.filePath !== "" && (
                  <div>{formik.values.filePath}</div>
                )}
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="webLink">Web Link</label>
                {formik.values.typeOfContent === "11" && (
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                )}
                <input
                  id="webLink"
                  name="webLink"
                  onChange={(e) => {
                    formik.handleChange(e);
                    page.setDirty(true);
                  }}
                  value={formik.values.webLink || ""}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  placeholder="Enter Web Link"
                  autocomplete="off"
                  disabled={!isEditMode}
                />
                <InlineMessage error={formik.errors.webLink} />
              </Grid>
              {formik.values.reason && (
                <Grid item xs={3} p={2}>
                  <label htmlFor="reason">Reason for Deactivation</label>
                  <input
                    id="reason"
                    name="reason"
                    value={formik.values.reason}
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter reason"
                    autocomplete="off"
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={3} p={2}>
                <label htmlFor="createdDate">Created Date</label>
                <input
                  id="createdDate"
                  name="createdDate"
                  value={formatDate(formik.values.createdOn)}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="createdBy">Created By</label>
                <input
                  id="createdBy"
                  name="createdBy"
                  value={formik.values.createdByName}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="lastUpdatedDate">Last Updated Date</label>
                <input
                  id="updatedDate"
                  name="updatedDate"
                  value={formatDate(formik.values.updatedOn)}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={3} p={2}>
                <label htmlFor="lastUpdatedBy">Last Updated By</label>
                <input
                  id="updatedBy"
                  name="updatedBy"
                  value={formik.values.updatedByName}
                  className="form-label form-control border-cus-cus"
                  type="text"
                  disabled
                />
              </Grid>
              <Grid container spacing={2} p={2}>
                <Grid
                  item
                  xs={3}
                  p={2}
                  className="tag-input tag-input-search-keyword blue-field-section"
                >
                  <label htmlFor="keywords">Search Keywords</label>
                  <TagsInput
                    id="keywords"
                    name="keywords"
                    value={selectedKeywords}
                    onChange={(value) => {
                      formik.setValues({
                        ...formik.values,
                        keywords: value.join(),
                      });
                      page.setDirty(true);
                      setSelectedKeywords(value);
                    }}
                    placeHolder="Enter keyword(s) here. Use a comma or the enter key to add each additional keyword(s)."
                    className={!isEditMode && "disabled"}
                    disabled={!isEditMode}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  p={2}
                  className="tag-input blue-field-section"
                >
                  <label htmlFor="tages">Content Tags/Labels</label>
                  <div>
                    <TagsInput
                      id="tages"
                      name="tages"
                      value={selectedTags}
                      onChange={(value) => {
                        formik.setValues({
                          ...formik.values,
                          tages: value.join(),
                        });
                        page.setDirty(true);
                        setSelectedTags(value);
                      }}
                      placeHolder="Enter Content Tag/Label then a Comma OR Enter key to add each additional Tag/Label(s)"
                      disabled={!isEditMode}
                    />
                  </div>
                </Grid>
                <Grid item xs={3} p={2}>
                  <label htmlFor="marketingDescription">
                    Marketing Description
                  </label>
                  {formik.values.previewType == "3" && (
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  )}
                  <div className="textarea-container">
                    <textarea
                      id="marketingDescription"
                      name="marketingDescription"
                      onChange={(e) => {
                        formik.handleChange(e);
                        page.setDirty(true);
                      }}
                      value={formik.values.marketingDescription}
                      className="form-label form-control bs-cus scrollable-input-content"
                      type="text"
                      placeholder="Enter Marketing Description"
                      autoComplete="off"
                      disabled={!isEditMode}
                    />
                  </div>
                  <InlineMessage error={formik.errors.marketingDescription} />
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div>
            {auth.getRole() !== SystemUsersEnum.ENTERPRISE &&
              showEditAction && (
                <Grid container spacing={1}>
                  <Grid item xs={12} p={2}>
                    <Accordion className="accordionWrapper">
                      <AccordionSummary
                        className="accordion-content-account-information"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className="montserrat-16-600">
                          Subscription Details
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div
                            className="menu-bar"
                            style={{
                              display: "flex",
                              height: "100%",
                              marginLeft: "0",
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={3} p={2} mt={1}>
                                <label
                                  className="extra-padding-input montserrat-14-500 label-gap"
                                  htmlFor="totalEnrolledUsers"
                                >
                                  Total Enrolled Users
                                </label>
                                <input
                                  id="totalEnrolledUsers"
                                  name="totalEnrolledUsers"
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    page.setDirty(true);
                                  }}
                                  value={totalEnrolledUsers}
                                  className="form-label form-control input-border"
                                  type="text"
                                  placeholder="Enter Last Name"
                                  maxLength={25}
                                  disabled
                                />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                p={2}
                                mt={-1}
                                className="cus-cus-grid"
                              >
                                <FormControlLabel
                                  className="content-checkbox-field form-label form-control input-border cus-cus-fm"
                                  label={
                                    <Typography className="montserrat-14-500">
                                      Available to Public
                                    </Typography>
                                  }
                                  checked={formik.values.isPublic}
                                  name="Available Public"
                                  data-testid="available-public-checkbox"
                                  onChange={(e) => {
                                    formik.setValues({
                                      ...formik.values,
                                      isPublic: e.target.checked,
                                    });
                                    page.setDirty(true);
                                  }}
                                  control={<Checkbox disabled={!isEditMode} />}
                                  labelPlacement="end"
                                />
                              </Grid>
                              <Grid item xs={3} p={2} mt={1}>
                                <label
                                  className="extra-padding-input montserrat-14-500 label-gap"
                                  htmlFor="contentType"
                                >
                                  Content Type(Free/Paid)
                                </label>
                                <select
                                  id="contentType"
                                  name="contentType"
                                  value={formik.values.contentType || 1}
                                  onChange={(e) => {
                                    if (e.target.value === "2") {
                                      handleIndividualClick();
                                    }
                                    if (e.target.value === "1") {
                                      formik.setFieldValue(`subscriptions`, []);
                                    }
                                    page.setDirty(true);
                                    formik.handleChange(e);
                                  }}
                                  className="form-label form-control input-border custom-sel-ect cus-cus-avl"
                                  disabled={!isEditMode}
                                >
                                  {contentTypeOptions?.map((res) => {
                                    return (
                                      <option key={res.value} value={res.value}>
                                        {res.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </Grid>
                              <Grid item xs={3} p={2} mt={1}></Grid>
                              {Number(formik.values.contentType) === 2 && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    p={2}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Button
                                      onClick={() => {
                                        handleIndividualClick();
                                        clearFilterHandler();
                                        setActiveButton("individual");
                                      }}
                                      className={
                                        activeButton === "individual"
                                          ? "active-button"
                                          : "dis-active-button"
                                      }
                                    >
                                      <Typography
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Individual
                                      </Typography>
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        handleEnterpriseClick();
                                        clearFilterHandler();
                                        setActiveButton("enterprise");
                                      }}
                                      className={
                                        activeButton === "enterprise"
                                          ? "active-button"
                                          : "dis-active-button"
                                      }
                                    >
                                      <Typography
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Enterprise
                                      </Typography>
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <hr
                                      style={{
                                        width: "100%",
                                        border: "0.5px solid #e9ecef",
                                        marginTop: "-32px",
                                        marginBottom: "0px",
                                      }}
                                    />
                                  </Grid>
                                  {showIndividual && (
                                    <Grid
                                      item
                                      xs={3}
                                      p={2}
                                      mt={1}
                                      style={{ marginTop: "-36px" }}
                                    >
                                      <label
                                        className="extra-padding-input montserrat-14-500 label-gap"
                                        htmlFor="numberOfEnrolledUsers"
                                      >
                                        Enrolled Users
                                      </label>
                                      <input
                                        id="numberOfEnrolledUsers"
                                        name="numberOfEnrolledUsers"
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          page.setDirty(true);
                                        }}
                                        value={
                                          formik.values.numberOfEnrolledUser
                                        }
                                        className="form-label form-control input-border"
                                        type="text"
                                        placeholder="Enter Last Name"
                                        maxLength={25}
                                        disabled
                                      />
                                    </Grid>
                                  )}

                                  {showIndividual && (
                                    <Grid item xs={0} p={2} mt={1}></Grid>
                                  )}
                                  {showIndividual && (
                                    <>
                                      <Grid item xs={8} p={2} mt={1}>
                                        <Box>
                                          <div className=" filter-icon-subs">
                                            <div>
                                              <div className="welcome-widgets">
                                                <Popover
                                                  anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                  }}
                                                  transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                  }}
                                                  id={"simple-popover"}
                                                  open={Boolean(anchorEl)}
                                                  anchorEl={anchorEl}
                                                  onClose={() => {
                                                    handleClose();
                                                  }}
                                                >
                                                  <div className="expanded-filter">
                                                    <div className="filter-options">
                                                      <List
                                                        sx={{
                                                          width: "100%",
                                                          maxWidth: 360,
                                                          bgcolor:
                                                            "background.paper",
                                                        }}
                                                        component="nav"
                                                        aria-labelledby="nested-list-subheader"
                                                        subheader={
                                                          <ListSubheader
                                                            component="div"
                                                            id="nested-list-subheader"
                                                          >
                                                            Filters will be
                                                            applied to your grid
                                                          </ListSubheader>
                                                        }
                                                      >
                                                        <Accordion
                                                          expanded={
                                                            expandedFilter ===
                                                            "status"
                                                          }
                                                          onChange={filterAccordianHandleChange(
                                                            "status"
                                                          )}
                                                        >
                                                          <AccordionSummary
                                                            expandIcon={
                                                              <ExpandMoreIcon />
                                                            }
                                                            onChange={handleChange(
                                                              "panel1"
                                                            )}
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                          >
                                                            <Typography
                                                              sx={{
                                                                flexShrink: 0,
                                                              }}
                                                            >
                                                              Status
                                                            </Typography>
                                                          </AccordionSummary>
                                                          <AccordionDetails>
                                                            <CheckboxList
                                                              id="status"
                                                              options={
                                                                subscriptionStatusFilters
                                                              }
                                                              onChange={
                                                                onFilterStatusChangeHandler
                                                              }
                                                              selectedValues={
                                                                selectedStatus
                                                              }
                                                            />
                                                          </AccordionDetails>
                                                        </Accordion>
                                                        <Accordion
                                                          expanded={
                                                            expandedFilter ===
                                                            "frequency"
                                                          }
                                                          onChange={filterAccordianHandleChange(
                                                            "frequency"
                                                          )}
                                                        >
                                                          <AccordionSummary
                                                            expandIcon={
                                                              <ExpandMoreIcon />
                                                            }
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                          >
                                                            <Typography
                                                              sx={{
                                                                flexShrink: 0,
                                                              }}
                                                            >
                                                              Frequency
                                                            </Typography>
                                                          </AccordionSummary>
                                                          <AccordionDetails>
                                                            <CheckboxList
                                                              id="frequency"
                                                              options={
                                                                frequencyOptionsIndividual
                                                              }
                                                              onChange={
                                                                onFilterFreqChangeHandler
                                                              }
                                                              selectedValues={
                                                                selectedFreq
                                                              }
                                                            />
                                                          </AccordionDetails>
                                                        </Accordion>
                                                      </List>
                                                    </div>
                                                    <div className="modal-footer">
                                                      <Button
                                                        variant="outlined"
                                                        className="action-button mr-4"
                                                        onClick={
                                                          clearFilterHandler
                                                        }
                                                      >
                                                        Clear
                                                      </Button>
                                                      <Button
                                                        variant="contained"
                                                        className="action-button"
                                                        onClick={() => {
                                                          applyFilterHandler();
                                                          handleClose();
                                                        }}
                                                      >
                                                        Apply
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </Popover>

                                                <Tooltip
                                                  placement="bottom"
                                                  title="Filter"
                                                >
                                                  <FilterAltOutlinedIcon
                                                    onClick={handleClick}
                                                    title="filter-icon"
                                                  />
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </div>
                                        </Box>
                                      </Grid>
                                    </>
                                  )}

                                  {!showIndividual && (
                                    <Grid
                                      item
                                      xs={3}
                                      p={2}
                                      mt={1}
                                      style={{ marginTop: "-36px" }}
                                    >
                                      <label
                                        className="extra-padding-input montserrat-14-500 label-gap"
                                        htmlFor="egEnrolledUser"
                                      >
                                        Enrolled Users
                                      </label>
                                      <input
                                        id="egEnrolledUser"
                                        name="egEnrolledUser"
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          page.setDirty(true);
                                        }}
                                        value={formik.values.egEnrolledUser}
                                        className="form-label form-control input-border"
                                        type="text"
                                        placeholder="Enter Last Name"
                                        maxLength={25}
                                        disabled
                                      />
                                    </Grid>
                                  )}

                                  {!showIndividual && (
                                    <Grid item xs={0} p={2} mt={1}></Grid>
                                  )}
                                  {!showIndividual && (
                                    <Grid item xs={8} p={2} mt={1}>
                                      <div className=" filter-icon-subs">
                                        <div className="welcome-widgets">
                                          <Popover
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "right",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "right",
                                            }}
                                            id={"simple-popover"}
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            onClose={() => {
                                              handleClose();
                                            }}
                                          >
                                            <div className="expanded-filter">
                                              <div className="filter-options">
                                                <List
                                                  sx={{
                                                    width: "100%",
                                                    maxWidth: 360,
                                                    bgcolor: "background.paper",
                                                  }}
                                                  component="nav"
                                                  aria-labelledby="nested-list-subheader"
                                                  subheader={
                                                    <ListSubheader
                                                      component="div"
                                                      id="nested-list-subheader"
                                                    >
                                                      Filters will be applied to
                                                      your grid
                                                    </ListSubheader>
                                                  }
                                                >
                                                  <Accordion
                                                    expanded={
                                                      expandedFilter ===
                                                      "status"
                                                    }
                                                    onChange={filterAccordianHandleChange(
                                                      "status"
                                                    )}
                                                  >
                                                    <AccordionSummary
                                                      expandIcon={
                                                        <ExpandMoreIcon />
                                                      }
                                                      onChange={handleChange(
                                                        "panel1"
                                                      )}
                                                      aria-controls="panel1bh-content"
                                                      id="panel1bh-header"
                                                    >
                                                      <Typography
                                                        sx={{
                                                          flexShrink: 0,
                                                        }}
                                                      >
                                                        Status
                                                      </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                      <CheckboxList
                                                        id="status"
                                                        options={
                                                          subscriptionStatusFilters
                                                        }
                                                        onChange={
                                                          onFilterStatusChangeHandler
                                                        }
                                                        selectedValues={
                                                          selectedStatus
                                                        }
                                                      />
                                                    </AccordionDetails>
                                                  </Accordion>
                                                  <Accordion
                                                    expanded={
                                                      expandedFilter ===
                                                      "frequency"
                                                    }
                                                    onChange={filterAccordianHandleChange(
                                                      "frequency"
                                                    )}
                                                  >
                                                    <AccordionSummary
                                                      expandIcon={
                                                        <ExpandMoreIcon />
                                                      }
                                                      aria-controls="panel1bh-content"
                                                      id="panel1bh-header"
                                                    >
                                                      <Typography
                                                        sx={{
                                                          flexShrink: 0,
                                                        }}
                                                      >
                                                        Frequency
                                                      </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                      <CheckboxList
                                                        id="frequency"
                                                        options={
                                                          frequencyOptions
                                                        }
                                                        onChange={
                                                          onFilterFreqChangeHandler
                                                        }
                                                        selectedValues={
                                                          selectedFreq
                                                        }
                                                      />
                                                    </AccordionDetails>
                                                  </Accordion>
                                                </List>
                                              </div>
                                              <div className="modal-footer">
                                                <Button
                                                  variant="outlined"
                                                  className="action-button mr-4"
                                                  onClick={clearFilterHandler}
                                                >
                                                  Clear
                                                </Button>
                                                <Button
                                                  variant="contained"
                                                  className="action-button"
                                                  onClick={() => {
                                                    applyFilterHandler();
                                                    handleClose();
                                                  }}
                                                >
                                                  Apply
                                                </Button>
                                              </div>
                                            </div>
                                          </Popover>

                                          <Tooltip
                                            placement="bottom"
                                            title="Filter"
                                          >
                                            <FilterAltOutlinedIcon
                                              onClick={handleClick}
                                              title="filter-icon"
                                            />
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </Grid>
                                  )}
                                  {showIndividual && (
                                    <Grid item xs={12} p={0}>
                                      {formik.values.subscriptions &&
                                        formik.values.subscriptions?.map(
                                          (item, index) => {
                                            let subs = [
                                              ...formik.values.subscriptions,
                                            ];
                                            subs = subs.reverse();
                                            let revIndex =
                                              subs.length -
                                              1 -
                                              subs.findIndex((x) =>
                                                filterSubs(x, true, filterQuery)
                                              );
                                            return renderSubscriptionItem(
                                              item,
                                              index,
                                              revIndex,
                                              true
                                            );
                                          }
                                        )}
                                    </Grid>
                                  )}
                                  {!showIndividual && (
                                    <Grid item xs={12} p={0}>
                                      {formik.values.subscriptions &&
                                        formik.values.subscriptions?.map(
                                          (item, index) => {
                                            let subs = [
                                              ...formik.values.subscriptions,
                                            ];
                                            subs = subs.reverse();
                                            let revIndex =
                                              subs.length -
                                              1 -
                                              subs.findIndex((x) =>
                                                filterSubs(
                                                  x,
                                                  false,
                                                  filterQuery
                                                )
                                              );
                                            return renderSubscriptionItemEnterprise(
                                              item,
                                              index,
                                              revIndex,
                                              false
                                            );
                                          }
                                        )}
                                    </Grid>
                                  )}
                                </>
                              )}
                            </Grid>
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              )}
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index="ContentPreview">
          <Grid display={"flex"} justifyContent={"space-between"}>
            <div className="welcome-main">
              <div className="welcome-message">
                <h5>Content Preview</h5>
              </div>
            </div>

            <div className="button-container">
              <label htmlFor="application">
                <span>
                  <p>{fileActionType()}</p>
                  <FileUploader
                    accept={acceptFileType}
                    onSelectFile={handleFileChange}
                    onDeleteFile={onDeleteFileHandler}
                    maxSizeInBytes={maxSizeInBytes}
                    maxSizeExeedError={maxSizeExeedError}
                    disabled={
                      !isEditMode ||
                      formik.values.publishStatus ===
                        ContentPublishStatus.PUBLISHED ||
                      !acceptFileType
                    }
                    sourceFile={file}
                  />
                </span>
                {formik.values.filePath !== "" && (
                  <div className="text-cus">{formik.values.filePath}</div>
                )}
              </label>
              <div className="button-container">
                <Button
                  variant="contained"
                  type="submit"
                  className="action-button"
                  disabled={
                    !isEditMode ||
                    !file ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                  onClick={uploadContent}
                >
                  Upload
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="action-button"
                  disabled={
                    !(
                      isEditMode &&
                      (selectedDocument.length > 0 || fileContent)
                    ) ||
                    formik.values.publishStatus ===
                      ContentPublishStatus.PUBLISHED
                  }
                  onClick={() => {
                    handleConfirmationOpen("Delete", confirmationNoDocAttached);
                  }}
                >
                  Delete
                </Button>
                <Button
                  variant="outlined"
                  className="action-button"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </div>
              <Confirmation
                open={confirmation}
                handleClose={handleConfirmationClose}
                title={confirmationMessage}
                cancelButtonLabel="Cancel"
                confirmButtonLabel="Delete"
                onConfirm={async (e) => {
                  deleteContent(e);
                  handleConfirmationClose();
                }}
                onCancel={() => {
                  handleConfirmationClose();
                }}
              />
            </div>
          </Grid>
          <div
            className="menu-bar"
            style={{
              display: "flex",
              height: "100%",
              paddingTop: "3rem",
            }}
          >
            <Grid container>
              <Grid item xs={12} pl={2} pr={2}></Grid>
              <Grid item xs={12} pl={2} pr={2}>
                {formik.values.typeOfContent === "7" && (
                  <AudioPreview
                    selectedDocument={selectedDocument}
                    fileName={formik.values.filePath}
                  />
                )}
                {formik.values.typeOfContent === "10" && (
                  <VideoPreview
                    selectedDocument={selectedDocument}
                    fileName={formik.values.filePath}
                  />
                )}
                {loadDocumentViewer()}
              </Grid>
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index="ManageSubscribers">
          <ManageSubscriber
            contentId={contentId}
            contentName={formik.values.name}
            bundleSubscriberStatus={formik.values.status}
            userId={formik.values.userId}
            id={id}
            from="content"
            permissions={permissionss}
          />
        </TabPanel>
      </div>
    </div>
  );

  function formDataChangesSecond(formData, status, actionName) {
    formData.append("publishContentId", formik.values.publishContentId || 0);
    formData.append("publishStatus", status || 0);
    formData.append("isEditAction", actionName === "Publish" ? false : true);
    let subs = formik.values.subscriptions;
    subs.forEach((record) => {
      if (!record.isIndividual && (record.effectiveToDate ? false : true)) {
        record.effectiveToDate = getMaxEToDateEG(entSubsTypes, record) || null;
      }
    });
    formData.append(
      "subscriptions",
      JSON.stringify(formik.values.subscriptions)
    );
  }

  async function apiCall(formData) {
    return contentId === 0 || contentContext.copy
      ? await ApiServices.httpPostFile(CONTENT, formData)
      : await ApiServices.httpPutFile(CONTENT, formData);
  }

  function handleStatusOnAction(actionName, publishStatus, status) {
    if (actionName === "Publish") {
      if (publishStatus === ContentPublishStatus.PUBLISHED) {
        status = ContentPublishStatus.MODIFIED;
      } else if (
        !publishStatus ||
        publishStatus === ContentPublishStatus.DRAFT
      ) {
        status = ContentPublishStatus.PUBLISHED;
      }
    } else {
      status = publishStatus || ContentPublishStatus.DRAFT;
    }
    return status;
  }

  function contentFormData(formData) {
    if (contentId > 0) {
      formData.append("contentId", contentId);
    } else if (contentContext.copy) {
      formData.append("contentId", contentContext.sourceContentId);
    }
  }

  function formDataChangesOne(formData, statePayload) {
    formData.append("typeOfContent", Number(formik.values.typeOfContent));
    formData.append("name", formik.values.name);
    formData.append("formNumber", formik.values.formNumber || "");
    formData.append("contentOriginator", formik.values.contentOriginator || "");
    formData.append("userId", formik.values.userId);
    formData.append("Keywords", formik.values.keywords);
    formData.append("tages", formik.values.tages || "");
    formData.append("countryId", Number(formik.values.countryId || 1));
    formData.append("states", JSON.stringify(statePayload));
    formData.append("latestVersion", formik.values.latestVersion);
    formData.append(
      "marketingDescription",
      formik.values.marketingDescription || ""
    );
    formData.append("webLink", formik.values.webLink || "");
    formData.append("reason", formik.values.reason);
    formData.append("fillable", Number(formik.values.fillable) || 1);
    formData.append("isFavorite", false);
  }

  function fillFormikDetails(response, savedStates) {
    return {
      ...formik.values,
      contentId: response.result.contentId,
      typeOfContent: String(response.result.typeOfContent),
      name: response.result.name,
      formNumber: response.result.formNumber,
      contentOriginator: response.result.contentOriginator || "",
      userId: response.result.userId,
      typeOfFormList: response.result.typeOfFormList.map((item) => {
        return { value: item.typeOfFormId, label: item.name };
      }),
      lineOfBusinessList: response.result.lineOfBusinessList.map((item) => {
        return { value: item.lineOfBusinessId, label: item.name };
      }),
      countryId: response.result.countryId[1],
      states: savedStates,
      marketingDescription: response.result.marketingDescription || "",
      webLink: response.result.webLink || "",
      reason: response.result.reason,
      latestVersion: response.result.latestVersion,
      keywords: response.result.contentKeywordList
        .map((item) => item.keyword)
        .join(","),
      tages: response.result.contentTagList.map((item) => item.tag).join(","),
      fillable: response.result.fillable || 1,
      file: response.result.file,
      filePath: response.result.filePath,
      fileStream: response.result.fileStream,
      isFileDeleted: response.result.filePath?.length === 0,
      numberOfEnrolledUser: contentContext.copy
        ? 0
        : response.result.numberOfEnrolledUser || 0,
      egEnrolledUser: contentContext.copy
        ? 0
        : response.result.egEnrolledUser || 0,
      isPublic: response.result.isPublic,
      isPreview: response.result.isPreview,
      isDisplayPublic: response.result.isDisplayPublic,
      publishStatus: contentContext.copy
        ? false
        : response.result.publishStatus,
      publishContentId: response.result.publishContentId || 0,
      contentType: contentContext.copy ? 1 : response.result.contentType,
      previewType: String(response.result.previewType),
      createdOn: contentContext.copy
        ? new Date().toISOString()
        : response.result.createdOn,
      updatedOn: contentContext.copy ? null : response.result.updatedOn,
      createdByName: contentContext.copy ? null : response.result.createdByName,
      updatedByName: contentContext.copy ? null : response.result.updatedByName,
      mode: mode,
      status: response.result.status,
      subscriptions: FillFormikSubscriptions(response),
    };
  }

  function FillFormikSubscriptions(response) {
    return contentContext.copy
      ? []
      : response.result.subscriptions?.map((item) => {
          return {
            distributorSubscriptionId: item.distributorSubscriptionId,
            distributorSubscriptionPlanId: item.distributorSubscriptionPlanId,
            baseValue: item.baseValue?.toFixed(2),
            oneTimeCost: item.oneTimeCost?.toFixed(2),
            isRecurring: item.isRecurring,
            frequency: item.frequency,
            isIndividual: item.isIndividual,
            effectiveFromDate: item.effectiveFromDate,
            effectiveToDate: item.effectiveToDate,
            savedEffectiveToDate: item.effectiveToDate,
            uid: Math.floor(Math.random() * 1000),
            contentSubscriptionId: item.contentSubscriptionId,
            status: item.status,
            isAssociated: item.isAssociated,
          };
        });
  }

  function msContent(response) {
    if (
      response.result.typeOfContent === 4 ||
      response.result.typeOfContent === 5 ||
      response.result.typeOfContent === 6
    ) {
      if (response.result.gcpFilePath) {
        setSelectedDocument([
          {
            uri: response.result.gcpFilePath,
            fileName: response.result.filePath,
            fileType: typeOfContentOptions?.find(
              (item) => item.value === response.result.typeOfContent
            )?.fileType,
          },
        ]);
      } else {
        setSelectedDocument([]);
      }
    }
  }

  function streamContent(response) {
    if (
      response.result.typeOfContent === 1 ||
      response.result.typeOfContent === 8 ||
      response.result.typeOfContent === 9 ||
      response.result.typeOfContent === 3 ||
      response.result.typeOfContent === 7 || // For Audio file
      response.result.typeOfContent === 10 // For Video file
    ) {
      if (response.result.fileStream) {
        setSelectedDocument([
          {
            uri: response.result.fileStream,
            fileName: response.result.filePath,
            fileType: typeOfContentOptions?.find(
              (item) => item.value === response.result.typeOfContent
            )?.fileType,
          },
        ]);
      } else {
        setSelectedDocument([]);
      }
    }
  }

  function xfdlContent(response) {
    if (response.result.typeOfContent === 2) {
      if (response.result.xfdlJson) {
        setFileContent(JSON.parse(response.result.xfdlJson));
      } else {
        setFileContent(null);
      }
    }
  }

  function fileActionType() {
    return file || formik.values.filePath ? (
      <span className="filename">Replace File</span>
    ) : (
      <span className="filename">Choose File</span>
    );
  }

  function loadDocumentViewer() {
    const whenNotDocument = fileContent ? (
      <XFDLViewer
        document={fileContent}
        fileName={formik.values.filePath}
        from="preview"
      />
    ) : (
      !show &&
      formik.values.fileStream === null && (
        <div className="no-preview">
          <img
            src="/images/no-preview-available.png"
            alt="No document attached to preview"
          />
        </div>
      )
    );
    return allowedContentTypes.includes(Number(formik.values.typeOfContent)) &&
      selectedDocument.length > 0 ? (
      <DocumentViewer
        disableHeader={true}
        tempId={Math.floor(Math.random() * 1000 + 1)} // Just for rerendering
        documents={selectedDocument}
        config={{
          noRenderer: {
            overrideComponent: <div>No Document uploaded</div>,
          },
        }}
      />
    ) : (
      whenNotDocument
    );
  }

  function disablePublishCondition() {
    return (
      (signUpApproval?.approvalId > 0 && signUpApproval?.status !== 1) ||
      !formik.isValid ||
      !isEditMode ||
      (formik.values.typeOfContent !== TypeofConstantENUM.WEBLINK &&
        !file &&
        !formik.values.filePath) ||
      (formik.values.typeOfContent === TypeofConstantENUM.WEBLINK &&
        formik.values.webLink === "") ||
      getDateAlreadyExistsValidation()
    );
  }
  async function loadDistSubscriptions() {
    if (distributorUserIdOfContent || !contentId > 0) {
      const response2 = await ApiServices.httpGet(
        `${DISTRIBUTOR_SUBSCROPTIONTYPES}/${
          contentId > 0 ? distributorUserIdOfContent : id
        }`
      );
      setEntSubsTypes(response2.result);
      setSubscriptionOptions(
        response2?.result?.map((r) => {
          return {
            value: r.distributorSubscriptionId,
            label: r.name,
          };
        }) || []
      );
    }
  }
}

export default AddEditContent;
/*istanbul ignore next */
function fromDateValidationMessage(
  formik,
  index,
  effFromDateRangeExists,
  isEffFromDateExeeds
) {
  return (
    (formik.errors?.subscriptions?.length > 0 &&
      index < formik.errors?.subscriptions?.length &&
      formik.errors?.subscriptions[index]?.effectiveFromDate) ||
    (effFromDateRangeExists &&
      "Date already exists for this frequency. Please enter valid date") ||
    (isEffFromDateExeeds &&
      "Date must be less than or equal to the subscription's Effective To date.")
  );
}

/*istanbul ignore next */
function getMaxEToDateEG(entSubsTypes, item) {
  return (
    entSubsTypes
      .find(
        (x) => x.distributorSubscriptionId == item.distributorSubscriptionId
      )
      ?.subscriptionPlan.find((x) => x.frequency == item.frequency)
      ?.effectiveToDate || undefined
  );
}

/*istanbul ignore next */
function renderEntFreq(entSubsTypes, item, formik, FREQUENCYENUM) {
  return entSubsTypes
    .find((x) => x.distributorSubscriptionId == item?.distributorSubscriptionId)
    ?.subscriptionPlan?.filter((x) => {
      let filteredValues =
        item.contentSubscriptionId > 0 ||
        formik.values.subscriptions?.filter(
          (y) =>
            y.frequency === x.frequency &&
            !y.isIndividual &&
            y.distributorSubscriptionId === item.distributorSubscriptionId &&
            y.uid !== item.uid &&
            (!y.effectiveToDate ||
              moment(y.effectiveToDate) >
                moment(new Date().setHours(0, 0, 0, 0)))
        );

      return (
        item.contentSubscriptionId > 0 ||
        (x.status > 0 &&
          filteredValues.length < 2 &&
          filteredValues.filter((x) => !x.contentSubscriptionId > 0)?.length <
            1)
      );
    })
    ?.map((res) => {
      return (
        <option
          key={res.frequency}
          value={res.frequency}
          actualCost={res.baseValue}
        >
          {FREQUENCYENUM[res.frequency]}
        </option>
      );
    });
}

/*istanbul ignore next */
function EToDateValidationMessage(formik, index, effToDateRangeExists) {
  return (
    (formik.errors?.subscriptions?.length > 0 &&
      index < formik.errors?.subscriptions?.length &&
      formik.errors?.subscriptions[index]?.effectiveToDate) ||
    (effToDateRangeExists &&
      "Date already exists for this frequency. Please enter valid date")
  );
}
/*istanbul ignore next */
function disableEToDate(isEditMode, item, subs) {
  return (
    !isEditMode ||
    !item.effectiveFromDate ||
    !item.frequency ||
    subs.find(
      (x) =>
        !x.contentSubscriptionId > 0 &&
        item.contentSubscriptionId > 0 &&
        x.frequency === item.frequency
    ) ||
    (item.contentSubscriptionId > 0 && item.savedEffectiveToDate)
  );
}
/*istanbul ignore next */
function effFDateValidationMessage(formik, index, effFromDateRangeExists) {
  return (
    (formik.errors?.subscriptions?.length > 0 &&
      index < formik.errors?.subscriptions?.length &&
      formik.errors?.subscriptions[index]?.effectiveFromDate) ||
    (effFromDateRangeExists &&
      "Date already exists for this frequency. Please enter valid date")
  );
}
/*istanbul ignore next */
function loadFilterOptions(frequencyOptions, item, formik) {
  return frequencyOptions
    ?.filter((x) => {
      let filteredValues =
        item.contentSubscriptionId > 0 ||
        formik.values.subscriptions?.filter(
          (y) =>
            y.frequency === x.value &&
            y.isIndividual &&
            y.distributorSubscriptionId === item.distributorSubscriptionId &&
            y.uid !== item.uid &&
            (!y.effectiveToDate ||
              moment(y.effectiveToDate) >
                moment(new Date().setHours(0, 0, 0, 0)))
        );
      return (
        item.contentSubscriptionId > 0 ||
        (filteredValues.length < 2 &&
          filteredValues.filter((x) => !x.contentSubscriptionId > 0)?.length <
            1)
      );
    })
    ?.map((res) => {
      return (
        <option key={res.value} value={res.value}>
          {res.label}
        </option>
      );
    });
}
/*istanbul ignore next */
function baseValueValidationMessage(formik, index) {
  return formik.errors &&
    formik.errors.subscriptions &&
    formik.errors.subscriptions[index]
    ? formik.errors.subscriptions[index].baseValue
    : "";
}
/*istanbul ignore next */
function filterSubs(item, isIndi, filterQuery) {
  return (
    item.isIndividual === isIndi &&
    ((filterQuery.status.includes(item.status) &&
      filterQuery.frequency.includes(item.frequency)) ||
      !item.contentSubscriptionId > 0)
  );
}
/*istanbul ignore next */
function effDateRange(item, minFromDate, entSubsTypes) {
  let isEffFromDateExeeds = false;
  let isEffToDateExeeds = false;
  let effFromDateRangeExists =
    !item.contentSubscriptionId > 0 &&
    item.effectiveFromDate &&
    new Date(item.effectiveFromDate.replace(/-/g, "/").replace(/T.+/, "")) <
      new Date(
        new Date(Math.max(minFromDate, new Date())).setHours(0, 0, 0, 0)
      );
  let effToDateRangeExists =
    !item.contentSubscriptionId > 0 &&
    item.effectiveToDate &&
    new Date(item.effectiveToDate.replace(/-/g, "/").replace(/T.+/, "")) <
      new Date(
        new Date(Math.max(minFromDate, new Date())).setHours(0, 0, 0, 0)
      );

  if (!item.isIndividual && entSubsTypes && entSubsTypes.length > 0) {
    let maxEDate = getMaxEToDateEG(entSubsTypes, item);
    isEffFromDateExeeds =
      !item.contentSubscriptionId > 0 &&
      item.effectiveFromDate &&
      new Date(
        item.effectiveFromDate.replace(/-/g, "/").replace(/T.+/, "")
      ).setHours(0, 0, 0, 0) > new Date(maxEDate);
    isEffToDateExeeds =
      !item.contentSubscriptionId > 0 &&
      item.effectiveToDate &&
      new Date(
        item.effectiveToDate.replace(/-/g, "/").replace(/T.+/, "")
      ).setHours(0, 0, 0, 0) > new Date(maxEDate);
  }

  return {
    effFromDateRangeExists,
    effToDateRangeExists,
    isEffFromDateExeeds,
    isEffToDateExeeds,
  };
}
/*istanbul ignore next */
function getMinFromAndToDate(item, subs) {
  let minFromDate = new Date(
    Math.max(
      null,
      ...subs
        ?.filter(
          (x) =>
            x.distributorSubscriptionId === item.distributorSubscriptionId &&
            x.frequency === item.frequency &&
            x.uid !== item.uid
        )
        ?.map((e) => new Date(e.effectiveFromDate)),
      ...subs
        ?.filter(
          (x) =>
            x.distributorSubscriptionId === item.distributorSubscriptionId &&
            x.frequency === item.frequency &&
            x.uid !== item.uid
        )
        ?.map((e) => new Date(e.effectiveToDate))
    )
  );
  let minToDate = new Date(item.effectiveFromDate);
  minFromDate.setDate(minFromDate.getDate() + 1);
  minToDate.setDate(minToDate.getDate());
  return { minFromDate, minToDate };
}
