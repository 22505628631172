import {
  ENTERPRISE_GROUP_USER,
  INTERNAL_USERS,
  ADD_EDIT_INTERNAL_USERS,
  INTERNAL_USER_CHANGE_STATUS,
  ENTERPRISE_GROUP_EXPORT,
  INTERNAL_USERS_EXPORT,
  CONTENT_EXPORT,
  GET_ASSOCIATE_DISTRIBITORS,
  GET_SUBSCRIPTION_TYPE,
  SUBSCRIPTION_TYPE,
  SEND_ASSOCIATE_DIST_APPROVAL,
  BUY_SUBSCRIPTION_ENTERPRISE,
  CATALOG_EXPORT,
  BUNDLE_EXPORT,
  ENTERPRISE_GROUP_USER_RENEW,
  CATALOG_ASSOCIATED_CONTENT_EXPORT,
  BUNDLE_ASSOCIATED_CONTENT_EXPORT,
} from "../../constants/api-constants";
import ApiServices from "../ApiServices";
import { IUserService } from "./IUserService";

export class EnterpriseGroupUserService extends IUserService {
  getUsers = async (query) => {
    const response = await ApiServices.httpGet(query);
    return response;
  };
  getUser = async (userId) => {
    const response = await ApiServices.httpGet(
      `${ENTERPRISE_GROUP_USER}/${userId}`
    );
    return {
      ...response.result,
      userId: response.result.userId,
      userTypeId: response.result.userTypeId,
      userTypeName: response.result.userTypeName,
      systemGeneratedId: response.result.systemGeneratedId,
      createdOn: response.result.createdOn,
      firstName: response.result.firstName,
      lastName: response.result.lastName,
      primaryEmail: response.result.email,
      secondaryEmail: response.result.secondaryEmail,
      phoneNumber: response.result.phoneNumber,
      brandName: response.result.brandName,
      secondaryPhoneNumber: response.result.secondaryPhoneNumber,
      businessName: response.result.businessName,
      taxID: response.result.taxID,
      fax: response.result.fax,
      mobile: response.result.mobile,
      fax1: response.result.fax1,
      mobile1: response.result.mobile1,
      distributorType: response.result.enterpriseTypes.map((data) => {
        return {
          label: data.name,
          value: data.enterpriseTypeId,
        };
      }),
      areas: response.result.areas.map((data) => {
        return {
          label: data.name,
          value: data.areaId,
        };
      }),
      carrierRepresents: response.result.carrierRepresents.map((data) => {
        return {
          label: data.name,
          value: data.carrierRepresentId,
        };
      }),
      industryGroups: response.result.industryGroups.map((data) => {
        return {
          label: data.name,
          value: data.industryGroupId,
        };
      }),
      technologies: response.result.technologies.map((data) => {
        return {
          label: data.name,
          value: data.technologyId,
        };
      }),
      parentUser: {
        parentUserId: response.result.parentUser.parentUserId || 0,
        permissions: response.result.parentUser?.permissions,
      },
      annualRevenue: response.result.annualRevenueId,
      annualRevenueText: response.result.annualRevenueText,
      billingContact: response.result.billingContact,
      billingContactLastName: response.result.billingContactLastName,
      businessYear: response.result.businessYear || "",
      websiteUrl: response.result.websiteUrl,
      numberOfEmployees: response.result.numberOfEmployees || "",
      numberOfOwners: response.result.numberOfOwners || "",
      producers: response.result.producers || "",
      billingPhoneNumber: response.result.billingPhoneNumber,
      billingAddressLine1: response.result.billingAddressLine1,
      billingAddressLine2: response.result.billingAddressLine2,
      zip: response.result.zipCode,
      city: response.result.city,
      stateId: response.result.stateId,
      countryId: response.result.countryId,
      id: response.result.userId,
      status: response.result.status,
      associateDistributors: response.result.associateDistributors,
      roles: response.result.roles,
      logoGcpPath: response.result.logoGcpPath,
      logoGcpFileUrl: response.result.logoGcpFileUrl,
      signUpApproval: response.result.signUpApproval,
    };
  };

  createUser = async (values) => {
    const payload = {
      ...values,
      status: 1,
      userId: 0,
      enterpriseGroupId: 0,
      distributorUserId: 0,
      userTypeId: 4,
      firstName: this.getOrDefaultValue(values.firstName),
      lastName: this.getOrDefaultValue(values.lastName),
      brandName: this.getOrDefaultValue(values.brandName),
      email: this.getOrDefaultValue(values.primaryEmail),
      secondaryEmail: this.getOrDefaultValue(values.secondaryEmail),
      phoneNumber: this.getOrDefaultValue(values.phoneNumber),
      secondaryPhoneNumber: this.getOrDefaultValue(values.secondaryPhoneNumber),
      billingContact: this.getOrDefaultValue(values.billingContact),
      billingContactLastName: values.billingContactLastName,
      billingPhoneNumber: this.getOrDefaultValue(values.billingPhoneNumber),
      billingAddressLine1: this.getOrDefaultValue(values.billingAddressLine1),
      billingAddressLine2: this.getOrDefaultValue(values.billingAddressLine2),
      zipCode: this.getOrDefaultValue(values.zip),
      countryId: values.countryId ? Number(values.countryId) : null,
      city: this.getOrDefaultValue(values.city),
      stateId: values.stateId ? Number(values.stateId) : null,
      businessYear: this.getOrDefaultValue(values.businessYear),
      websiteUrl: this.getOrDefaultValue(values.websiteUrl),
      annualRevenueId: Number(values.annualRevenue),
      numberOfEmployees: values.numberOfEmployees,
      numberOfOwners: values.numberOfOwners,
      producers: values.producers,
      userTypeName: values.userTypeName,
      businessName: values.businessName,
      taxID: values.taxID,
      mobile: values.mobile,
      fax1: values.fax1,
      mobile1: values.mobile1,
      fax: values.fax,
      roles: values.roles,
      areas: values.areas.map((res) => {
        return {
          areaId: res.value,
          name: res.label,
        };
      }),
      carrierRepresents: values.carrierRepresents.map((res) => {
        return {
          carrierRepresentId: res.value,
          name: res.label,
        };
      }),
      industryGroups: values.industryGroups.map((res) => {
        return {
          industryGroupId: res.value,
          name: res.label,
        };
      }),
      technologies: values.technologies.map((res) => {
        return {
          technologyId: res.value,
          name: res.label,
        };
      }),
      parentUser: {
        parentUserId: values.parentUser.parentUserId || 0,
        permissions: values.parentUser?.permissions,
      },
      enterpriseTypes: values.distributorType.map((res) => {
        return {
          enterpriseTypeId: Number(res.value),
          name: res.label,
        };
      }),
      associateDistributors:
        values.associateDistributors &&
        values.associateDistributors.map((res) => {
          return {
            distributorUserId: res.distributorUserId.value,
            associationId: res.associationId,
            frequencyType: res.frequencyType,
            isRecurring: res.isRecurring,
            isNewAssociation: res.isNewAssociation,
            expirationDate: res.expirationDate ? res.expirationDate : null,
            distributorName: res.distributorUserId.label,
            distributorSubscriptionId: res.distributorSubscriptionId,
            distributorSubscriptionPlanId: res.distributorSubscriptionPlanId,
            requestId: res.requestId,
            enterpriseUserId: res.enterpriseUserId,
            associatonStatus: res.associatonStatus,
          };
        }),
      logoGcpPath: values.logoGcpPath,
      logoGcpFileUrl: values.logoGcpFileUrl,
    };

    const response = await ApiServices.httpPost(ENTERPRISE_GROUP_USER, payload);
    if (response && response.result && response.result.associateDistributors) {
      response.result.associateDistributors =
        response.result.associateDistributors.map((res) => {
          return {
            distributorUserId: {
              value: res.distributorUserId,
              label: res.distributorName,
            },
            associationId: res.associationId,
            frequencyType: res.frequencyType,
            isNewAssociation: res.isNewAssociation,
            isRecurring: res.isRecurring,
            expirationDate: res.expirationDate,
            distributorSubscriptionId: res.distributorSubscriptionId,
            requestId: res.requestId,
            enterpriseUserId: res.enterpriseUserId,
            associatonStatus: res.associatonStatus,
          };
        });
    }
    return response;
  };
  updateUser = async (values) => {
    const payload = {
      ...values,
      status: values.status,
      userId: values.id,
      enterpriseGroupId: 0,
      distributorUserId: 0,
      userTypeId: 4,
      firstName: values.firstName,
      lastName: values.lastName,
      brandName: values.brandName,
      email: values.primaryEmail,
      secondaryEmail: values.secondaryEmail,
      phoneNumber: values.phoneNumber,
      secondaryPhoneNumber: values.secondaryPhoneNumber,
      billingContact: values.billingContact,
      billingContactLastName: values.billingContactLastName,
      billingPhoneNumber: values.billingPhoneNumber,
      billingAddressLine1: values.billingAddressLine1,
      billingAddressLine2: values.billingAddressLine2,
      zipCode: values.zip,
      countryId: values.countryId ? Number(values.countryId) : null,
      city: values.city,
      stateId: values.stateId ? Number(values.stateId) : null,
      businessYear: values.businessYear,
      websiteUrl: values.websiteUrl,
      annualRevenueId: Number(values.annualRevenue),
      numberOfEmployees: values.numberOfEmployees,
      numberOfOwners: values.numberOfOwners,
      producers: values.producers,
      userTypeName: values.userTypeName,
      systemGeneratedId: values.systemGeneratedId,
      businessName: values.businessName,
      taxID: values.taxID,
      mobile: values.mobile,
      fax: values.fax,
      fax1: values.fax1,
      mobile1: values.mobile1,
      roles: values.roles,
      areas: values.areas.map((res) => {
        return {
          areaId: Number(res.value),
          name: res.label,
        };
      }),
      carrierRepresents: values.carrierRepresents.map((res) => {
        return {
          carrierRepresentId: Number(res.value),
          name: res.label,
        };
      }),
      industryGroups: values.industryGroups.map((res) => {
        return {
          industryGroupId: Number(res.value),
          name: res.label,
        };
      }),
      technologies: values.technologies.map((res) => {
        return {
          technologyId: Number(res.value),
          name: res.label,
        };
      }),
      parentUser: {
        parentUserId: values.parentUser.parentUserId || 0,
        permissions: values.parentUser?.permissions,
      },
      enterpriseTypes: values.distributorType.map((res) => {
        return {
          enterpriseTypeId: Number(res.value),
          name: res.label,
        };
      }),
      associateDistributors:
        values.associateDistributors &&
        values.associateDistributors.map((res) => {
          return {
            distributorUserId: res.distributorUserId.value,
            associationId: res.associationId,
            frequencyType: res.frequencyType,
            isNewAssociation: res.isNewAssociation,
            isRecurring: res.isRecurring,
            expirationDate: res.expirationDate ? res.expirationDate : null,
            distributorName: res.distributorUserId.label,
            distributorSubscriptionId: res.distributorSubscriptionId,
            distributorSubscriptionPlanId: res.distributorSubscriptionPlanId,
            requestId: res.requestId,
            enterpriseUserId: res.enterpriseUserId,
            associatonStatus: res.associatonStatus,
          };
        }),
      logoGcpPath: values.logoGcpPath,
      logoGcpFileUrl: values.logoGcpFileUrl,
    };
    const response = await ApiServices.httpPut(ENTERPRISE_GROUP_USER, payload);
    if (response && response.result && response.result.associateDistributors) {
      response.result.associateDistributors =
        response.result.associateDistributors.map((res) => {
          return {
            distributorUserId: {
              value: res.distributorUserId,
              label: res.distributorName,
            },
            associationId: res.associationId,
            frequencyType: res.frequencyType,
            isNewAssociation: res.isNewAssociation,
            isRecurring: res.isRecurring,
            expirationDate: res.expirationDate,
            distributorSubscriptionId: res.distributorSubscriptionId,
            requestId: res.requestId,
            enterpriseUserId: res.enterpriseUserId,
            associatonStatus: res.associatonStatus,
          };
        });
    }
    return this.setResponseData(response);
  };
  updateUserStatus = () => {
    return "updating Enterprise User status";
  };
  renewUser = async (values) => {
    let payload = {
      status: values.status,
      enterpriseUserId: values.enterpriseGroupId,
      requestId: values.requestId,
    };
    const response = await ApiServices.httpPost(
      ENTERPRISE_GROUP_USER_RENEW,
      payload
    );
    if (response && response.result && response.result.associateDistributors) {
      response.result.associateDistributors =
        response.result.associateDistributors.map((res) => {
          return {
            enterpriseGroupId: {
              value: res.enterpriseGroupId,
              label: res.distributorName,
            },
            associationId: res.associationId,
            frequencyType: res.frequencyType,
            isNewAssociation: res.isNewAssociation,
            isRecurring: res.isRecurring,
            expirationDate: res.expirationDate,
            distributorSubscriptionId: res.distributorSubscriptionId,
            requestId: res.requestId,
            associatonStatus: res.associatonStatus,
          };
        });
    }
    return response;
  };

  getInternalUsers = (query) => {
    return ApiServices.httpGet(`${INTERNAL_USERS}${query}`);
  };
  getInternalUser = (query) => {
    return ApiServices.httpGet(`${ADD_EDIT_INTERNAL_USERS}${query}`);
  };
  createInternalUser = (payload) => {
    return ApiServices.httpPost(ADD_EDIT_INTERNAL_USERS, payload);
  };
  updateInternalUser = (payload) => {
    return ApiServices.httpPut(ADD_EDIT_INTERNAL_USERS, payload);
  };
  updateInternalUserStatus = (payload) => {
    return ApiServices.httpPut(INTERNAL_USER_CHANGE_STATUS, payload);
  };

  populateDropdownOptions = async () => {
    const response = JSON.parse(localStorage.getItem("masterData"));
    if (response.responseStatus.isSuccess) {
      return {
        userTypeOptions: response.result.enterpriseTypes.map((res) => {
          return {
            value: res.enterpriseTypeId,
            label: res.name,
          };
        }),
        countries: response.result.countries,
        states: response.result.states,
        annualRevenues: response.result.annualRevenues,
        numberOfEmployees: response.result.numberOfEmployees,
        areas: response.result.areas.map((res) => {
          return {
            value: res.areaId,
            label: res.name,
          };
        }),
        carrierRepresents: response.result.carrierRepresents.map((res) => {
          return {
            value: res.carrierRepresentId,
            label: res.name,
          };
        }),
        industryGroups: response.result.industryGroups.map((res) => {
          return {
            value: res.industryGroupId,
            label: res.name,
          };
        }),
        technologies: response.result.technologies.map((res) => {
          return {
            value: res.technologyId,
            label: res.name,
          };
        }),
        roles: response.result.roles,
        parentPermissions: response.result.parentPermissions.map((res) => {
          return {
            value: res.parentPermissionMasterId,
            label: res.name,
          };
        }),
      };
    } else {
      return {
        userTypeOptions: [],
        countries: [],
        states: [],
        annualRevenues: [],
        numberOfEmployees: [],
        areas: [],
        carrierRepresents: [],
        industryGroups: [],
        technologies: [],
        roles: [],
      };
    }
  };

  setResponseData = (response) => {
    //if you are changing here change this in the distributor service one too if required
    let newRespone = {
      responseStatus: { ...response.responseStatus },
      result: {
        ...response.result,
        id: response.result.userId,
        primaryEmail: response.result.email,
        zip: response.result.zipCode,
        distributorType:
          response.result.enterpriseTypes &&
          response.result.enterpriseTypes.map((res) => {
            return {
              value: res.enterpriseTypeId,
              label: res.name,
            };
          }),
        areas:
          response.result.areas &&
          response.result.areas.map((res) => {
            return {
              value: res.areaId,
              label: res.name,
            };
          }),
        carrierRepresents:
          response.result.carrierRepresents &&
          response.result.carrierRepresents.map((res) => {
            return {
              value: res.carrierRepresentId,
              label: res.name,
            };
          }),
        industryGroups:
          response.result.industryGroups &&
          response.result.industryGroups.map((res) => {
            return {
              value: res.industryGroupId,
              label: res.name,
            };
          }),
        technologies:
          response.result.technologies &&
          response.result.technologies.map((res) => {
            return {
              value: res.technologyId,
              label: res.name,
            };
          }),
        enterpriseType:
          response.result.enterpriseTypes &&
          response.result.enterpriseTypes.map((res) => {
            return {
              value: res.enterpriseTypeId,
              label: res.name,
            };
          }),
      },
    };
    return newRespone;
  };

  exportData = (query) => {
    return ApiServices.httpGet(`${ENTERPRISE_GROUP_EXPORT}${query}`);
  };

  exportDataInternalUsers = (query) => {
    return ApiServices.httpGet(`${INTERNAL_USERS_EXPORT}${query}`);
  };

  exportDataContent = (query) => {
    return ApiServices.httpGet(`${CONTENT_EXPORT}/${query}`);
  };

  getAsscoiateDistributors = async () => {
    let response = await ApiServices.httpGet(`${GET_ASSOCIATE_DISTRIBITORS}`);
    let data = response.result?.map((res) => {
      return {
        value: res.userId,
        label: res.brandName || "NA",
      };
    });
    data = data.filter((option) => option.label !== "");
    data.sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
    return data;
  };

  getSubscriptionType = async (distributorId) => {
    let response = await ApiServices.httpGet(
      `${GET_SUBSCRIPTION_TYPE}${distributorId}`
    );
    let data =
      response.result &&
      response.result.map((res) => {
        return {
          value: res.distributorSubscriptionId,
          label: res.name,
          baseValue: res.baseValue,
          frequency: res.frequency,
          distributorSubscriptionId: res.distributorSubscriptionId,
          isAssociated: res.isAssociated,
          userId: res.userId,
          subscriptionPlan: res.subscriptionPlan,
        };
      });
    return data;
  };

  getSubscriptionTypes = async (payload) => {
    return await ApiServices.httpPost(`${SUBSCRIPTION_TYPE}`, payload);
  };

  getSubscriptionTypeMembership = async (distributorId) => {
    let response = await ApiServices.httpGet(
      `${GET_SUBSCRIPTION_TYPE}${distributorId}`
    );
    return response;
  };

  sendAssociatedDistApproval = async (payload) => {
    return await ApiServices.httpPost(
      `${SEND_ASSOCIATE_DIST_APPROVAL}`,
      payload
    );
  };

  buyMembership = async (payload) => {
    return await ApiServices.httpPost(
      `${BUY_SUBSCRIPTION_ENTERPRISE}`,
      payload
    );
  };

  exportDataCatalog = (query) => {
    return ApiServices.httpGet(`${CATALOG_EXPORT}/${query}`);
  };

  exportDataBundle = (query) => {
    return ApiServices.httpGet(`${BUNDLE_EXPORT}/${query}`);
  };

  exportAssociatedDataCatalog = (query) => {
    return ApiServices.httpGet(`${CATALOG_ASSOCIATED_CONTENT_EXPORT}/${query}`);
  };

  exportAssociatedDataBundle = (query) => {
    return ApiServices.httpGet(`${BUNDLE_ASSOCIATED_CONTENT_EXPORT}/${query}`);
  };

  getOrDefaultValue(value, defaultValue = "") {
    return value || defaultValue;
  }
}
