import { Box, Button, Grid, Tooltip, Tab, Tabs } from "@mui/material";
import TabPanel from "../../common/TabPanel/TabPanel";
import { a11yProps } from "../../common/TabPanel/a11yProps";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import "./ManageDocuments.css";
import DataTable from "../Tables/DataTable/DataTable";
import { MuiFileInput } from "mui-file-input"; /* istanbul ignore */
import {
  UPLOAD_DOCUMENT_URL,
  DISTRIBUTORS,
  ERROR_DOCUMENT_URL,
} from "../../constants/api-constants";
import ApiServices from "../../services/ApiServices";
import { useLoading } from "../../hooks/useLoader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import {
  // allAcceptedExtentionsDocuments,
  deleteSubscriptionConfirmationMessages,
} from "../Distributors/constants";
import {
  ClearSelectionData,
  GetAllSelectedIds,
  SelectionFilter,
  GetSelectionData,
} from "../../utilities/SelectionFilter";
import {
  PermissionEnum,
  PermissionService,
  childModulePermissions,
  getModuleId,
} from "../../constants/permissionService";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import SearchableDropDown from "../../common/SearchableDropDown/SearchableDropdown";
import Modal from "@mui/material/Modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Typography from "@mui/material/Typography";
import { ErrorRecordsColumns } from "../../common/TableColumnModal/DefaultDataColumns";
import Confirmation from "../../common/Confirmation/Confirmation";
import { SystemModulesEnum } from "../../constants/SystemUsers";
import UploadUserModal from "../../common/Modal/UploadUserModal";
import { UserTypes } from "../../constants/user-types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

/*istanbul ignore next */
const DOC_TABS = {
  RECENT: "Uploaded Content",
  SUCCESS: "Successful Content",
  ERROR: "Error Content",
};
/*istanbul ignore next */
const DocumentTabslist = [
  { id: 0, label: DOC_TABS.RECENT, name: "Uploaded Content" },
  { id: 1, label: DOC_TABS.SUCCESS, name: "Successful Content" },
  { id: 2, label: DOC_TABS.ERROR, name: "Error Content" },
];

/* istanbul ignore next */
const ManageDocuments = (props) => {
  const auth = useAuth();
  const currentUser = auth.getCurrentUser();
  const [files, setFiles] = useState([]);
  const [openMassUploadPopup, setOpenMassUploadPopup] = useState(false);
  const [filesStatus, setFilesStatus] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [distributorTypes, setDistributorTypes] = useState({
    allDistributorUsers: [],
  });
  const [selectedDistributor, setSelectedDistributor] = useState(null);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState({
    SearchText: "",
    SortType: "desc",
    SortColumn: "createdOn",
    PageNumber: 1,
    PageSize: 10,
    IgnorePaging: false,
    Filter: {
      FilterType: 0,
    },
  });
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [documentList, setDocumentList] = useState([]);
  const { showLoader } = useLoading();
  const [selectedTab, setSelectedTab] = useState(DOC_TABS.RECENT);
  const [errorRecordsPopupConfirmation, setErrorRecordsPopupConfirmation] =
    useState(false);
  const [errorRecordsList, setErrorRecordsList] = useState([]);
  const [deletionData, setDeletionData] = useState(null);
  const maxFileSize = 25 * 1024 * 1024; // 25MB in bytes

  const getErrorRecords = async (item) => {
    try {
      showLoader(true);
      let response = await ApiServices.httpGet(
        `${ERROR_DOCUMENT_URL}/${item.userId}/${item.userDocumentId}`
      );
      if (response.responseStatus.isSuccess) {
        setErrorRecordsList(response.result);
      } else {
        toast.error(response.responseStatus.error);
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const handleDeleteAction = (selectedData = null) => {
    if (selectedData) {
      setDeletionData([selectedData]);
    } else {
      let data = GetSelectionData();
      setDeletionData(data);
    }
    setOpenConfirmation(true);
  };

  const handleTabsChange = (event, newValue) => {
    let selTab = DocumentTabslist.find((x) => x.label === newValue);

    setQuery((prevState) => {
      return {
        ...prevState,
        Filter: {
          // ...query.filter,
          PageNumber: 1,
          FilterType: selTab.id,
        },
      };
    });
    setSelectedTab(selTab.name);
  };

  const onView = (item) => {
    setErrorRecordsPopupConfirmation(true);
    getErrorRecords(item);
  };

  const populateDistributorDropdown = async () => {
    const response = await ApiServices.httpGet(DISTRIBUTORS);
    if (response.responseStatus.isSuccess) {
      let distObj = response.result.find(
        (res) => res.userId === currentUser?.userId
      );
      if (distObj)
        setSelectedDistributor([
          {
            value: distObj.userId,
            label: distObj.name,
          },
        ]);
      else setSelectedDistributor(null);

      setDistributorTypes({
        allDistributorUsers: response.result.map((res) => {
          return {
            value: res.userId,
            label: res.name,
          };
        }),
      });
    } else {
      setDistributorTypes({
        allDistributorUsers: [],
      });
    }

    return;
  };

  useState(() => {
    populateDistributorDropdown();
  });

  useEffect(() => {
    ClearSelectionData();
    getDocumentList();
  }, []);

  useEffect(() => {
    getDocumentList();
  }, [selectedDistributor]);

  useEffect(() => {
    getDocumentList();
    ClearSelectionData();
  }, [query]);

  const handleChange = (newFile) => {
    setFiles(newFile);
    setFilesStatus(newFile);
  };

  const permissions = PermissionService(8);

  const uploadFilesSequentially = async () => {
    showLoader(true);
    let i = 0;
    for (const file of filesStatus) {
      try {
        if (file.size > maxFileSize) {
          files[i].uploadStatus = "Failed";
          files[i].uploadStatusMessage =
            "File size exceeds maximum 25MB allowed.  Either reduce size or contact support@inchanted.com regarding an exception";
          continue;
        }
        files[i].uploadStatus = "In Progress";
        const formData = new FormData();
        formData.append("File", file);
        formData.append(
          "UserId",
          selectedDistributor
            ? selectedDistributor[0].value
            : currentUser?.userId
        );

        const response = await ApiServices.httpPostFile(
          UPLOAD_DOCUMENT_URL,
          formData
        );
        if (response.responseStatus?.isSuccess) {
          files[i].uploadStatus = "Success";
          files[i].uploadStatusMessage = response.responseStatus.description;
        } else {
          files[i].uploadStatus = "Failed";
          files[i].uploadStatusMessage = response.responseStatus.error;
        }
      } catch (error) {
        files[i].uploadStatus = "Failed";
        files[i].uploadStatusMessage = error;
        console.log("error", error);
      } finally {
        i++;
      }
    }
    ClearSelectionData();
    setFiles([]);
    showLoader(false);
    getDocumentList(true);
    setOpenMassUploadPopup(true);
  };

  const getDocumentList = async (resetRecent = false) => {
    try {
      if (selectedDistributor) {
        showLoader(true);
        let newQuery = query;

        if (resetRecent) {
          let selTab = DocumentTabslist.find(
            (x) => x.label === DOC_TABS.RECENT
          );
          newQuery = {
            ...newQuery,
            Filter: {
              PageNumber: 1,
              FilterType: selTab.id,
            },
          };
          setQuery(newQuery);
          setSelectedTab(DOC_TABS.RECENT);
        }

        const docListResponse = await ApiServices.httpGet(
          `${UPLOAD_DOCUMENT_URL}/${
            selectedDistributor[0].value
          }/query?query=${JSON.stringify(newQuery)}`
        );
        showLoader(false);

        // starts here: added code for pre-selection if data/record selected/checked
        let checkedIds = GetAllSelectedIds("userDocumentId");
        if (checkedIds && docListResponse?.records) {
          docListResponse.records = docListResponse?.records.map((ele) => {
            if (checkedIds.includes(ele.userDocumentId)) {
              ele.isChecked = true;
            } else {
              ele.isChecked = false;
            }
            return ele;
          });
        }
        // ends here

        setDocumentList(docListResponse?.records);
        setTotalPages(docListResponse?.totalPages);
        setPageSize(docListResponse?.pageSize);
      } else {
        setDocumentList([]);
        setTotalPages(0);
        setPageSize(0);
      }
    } catch (e) {
      showLoader(false);
    }
  };

  const getSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (selectedTab === DOC_TABS.RECENT) {
      return selectedCols?.manageDocumentsListColumns || [];
    } else {
      return selectedCols?.ManageDocumentsSuccessOrErrorListColumns || [];
    }
  };

  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return {
          ...prevState,
          PageNumber: 1,
          searchText: event.target.value,
        };
      });
    }, 1000);
  };

  const sortHandler = (sortOrder, sortBy) => {
    setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };
  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };

  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };

  const onDeleteHandler = async () => {
    try {
      showLoader(true);
      let payload = deletionData;
      const resp = await ApiServices.httpDelete(UPLOAD_DOCUMENT_URL, payload);
      if (resp.responseStatus?.isSuccess) {
        toast.success(resp.responseStatus?.description);
      } else {
        toast.success(resp.responseStatus?.error);
      }
      showLoader(false);
      getDocumentList();
      ClearSelectionData();
      setDeletionData(null);
    } catch (e) {
      showLoader(false);
    }
  };

  const updateFiles = (fileName) => {
    let allfiles = files.filter((res) => res.name !== fileName);
    setFiles(allfiles);
    setFilesStatus(allfiles);
  };

  const onCheckboxChangeHandler = (e, value) => {
    const { checked } = e.target;
    if (value === "all") {
      setDocumentList((items) => {
        SelectionFilter(items, "multiple", checked, "userDocumentId");
        return items.map((data) => {
          return {
            ...data,
            isChecked: checked,
          };
        });
      });
    } else {
      setDocumentList((items) => {
        return items.map((user) => {
          let userRecord = { ...user };
          if (user.userDocumentId === value) {
            SelectionFilter(user, "single", checked, "userDocumentId");
            userRecord.isChecked = checked;
          }
          return userRecord;
        });
      });
    }
  };

  const renderErrorRecordsConfirmationPopup = () => {
    return (
      <Modal
        open={errorRecordsPopupConfirmation}
        onClose={() => {
          setErrorRecordsPopupConfirmation(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <div
            onClick={() => {
              setErrorRecordsPopupConfirmation(false);
            }}
            style={{
              cursor: "pointer",
              justifyContent: "end",
              display: "flex",
              marginTop: "5px",
            }}
          >
            <CloseOutlinedIcon data-testid="CloseNewAdminPopup" />
          </div>
          <Typography
            id="modal-modal-description"
            sx={{
              mb: 2,
              mt: -2,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Error Details
          </Typography>

          <div>
            <DataTable
              paginationNotRequired={true}
              checkboxesNotRequired={true}
              serachBarNotRequired={true}
              columns={ErrorRecordsColumns}
              adminTable={errorRecordsList}
            />
          </div>
        </Box>
      </Modal>
    );
  };

  const selectedOrErrorFiles = filesStatus?.filter(
    (x) => !x.uploadStatus || x.uploadStatus === "Failed"
  );
  return (
    <>
      <Confirmation
        open={openConfirmation}
        title={deleteSubscriptionConfirmationMessages}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
        onConfirm={() => {
          onDeleteHandler();
          setOpenConfirmation(false);
        }}
        onCancel={() => {
          setOpenConfirmation(false);
        }}
        onClose={() => {
          setOpenConfirmation(false);
        }}
      />
      <Grid spacing={2} container className="landing-page-container">
        <Grid container item xs={12} pb={2}>
          <Grid item xs={10}>
            <Box pt={2} pr={4} pl={4} pb={0}>
              <h5>Mass Upload Content</h5>
            </Box>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          {/* <Box pr={5} pl={5} pb={0}> */}

          <Grid item xs={3} p={1} pl={5} bottom={1}>
            <div className="items" style={{ marginTop: "-32px" }}>
              <label htmlFor="distributor">Select Distributor</label>
              <MandatoryField />
              <SearchableDropDown
                options={distributorTypes.allDistributorUsers || []}
                name="manageDocumentDistributor"
                id="manageDocumentDistributor"
                setData={(obj) => {
                  setSelectedDistributor(obj ? [obj] : null);
                  if (
                    filesStatus &&
                    filesStatus.length > 0 &&
                    filesStatus[0].uploadStatus
                  ) {
                    setFilesStatus([]);
                  }
                }}
                value={selectedDistributor}
                placeholder="Search and Select Distributor"
                classNameValue="manage-searchable-dropdown-name"
                // disabled={}
              />
            </div>
          </Grid>

          <Grid item xs={3.5} p={1}>
            <div style={{ display: "inline-flex" }}>
              <div style={{ display: "inline-flex" }}>
                <MuiFileInput
                  className="document-upload upload-doc-cus"
                  multiple
                  inputProps={{ accept: "*/*" }}
                  value={files}
                  onChange={handleChange}
                  placeholder="Select Content(s) to Upload"
                />
              </div>
              <div
                style={{
                  display: "inline-flex",
                  paddingTop: "15px",
                  cursor: "pointer",
                }}
              >
                <InfoOutlinedIcon
                  className="custom-info-icon help-text-color"
                  onClick={handleOpen}
                />
              </div>
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="info-modal-title"
              aria-describedby="info-modal-description"
            >
              <Box sx={style}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "-30px",
                    marginRight: "-30px",
                  }}
                >
                  <CloseOutlinedIcon
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <Typography className="bold-text">
                  The Mass Upload Content Process is made up of these steps:
                </Typography>
                <ol>
                  <li>
                    Create a folder on your PC or network comprised of the
                    physical content files you desire to upload.
                  </li>
                  <li>
                    Complete the spreadsheet template to match the physical
                    content file names. You can download template from the
                    “Upload Matching Spreadsheet” button/screen.
                  </li>
                  <li>
                    Click in the “Select Content(s) to Upload” field and browse
                    your computer or network to find the content file name(s)
                    you desire to upload. Selected file(s) will display in the
                    “Selected Files” box. You may scroll and remove a selected
                    file by clicking the “X” next to the file name.
                  </li>
                  <li>
                    Click the “Upload Selected Content” button to actually
                    upload the selected files. Successful files will then
                    display under the “Uploaded Content” Tab.
                  </li>
                  <li>
                    The “Upload Matching Spreadsheet” screen will automatically
                    display where you will browse and find the spreadsheet that
                    match the physical content files you have already uploaded.
                    The “Upload Matching Excel Sheet” screen may also be
                    accessed by clicking the “Upload Matching Spreadsheet”
                    button.
                  </li>
                </ol>
                <Typography className="bold-text" style={{ marginTop: "4px" }}>
                  NOTE: The physical files names being uploaded MUST match the
                  file names in the matching spreadsheet. After being processed,
                  check “Successful” and “Error” content tabs.
                </Typography>
              </Box>
            </Modal>
          </Grid>

          <Grid item xs={3} p={1}>
            <div style={{ display: "inline-flex" }}>
              <div style={{ display: "inline-flex" }}>
                <Button
                  disabled={
                    (!files?.length > 0 && permissions) ||
                    !permissions.includes(PermissionEnum.CREATE) ||
                    !selectedDistributor
                  }
                  variant="contained"
                  type="submit"
                  className="action-button-font"
                  onClick={() => {
                    uploadFilesSequentially();
                  }}
                >
                  Upload Selected Content(s)
                </Button>
              </div>
            </div>
          </Grid>

          <Grid item xs={2.5} p={1} pr={5}>
            {selectedTab !== DOC_TABS.SUCCESS && (
              <Button
                disabled={
                  !permissions.includes(PermissionEnum.UPDATE) ||
                  !GetAllSelectedIds("userDocumentId")?.length
                }
                variant="contained"
                type="submit"
                style={{ float: "right" }}
                className="action-button-font"
                onClick={() => {
                  handleDeleteAction();
                }}
              >
                Delete Document(s)
              </Button>
            )}
          </Grid>

          {selectedOrErrorFiles?.length > 0 && (
            <Box pr={5} pl={5} pb={0}>
              <div className="show-upload-doc-status-pane">
                <span>
                  {selectedOrErrorFiles[0].uploadStatus
                    ? "Failed Uploaded Files"
                    : "Selected Files"}
                  ({selectedOrErrorFiles.length})
                </span>
              </div>
              <List className="list-upload-docs">
                {selectedOrErrorFiles?.map((file) => (
                  <ListItem
                    key={file.name}
                    disableGutters
                    secondaryAction={
                      file.uploadStatus === "Success" ? (
                        <Tooltip
                          placement="top"
                          title={file.uploadStatusMessage}
                        >
                          <DoneIcon className="success-icon" />
                        </Tooltip>
                      ) : (
                        onNotSuccessFile(file)
                      )
                    }
                  >
                    <ListItemText primary={`${file.name}`} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Box pr={4} pl={4} pb={0}>
            <Tabs
              value={selectedTab}
              onChange={handleTabsChange}
              aria-label="Document Tabs"
            >
              {DocumentTabslist.map((item) => {
                return (
                  <Tab
                    className="my-tabs"
                    label={item.name}
                    value={item.label}
                    {...a11yProps(item.label, item.label)}
                    // disabled={item.disabled}
                  />
                );
              })}
            </Tabs>

            <TabPanel
              value={selectedTab}
              index={DOC_TABS.RECENT}
              className="documents-tab-panel"
            >
              {childModulePermissions(
                getModuleId(UserTypes.distributor.userTypeId),
                SystemModulesEnum.DISTRIBUTOR_CONTENT
              )?.includes(PermissionEnum.CREATE) &&
                selectedDistributor && (
                  <div
                    className="top-right-actions"
                    style={{ marginBottom: "-46px", marginRight: "270px" }}
                  >
                    <UploadUserModal
                      from="mass-upload-content"
                      userId={selectedDistributor[0].value}
                      title={"Upload Matching Excel Sheet"}
                      bulkUploadTemplate={"/templates/Content_Mass_Upload.xlsx"}
                      getContentList={() => getDocumentList()}
                      openMassUploadPopup={openMassUploadPopup}
                      setOpenMassUploadPopup={setOpenMassUploadPopup}
                    />
                  </div>
                )}
              {loadDataTable(DOC_TABS.RECENT)}
            </TabPanel>
            <TabPanel
              value={selectedTab}
              index={DOC_TABS.SUCCESS}
              className="documents-tab-panel"
            >
              {loadDataTable(DOC_TABS.SUCCESS)}
            </TabPanel>
            <TabPanel
              value={selectedTab}
              index={DOC_TABS.ERROR}
              className="documents-tab-panel"
            >
              {loadDataTable(DOC_TABS.ERROR)}
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
      {errorRecordsPopupConfirmation && renderErrorRecordsConfirmationPopup()}
    </>
  );

  function loadDataTable(docType) {
    return (
      <DataTable
        uniqueKey="userDocumentId"
        onCheckboxChange={onCheckboxChangeHandler}
        columns={getSelectionColumns()}
        adminTable={documentList}
        onSearch={onSearchHandler}
        onSort={sortHandler}
        onPageSizeChange={pageSizeChangeHandler}
        handleChangePage={handleChangePageNumber}
        totalPages={totalPages}
        pageSize={pageSize}
        showDeleteDocument={
          permissions.includes(PermissionEnum.UPDATE) &&
          docType !== DOC_TABS.SUCCESS
        }
        onDelete={handleDeleteAction}
        query={query}
        checkboxesNotRequired={docType === DOC_TABS.SUCCESS}
        showView={docType === DOC_TABS.ERROR}
        onView={(data) => onView(data)}
      />
    );
  }

  function onNotSuccessFile(file) {
    return file.uploadStatus === "Failed" ? (
      <Tooltip placement="top" title={file.uploadStatusMessage}>
        <ErrorIcon className="error-icon" />
      </Tooltip>
    ) : (
      <Tooltip placement="top" title={!file.uploadStatus && "Click to remove"}>
        <CloseIcon
          className="pending-icon"
          onClick={() => {
            updateFiles(file.name);
          }}
        />
      </Tooltip>
    );
  }
};
export default ManageDocuments;
