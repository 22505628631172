import { Button, Checkbox, Grid, Modal, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "../Modal/Modal.css";
import React, { useState } from "react";
import { useFormik } from "formik";
import { UserSignupSchema } from "../ValidationSchema/ValidationSchema";
import ApiServices from "../../services/ApiServices";
import { toast } from "react-toastify";
import { ADMIN_USER, ALL_ROLE_LIST } from "../../constants/api-constants";
import PhoneNumberTextbox from "../PhoneNumberTextbox/PhoneNumberTextbox";
import InlineMessage from "../InlineMessage/InlineMessage";
import { useLoading } from "../../hooks/useLoader";
import MultiSelectDropDown from "../MultiSelectDropDown/MultiSelectDropDown";
import { MandatoryField } from "../MandatoryFieldsIcon/MandatoryField";
import filterRoles from "../../utilities/filterRoles";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { exitConfirmationMessage } from "../../screens/Distributors/constants";
import Confirmation from "../Confirmation/Confirmation";
import BusinessPhoneNumberTextbox from "../PhoneNumberTextbox/BusinessPhoneNumberTextbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "#fff",
  border: "1px solid #000",
  boxShadow: 24,
  p: 0,
};

const CreateAdminModal = (props) => {
  const { userRoleId, masterData } = props;
  const { showLoader } = useLoading();
  const [open, setOpen] = React.useState(false);
  const [distributorTypeOptions, setDistributorTypeOptions] = useState([]);
  const [enterpriseTypeOptions, setEnterpriseTypeOptions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [allRoles, setAllRoles] = useState([]);
  const options = allRoles && allRoles.map((item) => item.name);
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options && options.length > 0 && selected.length === options.length;

  const handleOpen = () => {
    loadInternalRoleOptions();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenConfirmation(false);
  };

  const [state, setState] = useState({
    addMobile: false,
    addFax: false,
    addForm: false,
    registrationDate: new Date().toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }),
  });

  // Formik
  const formik = useFormik({
    initialValues: {
      userTypeId: userRoleId === 1 ? 0 : 2,
      firstName: "",
      lastName: "",
      primaryEmail: "",
      secondaryEmail: "",
      phoneNumber: "",
      secondaryPhoneNumber: "",
      mobile: "",
      mobile1: "",
      fax: "",
      fax1: "",
      roles: [],
      distributorType: [],
      enterpriseType: [],
    },
    enableReinitialize: true,
    validationSchema: UserSignupSchema,
  });

  const handleEditModalCancelClick = () => {
    if (formik.dirty === true) {
      setOpenConfirmation(true);
    } else if (open === true) {
      setOpen(false);
    }
  };

  const loadInternalRoleOptions = async () => {
    let role = Number(localStorage.getItem("selectedRole"));
    const adminData = await ApiServices.httpGet(`${ALL_ROLE_LIST}`);
    setAllRoles(filterRoles(role, adminData?.result?.records));
    setDistributorTypeOptions(
      masterData?.result?.distributorTypes.map((opt) => {
        return {
          value: opt.distributorTypeId,
          label: opt.name,
        };
      })
    );
    setEnterpriseTypeOptions(
      masterData?.result?.enterpriseTypes.map((opt) => {
        return {
          value: opt.enterpriseTypeId,
          label: opt.name,
        };
      })
    );
  };

  const onCancel = () => {
    setSelectedRoles([]);
    setState({
      addFax: false,
      addMobile: false,
      addForm: false,
    });
  };

  const onSave = async (e) => {
    e.preventDefault();
    try {
      showLoader(true);
      let roles = allRoles.filter((element) => selected.includes(element.name));
      const response = await ApiServices.httpPost(ADMIN_USER, {
        ...formik.values,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.primaryEmail,
        secondaryEmail: formik.values.secondaryEmail || "",
        phoneNumber: formik.values.phoneNumber,
        secondaryPhoneNumber: formik.values.secondaryPhoneNumber || "",
        userTypeId: Number(formik.values.userTypeId),
        mobile: formik.values.mobile,
        fax1: formik.values.fax1,
        mobile1: formik.values.mobile1,
        fax: formik.values.fax,
        roles: roles,
        distributorTypes: formik.values.distributorType.map((dt) => {
          return {
            distributorTypeId: dt.value,
            name: dt.label,
          };
        }),
        enterpriseTypes: formik.values.enterpriseType.map((et) => {
          return {
            enterpriseTypeId: et.value,
            name: et.label,
          };
        }),
        userType: null,
        countryId: 1,
      });
      showLoader(false);
      if (response.responseStatus.isSuccess) {
        handleClose();
        clearAll();
        props.onSuccess(response.responseStatus.code);
        toast.success(response.responseStatus.description);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (event) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const clearAll = () => {
    formik.values.userTypeId = 0;
    formik.values.firstName = "";
    formik.values.lastName = "";
    formik.values.primaryEmail = "";
    formik.values.secondaryEmail = "";
    formik.values.phoneNumber = "";
    formik.values.secondaryPhoneNumber = "";
    formik.values.roles = [];
  };

  const isValid = () => {
    return (
      formik.values.firstName &&
      !formik.errors.firstName &&
      formik.values.lastName &&
      !formik.errors.lastName &&
      formik.values.primaryEmail &&
      !formik.errors.primaryEmail &&
      !formik.errors.secondaryEmail &&
      formik.values.phoneNumber &&
      !formik.errors.phoneNumber &&
      !formik.errors.mobile &&
      !formik.errors.fax1 &&
      !formik.errors.mobile1 &&
      !formik.errors.fax &&
      !formik.errors.secondaryPhoneNumber &&
      !formik.errors.roles
    );
  };

  const handleChangeDropDown = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      formik.setFieldValue(
        "roles",
        selected.length === options.length ? [] : options
      );
      return;
    }
    setSelected(value);
    formik.setFieldValue("roles", value);
  };

  const MenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const renderMultiSelectRolesDropdown = () => {
    return (
      <div sx={{ width: "100%" }}>
        <label htmlFor="rolesDropdown">Roles</label>
        <MandatoryField />
        <Select
          displayEmpty
          data-testid="rolesDropdown"
          labelId="mutiple-select-label"
          className="form-select form-control select-user roles-dropdown"
          multiple
          value={selected}
          title={selected}
          onChange={handleChangeDropDown}
          renderValue={(selectedItem) => {
            if (selected.length === 0) {
              return "Select Roles";
            }
            return selectedItem.join(", ");
          }}
          MenuProps={MenuProps}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  // Formik
  return (
    <>
      <Confirmation
        open={openConfirmation}
        title={`Confirmation`}
        description={exitConfirmationMessage}
        onCancel={() => {
          setOpenConfirmation(false);
        }}
        onConfirm={() => {
          handleClose();
          setOpenConfirmation(false);
          formik.resetForm();
        }}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Exit"
      />
      <div className="add-welcome-widgets-breadcrumb-admin">
        <Tooltip placement="bottom" title="Add New User">
          <AddOutlinedIcon
            onClick={() => {
              handleOpen();
            }}
            name="add-icon"
            role="img"
          />
        </Tooltip>
      </div>

      <Modal
        open={open}
        onClose={() => {
          onCancel();
          handleEditModalCancelClick();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="custom-modal">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-header">
              <div className="modal-header-lefts">Add New User</div>
              <div
                className="modal-header-right"
                onClick={() => {
                  handleEditModalCancelClick();
                }}
                style={{ cursor: "pointer" }}
              >
                <CloseOutlinedIcon data-testid="CloseNewAdminPopup" />
              </div>
            </div>
            <div className="modal-body">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <label htmlFor="firstName">First Name</label>
                  <MandatoryField />
                  <input
                    className="form-label form-control"
                    placeholder="Enter First Name"
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={(e) => formik.handleChange(e)}
                    value={formik.values.firstName}
                    maxLength={25}
                    autoComplete="off"
                  />
                  <InlineMessage error={formik.errors.firstName} />
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="lastName">Last Name</label>
                  <MandatoryField />
                  <input
                    id="lastName"
                    name="lastName"
                    className="form-label form-control"
                    type="text"
                    placeholder="Enter Last Name"
                    onChange={(e) => formik.handleChange(e)}
                    value={formik.values.lastName}
                    maxLength={25}
                    autoComplete="off"
                  />
                  <InlineMessage error={formik.errors.lastName} />
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="primaryEmail">Primary Email Address</label>
                  <MandatoryField />
                  <input
                    id="primaryEmail"
                    name="primaryEmail"
                    className="form-label form-control"
                    type="email"
                    placeholder="Enter Primary Email Address"
                    onChange={(e) => formik.handleChange(e)}
                    value={formik.values.primaryEmail}
                    autoComplete="off"
                  />
                  <InlineMessage error={formik.errors.primaryEmail} />
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="secondaryEmail">
                    Secondary Email Address
                  </label>
                  <input
                    id="secondaryEmail"
                    className="form-label form-control"
                    type="email"
                    name="secondaryEmail"
                    placeholder="Enter Secondary Email Address"
                    onChange={(e) => formik.handleChange(e)}
                    value={formik.values.secondaryEmail}
                    autoComplete="off"
                  />
                  <InlineMessage error={formik.errors.secondaryEmail} />
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="phoneNumber">Business Phone Number</label>
                  <MandatoryField />
                  <BusinessPhoneNumberTextbox
                    id="phoneNumber"
                    className="form-label form-control"
                    name="phoneNumber"
                    placeholder="Enter Business Phone Number"
                    onChange={(e) => formik.setFieldValue("phoneNumber", e)}
                    value={formik.values.phoneNumber}
                    phoneCode={["+1"]}
                  />
                  <InlineMessage error={formik.errors.phoneNumber} />
                </Grid>
                <Grid item xs={4}>
                  <div
                    className="add-item"
                    onClick={() =>
                      setState({ ...state, addForm: !state.addForm })
                    }
                  >
                    <h5>
                      <span>
                        {!state.addForm
                          ? "+ Add Another Number"
                          : "- Remove Another Number"}
                      </span>
                    </h5>
                  </div>
                </Grid>
                {state.addForm && (
                  <Grid item xs={4}>
                    <label htmlFor="secondaryPhoneNumber">
                      Business 2 Phone Number
                    </label>
                    <BusinessPhoneNumberTextbox
                      id="secondaryPhoneNumber"
                      className="form-label form-control"
                      name="secondaryPhoneNumber"
                      placeholder="Enter Business 2 Phone Number"
                      onChange={(e) =>
                        formik.setFieldValue("secondaryPhoneNumber", e)
                      }
                      value={formik.values.secondaryPhoneNumber}
                      phoneCode={["+1"]}
                    />
                    <InlineMessage error={formik.errors.secondaryPhoneNumber} />
                  </Grid>
                )}

                <Grid item xs={4}>
                  <label htmlFor="mobile">Mobile</label>
                  <PhoneNumberTextbox
                    id="mobile"
                    className="form-label form-control"
                    name="mobile"
                    placeholder="Enter Mobile Number"
                    onChange={(e) => formik.handleChange(e)}
                    value={formik.values.mobile}
                    phoneCode={["+1"]} // Country Code for USA and Canada
                  />
                  <InlineMessage error={formik.errors.mobile} />
                </Grid>
                <Grid item xs={4}>
                  <div
                    data-testid="ash"
                    className="add-item"
                    onClick={() =>
                      setState({ ...state, addMobile: !state.addMobile })
                    }
                  >
                    <h5>
                      {!state.addMobile
                        ? "+ Add Another Mobile"
                        : "- Remove Another Mobile"}
                    </h5>
                  </div>
                </Grid>
                {state.addMobile && (
                  <Grid item xs={4}>
                    <label htmlFor="mobile1">Alternate Mobile</label>
                    <PhoneNumberTextbox
                      id="mobile1"
                      className="form-label form-control"
                      name="mobile1"
                      placeholder="Enter Alternate Mobile Number"
                      onChange={(e) => formik.handleChange(e)}
                      value={formik.values.mobile1}
                      phoneCode={["+1"]} // Country Code for USA and Canada
                    />
                    <InlineMessage error={formik.errors.mobile1} />
                  </Grid>
                )}
                {!state.addMobile && !state.addForm && (
                  <Grid item xs={4}></Grid>
                )}
                <Grid item xs={4}>
                  <label htmlFor="fax">Fax</label>
                  <PhoneNumberTextbox
                    id="fax"
                    className="form-label form-control"
                    name="fax"
                    placeholder="Enter Fax"
                    onChange={(e) => formik.handleChange(e)}
                    value={formik.values.fax}
                    phoneCode={["+1"]} // Country Code for USA and Canada
                  />
                  <InlineMessage error={formik.errors.fax} />
                </Grid>
                <Grid item xs={4}>
                  <div
                    className="add-item"
                    onClick={() =>
                      setState({ ...state, addFax: !state.addFax })
                    }
                  >
                    <h5>
                      {!state.addFax
                        ? "+ Add Another Fax"
                        : "- Remove Another Fax"}
                    </h5>
                  </div>
                </Grid>
                {state.addFax && (
                  <Grid item xs={4}>
                    <label htmlFor="fax1">Alternate Fax</label>
                    <PhoneNumberTextbox
                      id="fax1"
                      className="form-label form-control"
                      name="fax1"
                      placeholder="Enter Alternate Fax"
                      onChange={(e) => formik.handleChange(e)}
                      value={formik.values.fax1}
                      phoneCode={["+1"]} // Country Code for USA and Canada
                    />
                    <InlineMessage error={formik.errors.fax1} />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid item xs={4}>
                    {renderMultiSelectRolesDropdown()}
                  </Grid>

                  {selectedRoles.some((r) => r.roleId === 3) && (
                    <Grid item xs={4}>
                      <label htmlFor="Roles">Distributor Type</label>
                      <MultiSelectDropDown
                        name="distributorType"
                        id="distributorType"
                        value={formik.values.distributorType}
                        onChange={(value) => {
                          formik.setValues({
                            ...formik.values,
                            distributorType: value,
                          });
                        }}
                        options={distributorTypeOptions}
                      />
                    </Grid>
                  )}
                  {selectedRoles.some((r) => r.roleId === 4) && (
                    <Grid item xs={4}>
                      <label htmlFor="Roles">Enterprise Type</label>
                      <MultiSelectDropDown
                        name="enterpriseType"
                        id="enterpriseType"
                        value={formik.values.enterpriseType}
                        onChange={(value) => {
                          formik.setValues({
                            ...formik.values,
                            enterpriseType: value,
                          });
                        }}
                        options={enterpriseTypeOptions}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </div>
            <div className="modal-footer">
              <div>
                <Button
                  variant="outlined"
                  className="action-button mr-4"
                  role="button"
                  name="cancel-create-new-admin"
                  onClick={() => {
                    onCancel();
                    handleEditModalCancelClick();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="action-button"
                  disabled={!isValid()}
                  onClick={onSave}
                  data-testid="save-button-2"
                >
                  Save Details
                </Button>
              </div>
            </div>
          </form>
          ;
        </Box>
      </Modal>
    </>
  );
};

export default CreateAdminModal;
